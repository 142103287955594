import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isPhone from "validator/lib/isMobilePhone";
import isNumeric from "validator/lib/isNumeric";
import paymentMethods from "../../imgs/pagamentos_small.png";
import { DatePicker } from "@mantine/dates";
import $ from "jquery";
import { Modal } from "reactstrap";
import { v1 as uuidv1 } from "uuid";
import update from "immutability-helper";
import image_1 from "../../imgs/zar43-small.jpg";
import dayjs from 'dayjs';

import CheckoutBtn from "../checkout/CheckoutBtn";

import TimerMachine from "react-timer-machine";
import momentDurationFormatSetup from "moment-duration-format";
import axios from "axios";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import mapStateToProps from '../Containers/MainContainer';
// import { setBookingOptions, cancelBooking, confirmBooking } from '../Actions/MainActions';

// import StripeComponent from './StripeComponent';

import moment from "moment";
import APPDATA from "../../AppData";

import es from "moment/locale/es";
import en from "moment/locale/en-gb";
import it from "moment/locale/it";
import ru from "moment/locale/ru";
import fr from "moment/locale/fr";
import de from "moment/locale/de";

import LANG from "../../Lang/BookingLanguage";
import LANG2 from "../../Lang/Languages";

const LangWrapper = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  return <NoLisenceBoat Lang={LANG[lang]} Lang2={LANG2[lang]} goBack={() => navigate(-1)} />;
};

class NoLisenceBoat extends Component {
  constructor(props) {
    super(props);

    this.HdDates = {
      start: null,
      end: null,
    };

    this.minDate = dayjs(new Date()).add(1, 'days').toDate();

    momentDurationFormatSetup(moment);

    this.state = {
      bookingDate: this.minDate,
      bookingDateSelected: false,
      ref: uuidv1(),
      serviceBookingsData: null,
      preBookingDone: false,
      pay: false,
      name: "",
      surname: "",
      idNum: "",
      birthday: null,
      birthlocation: "",
      resident: false,
      residence: "",
      stayingin: "",
      email: "",
      phone: "",
      amount: 1,
      duration: "FD",
      adults: 1,
      children: 0,
      posibleAdults: [1, 2, 3, 4, 5, 6],
      posibleChildren: [0, 1, 2, 3, 4, 5],
      maxPeople: 6,
      formValid: false,
      formValidIncomplete: false,
      services: [],
      price: null,
      prices: [],
      ensurance: false,
      timerStarted: false,
      timerPaused: false,
      hoursSelected: "",
      bookingError: "",
      pilotNumber: 1,
      pilots: [],
      pilotSaveError: "",
      pilotSaved: false,
      morning: -1
    };

    // this.props.buildings.map(this.renderBuildings.bind(this))
    this.dataChange = this.dataChange.bind(this);
    this.validateData = this.validateData.bind(this);
    this.validateStateData = this.validateStateData.bind(this);
    this.dateChangeHandler = this.dateChangeHandler.bind(this);
    this.selectHours = this.selectHours.bind(this);
    this.confirmBooking = this.confirmBooking.bind(this);
    // this.drawAmounOptions = this.drawAmounOptions.bind(this);
    this.changeAmount = this.changeAmount.bind(this);
    this.prepareBooking = this.prepareBooking.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
    this.handleWindowClose = this.handleWindowClose.bind(this);
    this.handleErrorOnPayment = this.handleErrorOnPayment.bind(this);
    this.savePilot = this.savePilot.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  cancelBooking() {
    console.log("Canceling booking");
    window.scrollTo(0, 0);
    if (
      this.state.preBookingDone === true
      // && this.props.MainReducer.bookingConfirmed === false
    ) {
      $.ajax({
        url: APPDATA.apiPath + "deleteBooking.php",
        context: "json",
        async: false,
        data: {
          appid: "root",
          ref: this.state.ref,
        },
      });
    }
    this.props.goBack();
    // this.props.cancelBooking();
  }

  componentDidUpdate(prevProps, prevState){
    if (
      this.state.bookingDate !== prevState.bookingDate ||
      this.state.duration !== prevState.duration ||
      this.state.adults !== prevState.adults ||
      this.state.children !== prevState.children ||
      this.state.prices !== prevState.prices
    ){
      let price = -1;
      for (var i = 0; i < this.state.prices.length; i++){
        let a = this.state.prices[i]
        let year = moment().format('YYYY');
        let dateSData = a.dateStart.split('/');
        let dateS = moment(`${year}-${dateSData[1]}-${dateSData[0]}`);
        let dateEData = a.dateEnd.split('/');
        let dateE = moment(`${year}-${dateEData[1]}-${dateEData[0]}`);
        if (moment(this.state.bookingDate).isBetween(dateS,dateE)){
          debugger;
          let sumPeople = parseInt(this.state.adults) + parseInt(this.state.children);
          switch(sumPeople){
            case 6:
              if (this.state.duration === 'HD'){
                price = parseInt(a.price5);
              }else{
                price = parseInt(a.price6);
              }
              break;
            case 5:
              if (this.state.duration === 'HD'){
                price = parseInt(a.price3);
              }else{
                price = parseInt(a.price4);
              }
              break;
            default:
              if (this.state.duration === 'HD'){
                price = parseInt(a.price);
              }else{
                price = parseInt(a.price2);
              }
              break;
          }
        }
      }
      if (price > -1){
        this.setState({price});
      }else{
        this.setState({price: null})
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        timerStarted: true,
      });
      this.loadData();
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowClose);
  }

  handleWindowClose(e) {
    this.cancelBooking();
  }

  loadData() {
    console.log('loading Data');
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      let pri = resp.data.data.filter(a => a.service === 'SL');
      this.setState({
        prices: pri
      })
    });
  }

  dataChange(e) {
    if (e.target.value === "") {
      e.target.style = "border: solid red 2px;";
    } else {
      e.target.style = "border: transparent red 2px;";
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  dateChangeHandler(date) {
    this.setState({
      birthday: moment(date).toDate(),
    });
  }

  changeAmount(e) {
    var posibleAdults = [];
    var posibleChildren = [];
    var maxPeople = 7 * parseInt(e.target[e.target.selectedIndex].value);

    for (var i = 0; i < maxPeople; i++) {
      posibleAdults.push(i + 1);
      posibleChildren.push(i);
    }

    this.setState({
      amount: e.target[e.target.selectedIndex].value,
      pilotNumber: e.target[e.target.selectedIndex].value,
      posibleAdults,
      posibleChildren,
      maxPeople,
    });
  }

  validateStateData() {
    if (this.state.amount > 1) {
      if (this.state.pilots.length === parseInt(this.state.pilotNumber)) {
        this.setState({
          formValid: true,
          formValidIncomplete: false,
        });
      } else {
        this.setState({
          formValid: false,
          formValidIncomplete: true,
        });
      }
    } else {
      var validName = this.state.name !== "" ? true : false;
      var validSurname = this.state.surname !== "" ? true : false;
      var validIdNum = this.state.idNum !== "" ? true : false;
      var validBirthday = this.state.birthday !== null ? true : false;
      var validBirthlocation = this.state.birthlocation !== "" ? true : false;
      var validResidence = this.state.residence !== "" ? true : false;
      var validStayingin = this.state.stayingin !== "" ? true : false;
      var validPhone = isPhone(this.state.phone);
      var validEmail = isEmail(this.state.email);
      var validHoursSelected = this.state.hoursSelected !== "" ? true : false;

      if (
        validName &&
        validEmail &&
        validPhone &&
        validBirthday &&
        validBirthlocation &&
        validResidence &&
        validStayingin &&
        validIdNum &&
        validSurname
      ) {
        this.setState({
          formValid: true,
          formValidIncomplete: false,
        });
      } else {
        console.log({
          validName: validName,
          validEmail: validEmail,
          validPhone: validPhone,
          validBirthday: validBirthday,
          validBirthlocation: validBirthlocation,
          validResidence: validResidence,
          validStayingin: validStayingin,
          validIdNum: validIdNum,
          validSurname: validSurname
        })
        this.setState({
          formValid: false,
          formValidIncomplete: true,
        });
      }
    }
  }

  validateData(e) {
    var validName = this.state.name !== "" ? true : false;
    var validSurname = this.state.surname !== "" ? true : false;
    var validIdNum = this.state.idNum !== "" ? true : false;
    var validBirthday = this.state.birthday !== null ? true : false;
    var validBirthlocation = this.state.birthlocation !== "" ? true : false;
    var validResidence = this.state.residence !== "" ? true : false;
    var validStayingin = this.state.stayingin !== "" ? true : false;
    var validPhone = isPhone(this.state.phone);
    var validEmail = isEmail(this.state.email);

    if (e.target.name === "name" && validName === false) {
      this.nameInput.style = "border: solid 2px red;";
      this.nameInput.placeholder =
        this.props.Lang.booking.form.name +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.nameInput.focus();
    }

    if (e.target.name === "surname" && validSurname === false) {
      this.surnameInput.style = "border: solid 2px red;";
      this.surnameInput.placeholder =
        this.props.Lang.booking.form.surname +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.surnameInput.focus();
    }

    if (e.target.name === "idNum" && validIdNum === false) {
      this.idNumInput.style = "border: solid 2px red;";
      this.idNumInput.placeholder =
        this.props.Lang.booking.form.idNum +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.idNumInput.focus();
    }

    if (e.target.name === "birthday" && validBirthday === false) {
      this.birthdayInput.style = "border: solid 2px red;";
      this.birthdayInput.placeholder =
        this.props.Lang.booking.form.dateFormat +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.birthdayInput.focus();
    }

    if (e.target.name === "birthlocation" && validBirthlocation === false) {
      this.birthlocationInput.style = "border: solid 2px red;";
      this.birthlocationInput.placeholder =
        this.props.Lang.booking.form.birthLocation +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.birthlocationInput.focus();
    }

    if (e.target.name === "residence" && validResidence === false) {
      this.residenceInput.style = "border: solid 2px red;";
      this.residenceInput.placeholder =
        this.props.Lang.booking.form.residence +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.residenceInput.focus();
    }

    if (e.target.name === "stayingin" && validStayingin === false) {
      this.stayinginInput.style = "border: solid 2px red;";
      this.stayinginInput.placeholder =
        this.props.Lang.booking.form.stayingin +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.stayinginInput.focus();
    }

    if (e.target.name === "phone" && validPhone === false) {
      this.phoneInput.style = "border: solid 2px red;";
      this.phoneInput.placeholder =
        this.props.Lang.booking.form.phone +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.phoneInput.focus();
    }

    if (e.target.name === "email" && validEmail === false) {
      this.emailInput.style = "border: solid 2px red;";
      this.emailInput.placeholder =
        this.props.Lang.booking.form.email +
        " (" +
        this.props.Lang.booking.form.required +
        ")";
      this.emailInput.focus();
    }

    this.setState({
      formValid: false,
    });
  }

  setDuraion(e) {
    var price = null;

    // this.props.setBookingOptions(e.target[e.target.selectedIndex].id);

    //Pedir precio del servicio

    // $.ajax({
    //   url: APPDATA.apiPath + "getServicePrice.php",
    //   context: 'json',
    //   async: false,
    //   data: {
    //     appid: 'root',
    //     month: moment(this.props.MainReducer.bookingDate).format('M'),
    //     day: moment(this.props.MainReducer.bookingDate).format('D'),
    //     service: e.target[e.target.selectedIndex].id
    //   }
    // }).done(function(res) {
    //   res = JSON.parse(res);
    //   if (res.valid === true){
    //     price = res.data;
    //   }
    // });

    this.setState({
      duration: e.target.value,
      hoursSelected: "",
      formValid: false,
    });
  }

  setAdults(e) {
    var posibleChildren = [];
    for (var i = 0; i <= this.state.maxPeople - e.target.value; i++) {
      posibleChildren.push(i);
    }

    this.setState({
      adults: e.target.value,
      posibleChildren,
    });
  }

  setChildren(e) {
    var posibleAdults = [];
    for (var i = 1; i <= this.state.maxPeople - e.target.value; i++) {
      posibleAdults.push(i);
    }

    this.setState({
      children: e.target.value,
      posibleAdults,
    });
  }

  selectHours(hours, morning) {
    if (this.state.hoursSelected !== hours) {
      this.setState({
        hoursSelected: hours,
        morning
      });
    }
  }

  // drawAmounOptions(){
  //   var options = [];
  //   if(this.props.MainReducer.bookingOptions === "4"){
  //     for(var i = 0; i < this.state.serviceBookingsData.freeData.possibleFullDays ; i++){
  //       options.push(<option key={i} value={i+1}>{i+1}</option>);
  //     }
  //   }else{
  //     if (this.props.MainReducer.bookingOptions === "1" || this.props.MainReducer.bookingOptions === "3"){
  //       for(var i = 0; i < this.state.serviceBookingsData.freeData.morning ; i++){
  //         options.push(<option key={i} value={i+1}>{i+1}</option>);
  //       }
  //     }else{
  //       for(var i = 0; i < this.state.serviceBookingsData.freeData.afternoon ; i++){
  //         options.push(<option key={i} value={i+1}>{i+1}</option>);
  //       }
  //     }
  //   }

  //   return options;

  // }

  confirmBooking() {
    window.removeEventListener("beforeunload", this.handleWindowClose);

    $.ajax({
      url: APPDATA.apiPath + "editBookingsRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        props: "state,payment",
        values: "1,1",
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't edit the booking record.");
      }
    });

    if (this.state.amount > 1) {
      var data = {
        name: this.state.pilots[0].name,
        surname: this.state.pilots[0].surname,
        email: this.state.pilots[0].email,
        phone: this.state.pilots[0].phone,
        idNum: this.state.pilots[0].idNum,
        birthday: this.state.pilots[0].birthday,
        birthlocation: this.state.pilots[0].birthlocation,
        residence: this.state.pilots[0].residence,
        stayingin: this.state.pilots[0].stayingin,
        price: this.state.price,
      };
    } else {
      var data = {
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        phone: this.state.phone,
        idNum: this.state.idNum,
        birthday: this.state.birthday,
        birthlocation: this.state.birthlocation,
        residence: this.state.residence,
        stayingin: this.state.stayingin,
        price: this.state.price,
      };
    }

    $.ajax({
      url: APPDATA.apiPath + "createSlBookingRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        name:
          this.state.amount > 1 ? this.state.pilots[0].name : this.state.name,
        surname:
          this.state.amount > 1
            ? this.state.pilots[0].surname
            : this.state.surname,
        birthday:
          this.state.amount > 1
            ? moment(this.state.pilots[0].birthday).format("YYYY/MM/DD")
            : moment(this.state.birthday).format("YYYY/MM/DD"),
        birthlocation:
          this.state.amount > 1
            ? this.state.pilots[0].birthlocation
            : this.state.birthlocation,
        resident: 0,
        residence:
          this.state.amount > 1
            ? this.state.pilots[0].residence
            : this.state.residence,
        phone:
          this.state.amount > 1 ? this.state.pilots[0].phone : this.state.phone,
        email:
          this.state.amount > 1 ? this.state.pilots[0].email : this.state.email,
        idNumber:
          this.state.amount > 1 ? this.state.pilots[0].idNum : this.state.idNum,
        stayingin:
          this.state.amount > 1
            ? this.state.pilots[0].stayingin
            : this.state.stayingin,
        numAdults: this.state.adults,
        numKids: this.state.children,
        ensurance: this.state.ensurance === true ? 1 : 0,
        hoursSelected: this.state.hoursSelected,
        options:
          this.state.hoursSelected !== "" ? this.state.hoursSelected : "",
        allPilots: JSON.stringify(this.state.pilots),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't create the SL booking record.");
      }
    });

    var amount = this.state.amount;

    $.ajax({
      url: APPDATA.mailerPath + this.props.Lang.booking.emailSender,
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        to: amount > 1 ? this.state.pilots[0].email : this.state.email,
        name: amount > 1 ? this.state.pilots[0].name : this.state.name,
        pageName: "sciroccorentaboat.com",
        color: "35336c",
        type: "booking",
        service: this.props.Lang.servicesList.SL,
        ensurance: this.state.ensurance === true ? 1 : 0,
        serviceId: "SL",
        amount: amount,
        date: moment(this.props.MainReducer.bookingDate).format(
          this.props.Lang.dateCode
        ),
        ref: this.state.ref.substring(0, 8),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't send the email.");
      }
    });

    $.ajax({
      url: APPDATA.mailerPath + "notification.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        from: amount > 1 ? this.state.pilots[0].email : this.state.email,
        name: amount > 1 ? this.state.pilots[0].name : this.state.name,
        pageName: "sciroccorentaboat.com",
        color: "35336c",
        type: "booking",
        ensurance: this.state.ensurance === true ? 1 : 0,
        service: this.props.MainReducer.bookingOptions,
        serviceId: "SL",
        amount: amount,
        date: moment(this.props.MainReducer.bookingDate).format("DD/MM/YYYY"),
        ref: this.state.ref.substring(0, 8),
        passengers:
          "Adultos: " + this.state.numAdults + ", niños: " + this.state.numKids,
        hours: this.state.hoursSelected,
      },
    });

    // this.props.confirmBooking(data);
    window.scrollTo(0, 0);
  }

  prepareBooking() {
    var valid = true;
    // var bookingOptions = this.props.MainReducer.bookingOptions;
    var bookingOptions = "55";
    var amount = this.state.amount;

    $.ajax({
      url: APPDATA.apiPath + "getSlBookingsPerDay.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        // date: moment(this.props.MainReducer.bookingDate).format('YYYY/MM/DD')
        date: moment().format("YYYY/MM/DD"),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (bookingOptions === "4") {
        if (res.freeData.possibleFullDays < amount) {
          valid = false;
        }
      }
      if (bookingOptions === "1" || bookingOptions === "3") {
        if (res.freeData.morning < amount) {
          valid = false;
        }
      }
      if (bookingOptions === "11" || bookingOptions === "13") {
        if (res.freeData.afternoon < amount) {
          valid = false;
        }
      }
    });

    if (valid === false) {
      this.unavailableBookingCancel();
      return false;
    }

    $.ajax({
      url: APPDATA.apiPath + "createBookingRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        // date: moment(this.props.MainReducer.bookingDate).format('YYYY/MM/DD'),
        date: moment().format("YYYY/MM/DD"),
        doneDate: moment().format("YYYY/MM/DD"),
        // service: this.props.MainReducer.bookingOptions,
        service: "55",
        amount: this.state.amount,
        payment: 0,
        billDetails: JSON.stringify({
          servicePrice: this.state.price,
          totalPrice: this.state.price * this.state.amount,
        }),
        state: 2,
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't make the booking record.");
        valid = false;
      }
    });
    if (valid === false) {
      this.unavailableBookingCancel();
      return false;
    } else {
      this.setState({
        preBookingDone: true,
      });
      return true;
    }
  }

  savePilot() {
    var pilots = update(this.state.pilots, {});
    var {
      name,
      surname,
      birthday,
      birthlocation,
      idNum,
      residence,
      stayingin,
      phone,
      email,
    } = this.state;
    var valid = false;
    if (this.state.pilots.length === 0) {
      if (
        name !== "" &&
        surname !== "" &&
        birthday !== null &&
        birthlocation !== "" &&
        idNum !== "" &&
        residence !== "" &&
        stayingin !== "" &&
        isPhone(phone) &&
        isEmail(email)
      ) {
        pilots.push({
          name: name,
          surname: surname,
          birthday: birthday,
          birthlocation: birthlocation,
          residence: residence,
          stayingin: stayingin,
          phone: phone,
          email: email,
          idNum: idNum,
        });
        valid = true;
      }
    } else {
      if (
        name !== "" &&
        surname !== "" &&
        birthday !== null &&
        birthlocation !== "" &&
        idNum !== "" &&
        residence !== "" &&
        stayingin !== "" &&
        isPhone(phone)
      ) {
        pilots.push({
          name: name,
          surname: surname,
          birthday: birthday,
          birthlocation: birthlocation,
          residence: residence,
          stayingin: stayingin,
          phone: phone,
          idNum: idNum,
        });
        valid = true;
      }
    }
    if (valid === true) {
      this.setState({
        pilots,
        name: "",
        surname: "",
        birthday: null,
        birthlocation: "",
        residence: "",
        stayingin: "",
        phone: "",
        email: "",
        idNum: "",
      });
    } else {
      this.setState({
        pilotSaveError: this.props.Lang.booking.form.pilotSaveError,
      });
    }
  }

  unavailableBookingCancel() {
    this.setState({
      bookingError: this.props.Lang.booking.form.bookingErrorMsg,
    });
  }

  setEnsurance(value) {
    this.setState({
      ensurance: value,
    });
  }

  handleErrorOnPayment() {
    if (
      this.state.preBookingDone === true &&
      this.props.MainReducer.bookingConfirmed === false
    ) {
      $.ajax({
        url: APPDATA.apiPath + "deleteBooking.php",
        context: "json",
        async: false,
        data: {
          appid: "root",
          ref: this.state.ref,
        },
      });
    }
  }

  // drawPrice(){
  //
  //   for(var i = 0; i < this.props.MainReducer.prices.length; i++){
  //     var item = this.props.MainReducer.prices[i];
  //     if (item.service === this.props.MainReducer.bookingOptions){
  //       return <b className="text-danger">{item.price+ " €"}</b>;
  //     }
  //   }
  // }

  render() {
    //console.log(this.props.Lang);

    return (
      <React.Fragment>
        <Modal isOpen={this.state.bookingError !== ""}>
          <div className="p-3">
            <p className="border rounded border-danger text-danger">
              {this.state.bookingError}
            </p>
            <div>
              <div
                className="btn btn-primary"
                onClick={() => {
                  this.cancelBooking();
                }}
              >
                <span>{this.props.Lang.btns.goBack}</span>
              </div>
            </div>
          </div>
        </Modal>
        <div className="topSpacer"></div>
        <div className="container text-center pb-5">
          <div className="row my-3">
            <div className="col-12 p-3 text-center">
              <h3>{this.props.Lang.servicesList.SL}</h3>
            </div>
            <div className="col-12 p-3 pt-0 text-right">
              <div
                className="btn btn-primary"
                onClick={() => {
                  this.cancelBooking();
                }}
              >
                {/* <i className="fas fa-times"></i> */}
                <span>{this.props.Lang.btns.cancel}</span>
              </div>
            </div>
            <div className="col-12 text-center mb-3">
              <span className="countdownText">
                {this.props.Lang.booking.time}
              </span>
              <br />
              <span className="text-danger countdownTimer px-3">
                <TimerMachine
                  timeStart={600 * 1000} // start at 0 seconds
                  timeEnd={0} // end at 300 seconds
                  started={this.state.timerStarted}
                  paused={this.state.timerPaused}
                  countdown={true} // use as stopwatch
                  interval={1000} // tick every 1 second
                  formatTimer={(time, ms) =>
                    moment.duration(ms, "milliseconds").format("h:mm:ss")
                  }
                  onComplete={(time) => this.cancelBooking()}
                />
              </span>
            </div>
            <div className="col-12 col-md-6 mx-auto h-100 p-3">
              <div>
                <img src={image_1}></img>
                <div className="text-left pt-2 pt-md-4">
                  <p className="text-danger h3">
                    {this.props.Lang.booked.disclaimer.important}
                  </p>
                  <p>{this.props.Lang.booked.disclaimer.boat.model}</p>
                  <p>
                    <b>{this.props.Lang.booked.disclaimer.boat.cancelTitle}</b>
                  </p>
                  <div>
                    <ul>
                      {/* <li>{this.props.Lang.booked.disclaimer.boat.cancelFree}</li> */}
                      <li>
                        {this.props.Lang.booked.disclaimer.boat.cancelPenalty}
                      </li>
                      <li>
                        {this.props.Lang.booked.disclaimer.boat.cancelNoRefound}
                      </li>
                      <li>
                        {
                          this.props.Lang.booked.disclaimer.boat
                            .cancelBadWeather
                        }
                      </li>
                    </ul>
                  </div>
                  <p>{this.props.Lang.booked.disclaimer.earlySL1}</p>
                  <p>{this.props.Lang.booked.disclaimer.earlySL2}</p>
                  <p>{this.props.Lang.booked.disclaimer.gas}</p>
                </div>
              </div>
            </div>
            {!this.state.bookingDateSelected ? (
              <div className="col-12 col-md-6 mx-auto p-3">
                <DatePicker
                  minDate={this.minDate}
                  maxDate={dayjs(new Date()).month(9).date(15).toDate()}
                  value={this.state.bookingDate}
                  label={this.props.Lang.datePicker.bookDay}
                  placeholder={this.props.Lang.dateFormat}
                  onChange={(date) => {
                    if (date){
                      this.setState({bookingDate: date});
                      this.setState({bookingDateSelected: true});
                    }else{
                      this.setState({bookingDateSelected: false})
                    }
                  }}
                ></DatePicker>
              </div>
            ) : (
              <React.Fragment>
                <div className="col-12 col-md-6 mx-auto p-3">
                  <div className="text-left">
                    {/* <p>{this.props.Lang.booked.date}: <b>{moment(this.props.MainReducer.bookingDate).format(this.props.Lang.dateCode)}</b></p> */}
                    <p>
                      {this.props.Lang.booked.date}:{" "}
                      <b>{moment(this.state.bookingDate).format(this.props.Lang.dateCode)}</b>
                    </p>
                    <label htmlFor="duration">
                      {this.props.Lang.booking.form.duration}
                    </label>
                    <select
                      id="duration"
                      value={this.state.duration}
                      className="form-control"
                      onChange={(e) => this.setDuraion(e)}
                    >
                      {/* {this.state.services.map((el, index) => (
                        <option key={index} value={el.duration} id={el.id}>
                          {isNumeric(el.duration)
                            ? parseInt(el.morning) === 1
                              ? el.duration + "h" + " ‌‌ ‌‌ ‌‌ 9:00h - 14:00h"
                              : el.duration + "h" + " ‌‌ ‌‌ ‌‌ 14:00h - 18:00h"
                            : el.duration === "HD"
                            ? parseInt(el.morning) === 1
                              ? this.props.Lang.booking.form.halfDay +
                                " ‌‌ ‌‌ ‌‌ 9:00h - 13:00h"
                              : this.props.Lang.booking.form.halfDay +
                                " ‌‌ ‌‌ ‌‌ 14:00h - 18:00h"
                            : this.props.Lang.booking.form.fullDay +
                              " ‌‌ ‌‌ ‌‌ 9:00h - 18:00h"}
                        </option>
                      ))} */}
                      <option value={'FD'}>{this.props.Lang2.woDriver.oneDay}</option>
                      <option value={'HD'}>4 {this.props.Lang2.woDriver.hours}</option>
                    </select>
                    {/* {moment(this.props.MainReducer.bookingDate).isBetween(this.HdDates.start, this.HdDates.end) === false ? */}
                    {moment().isBetween(
                      this.HdDates.start,
                      this.HdDates.end
                    ) === false ? (
                      this.state.duration !== "FD" ? (
                        <React.Fragment>
                          <label className="mt-3">
                            {this.props.Lang.booking.whatTime}
                          </label>
                          <div className="row">
                              <React.Fragment>
                                <div className={"col-4 px-2"}>
                                  <div
                                    onClick={() =>
                                      this.selectHours("9:00h - 13:00h", 1)
                                    }
                                    className={`hourSelectionPill mb-2 text-center ${
                                      this.state.hoursSelected ===
                                      "9:00h - 13:00h"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    <span className="d-none d-md-inline">
                                      9:00h - 13:00h
                                    </span>
                                    <span className="d-md-none">
                                      <p className="mb-0">9:00h</p>
                                      <p className="mb-0">13:00h</p>
                                    </span>
                                  </div>
                                </div>
                                <div className={"col-4 px-2"}>
                                  <div
                                    onClick={() =>
                                      this.selectHours("10:00h - 14:00h", 1)
                                    }
                                    className={`hourSelectionPill mb-2 text-center ${
                                      this.state.hoursSelected ===
                                      "10:00h - 14:00h"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    <span className="d-none d-md-inline">
                                      10:00h - 14:00h
                                    </span>
                                    <span className="d-md-none">
                                      <p className="mb-0">10:00h</p>
                                      <p className="mb-0">14:00h</p>
                                    </span>
                                  </div>
                                </div>
                                <div className={"col-4 px-2"}>
                                  <div
                                    onClick={() =>
                                      this.selectHours("14:00h - 18:00h", 0)
                                    }
                                    className={`hourSelectionPill mb-2 text-center ${
                                      this.state.hoursSelected ===
                                      "14:00h - 18:00h"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    <span className="d-none d-md-inline">
                                      14:00h - 18:00h
                                    </span>
                                    <span className="d-md-none">
                                      <p className="mb-0">14:00h</p>
                                      <p className="mb-0">18:00h</p>
                                    </span>
                                  </div>
                                </div>
                                <div className={"col-4 px-2"}>
                                  <div
                                    onClick={() =>
                                      this.selectHours("15:00h - 19:00h", 0)
                                    }
                                    className={`hourSelectionPill mb-2 text-center ${
                                      this.state.hoursSelected ===
                                      "15:00h - 19:00h"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    <span className="d-none d-md-inline">
                                      15:00h - 19:00h
                                    </span>
                                    <span className="d-md-none">
                                      <p className="mb-0">15:00h</p>
                                      <p className="mb-0">19:00h</p>
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                          </div>
                        </React.Fragment>
                      ) : null
                    ) : null}
                    {/* <label className="mt-2">{this.props.Lang.booking.quantity}</label>
                  <select className="form-control" name="amount" onChange={e => this.changeAmount(e)}>
                    {this.drawAmounOptions()}
                  </select> */}
                      <label className="mt-3">
                        {this.props.Lang.booking.driverDetails}
                      </label>
                    
                      <React.Fragment>
                        <input
                          ref={(input) => (this.nameInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.name}
                        ></input>
                        <input
                          ref={(input) => (this.surnameInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="surname"
                          value={this.state.surname}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.surname}
                        ></input>
                        <input
                          ref={(input) => (this.idNumInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="idNum"
                          value={this.state.idNum}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.idNum}
                        ></input>
                        <br />
                        {/* <input className="form-control my-2" type="text"  value={moment(this.state.birthday).format('DD/MM/YYYY')} disabled={true} placeholder="Fecha de nacimiento"></input> */}
                        <label className="mt-3">
                          {this.props.Lang.booking.form.birthday}
                        </label>
                        <br />
                        <DatePicker
                          value={this.state.birthday || moment().add(-18, "year").toDate()}
                          maxDate={moment().add(-18, "year").toDate()}
                          onChange={(date) => {
                            if (!date){
                              this.dateChangeHandler(moment().add(-18, "year").toDate())
                            }else{
                              this.dateChangeHandler(date)
                            }
                          }}
                        ></DatePicker>
                        <input
                          ref={(input) => (this.birthlocationInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="birthlocation"
                          value={this.state.birthlocation}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={
                            this.props.Lang.booking.form.birthLocation
                          }
                        ></input>
                        <br />
                        <input
                          ref={(input) => (this.residenceInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="residence"
                          value={this.state.residence}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.residence}
                        ></input>
                        <input
                          ref={(input) => (this.stayinginInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="stayingin"
                          value={this.state.stayingin}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.stayingin}
                        ></input>
                        <br />
                        <input
                          ref={(input) => (this.phoneInput = input)}
                          className="form-control my-2"
                          type="text"
                          name="phone"
                          value={this.state.phone}
                          onChange={(e) => this.dataChange(e)}
                          onBlur={(e) => this.validateData(e)}
                          placeholder={this.props.Lang.booking.form.phone}
                        ></input>
                      </React.Fragment>
                    
                      <input
                        ref={(input) => (this.emailInput = input)}
                        className="form-control my-2"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => this.dataChange(e)}
                        onBlur={(e) => this.validateData(e)}
                        placeholder={this.props.Lang.booking.form.email}
                      ></input>

                    <label className="mt-3">
                      {this.props.Lang.booking.form.adults}
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => this.setAdults(e)}
                    >
                      {this.state.posibleAdults.map((el, index) => (
                        <option key={index} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                    <label className="mt-3">
                      {this.props.Lang.booking.form.kids}
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => this.setChildren(e)}
                    >
                      {this.state.posibleChildren.map((el, index) => (
                        <option key={index} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                    <div className="my-3">
                      <label>
                        {this.state.amount > 1
                          ? this.props.Lang.booking.form.ensurancePerBoat
                          : this.props.Lang.booking.form.ensuranceQuestion}
                      </label>
                    </div>
                    <div className="row my-3">
                      <div className="col-6 text-center">
                        {this.state.ensurance === true ? (
                          <div className="btn btn-primary">
                            {this.props.Lang.btns.yes}
                          </div>
                        ) : (
                          <div
                            className="btn btn-secondary"
                            onClick={() => this.setEnsurance(true)}
                          >
                            {this.props.Lang.btns.yes}
                          </div>
                        )}
                      </div>
                      <div className="col-6 text-center">
                        {this.state.ensurance === false ? (
                          <div className="btn btn-primary">
                            {this.props.Lang.btns.no}
                          </div>
                        ) : (
                          <div
                            className="btn btn-secondary"
                            onClick={() => this.setEnsurance(false)}
                          >
                            {this.props.Lang.btns.no}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 pb-3">
                        <b>{this.props.Lang.btns.yes}:</b> &nbsp;
                        {this.props.Lang.booked.ensuranceYes}
                      </div>
                      <div className="col-12">
                        <b>{this.props.Lang.btns.no}:</b> &nbsp;
                        {this.props.Lang.booked.ensuranceNo}
                      </div>
                    </div>
                    <div className="my-3">
                      <label>
                        {`Cantidad a pagar para reservar `} <b>100€</b> {` de `}:{" "}
                        {this.state.price != null ? (
                          <span><b>
                            {this.state.price + "€"}
                            {this.state.ensurance !== false ? " + " + this.props.Lang.booking.form.ensurance + " (5€)" + " = " : ""}
                            {this.state.ensurance !== false ? this.state.price + 5 + " €" : ""}
                          </b></span>
                        ) : (
                          this.props.Lang.booking.form.calculating + "..."
                        )}
                      </label>
                    </div>
                    <div className="row text-center my-3">
                      <div className="col-12">
                        {this.state.formValidIncomplete === true ? (
                          <p className="border border-danger text-danger rounded">
                            {this.props.Lang.booking.form.makeSure}
                          </p>
                        ) : null}
                        {this.state.formValid === true ? (
                          <p className="border border-success text-success rounded">
                            {this.props.Lang.booking.form.formValid}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <img
                          style={{ width: "150px" }}
                          className="mb-3"
                          src={paymentMethods}
                          alt={this.props.Lang.booking.form.paymentMethods}
                        ></img>
                      </div>
                      <div className="col-12 col-md-6">
                        {this.state.formValid === false ? (
                          <div
                            className={`btn btn-primary`}
                            onClick={this.validateStateData}
                          >
                            {this.props.Lang.booking.form.confirmDetails}
                          </div>
                        ) : (
                          // <div className={`btn btn-primary ${this.state.formValid === true ? "" : "disabled"}`} onClick={()=>{
                          //   if(this.state.formValid === true){
                          //     this.setState({
                          //       pay: true
                          //     });
                          //   }
                          // }}>{this.props.Lang.booking.form.payAndBook}</div>
                          <CheckoutBtn data={this.state} service={'SL'}/>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators({ setBookingOptions, cancelBooking, confirmBooking }, dispatch)
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NoLisenceBoat);

export default LangWrapper;
