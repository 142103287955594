import GoogleMap from "../components/GoogleMap";
import locationMap from "../imgs/map_location.jpg";
import TEXTS from "../Lang/Languages";
import { useParams } from "react-router";

const Contact = () => {
  let {lang} = useParams();
  return (
    <div class="container text-center mt-5 pt-5">
      <div class="row mt-md-4">
        <div class="col-12 col-md-6 text-left p-md-3">
          <h3>{TEXTS[lang].contact.whereTitle}</h3>
          <p>
            {TEXTS[lang].contact.where1}{" "}
            <b> {TEXTS[lang].contact.where2} </b>{" "}
            <b> {TEXTS[lang].contact.where3} </b> {TEXTS[lang].contact.where4}
          </p>
          <div class="py-3">
            <i class="fas fa-2x fa-map-marked-alt"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            {TEXTS[lang].contact.address}
          </div>
          <div class="py-3">
            <i class="fas fa-2x fa-phone"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="tel:+39 345 758 2776">+39 345 758 2776</a>
          </div>
          <div class="py-3 text-success">
            <i class="fab fa-2x fa-whatsapp"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://msng.link/wa/+39 345 758 2776">+39 345 758 2776</a>
          </div>
          <div class="py-3">
            <i class="fas fa-2x fa-at"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto:davidepisati@gmail.com">davidepisati@gmail.com</a>
          </div>
        </div>
        <div class="col-12 col-md-6 p-3">
          <div class="w-100 h-100 d-flex justify-content-end align-items-center">
            <a
              class="btn btn-lg btn-primary"
              href="mailto:davidepisati@gmail.com"
            >
              <i class="fas fa-at"></i>
              {TEXTS[lang].contact.sendEmail}
            </a>
          </div>
        </div>
      </div>

      {/* <div className="row mt-4">
        <div className="col-12 col-md-10 col-lg-8 mx-auto my-4">
          <img className="" src={locationMap} alt="location map"></img>
        </div>
      </div> */}

      <GoogleMap />
    </div>
  );
};

export default Contact;
