const GoogleMap = () => {
  return (
    <div className="row mt-4 mb-5">
      <div className="col-12 col-md-10 col-lg-8 mx-auto my-4">
        <iframe
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.8172194780514!2d9.68387091540865!3d40.83197827931908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x30b63afa42fd0ad1!2sScirocco+%E2%80%A2+rent+a+boat!5e0!3m2!1sen!2ses!4v1549554265828"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
