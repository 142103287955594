import home_1 from "../imgs/home_1.jpg";
import home_2 from "../imgs/escursioni-1.jpg";
import home_3 from "../imgs/beach_aerial.jpg";
import home_4 from "../imgs/Tavolara_pan.jpg";
import home_5 from "../imgs/Molara_pan.jpg";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Tripadvisor from "../components/Tripadvisor";
import TEXTS from "../Lang/Languages";
import GoogleMap from "../components/GoogleMap";
import Meteo from "../components/Meteo";
import Video from "../components/Video";
import langES from "../imgs/flags/es.svg";
import langEN from "../imgs/flags/gb.svg";
import langIT from "../imgs/flags/it.svg";
import langFR from "../imgs/flags/fr.svg";

const langFlags = {
  es: langES,
  en: langEN,
  fr: langFR,
};

const Home = () => {
  var { lang } = useParams();

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="container-fluid p-0 text-center">
      <div className="mb-5">
        <Slider {...settings}>
          <div className="caruselSlide">
            <img src={home_1} />
            <h2 className="alquiler">{TEXTS[lang].home.carousel1}</h2>
          </div>
          <div className="caruselSlide">
            <img src={home_2} />
            <h2 className="visitas">{TEXTS[lang].home.carousel2}</h2>
          </div>
          <div className="caruselSlide">
            <img src={home_3} />
          </div>
          <div className="caruselSlide">
            <img src={home_4} />
          </div>
          <div className="caruselSlide">
            <img src={home_5} />
          </div>
        </Slider>
      </div>

      {lang === "es" || lang === "en" || lang === "fr" ? (
        <div class="row">
          <div class="col-12">
            <h3 class="py-md-3">
              {" "}
              <img style={{ width: 35 }} src={langFlags[lang]} />
              &nbsp;
              &nbsp;
              {TEXTS[lang].home.talkLanguage}
            </h3>
          </div>
        </div>
      ) : null}

      <div className="mt-5">
        <Video />
      </div>

      <div>
        <div className="mt-5">
          <h2>{TEXTS[lang].home.servi1}</h2>
          <Link to={`boats`}>{TEXTS[lang].home.viewPrices}</Link>
        </div>
        <div className="mt-5">
          <h2>{TEXTS[lang].home.servi2}</h2>
          <Link to={`boats_driver`}>{TEXTS[lang].home.viewPrices}</Link>
        </div>
        <div className="mt-5">
          <h2>{TEXTS[lang].home.servi3}</h2>
          <Link to={`group_excursions`}>{TEXTS[lang].home.viewPrices}</Link>
        </div>
      </div>

      <div className="m-5">
        <p>
          {" "}
          <i className="fas fa-phone" style={{ fontSize: "2.5rem" }}></i>
          &nbsp;
          <a
            className="btn btn-success"
            style={{ marginTop: -25 }}
            href="tel:+393457582776"
          >
            +39 345 758 2776
          </a>
          &nbsp;
          <span className="text-success">
            <a
              className="text-success"
              href="https://msng.link/wa/+39 345 758 2776"
            >
              <i
                className="fab fa-whatsapp-square"
                style={{ fontSize: "2.8rem" }}
              ></i>
            </a>
          </span>{" "}
        </p>
      </div>

      <Tripadvisor />

      <div>
        <h4>{TEXTS[lang].footer.schedule}: 8:30 - 18:00</h4>
      </div>

      <GoogleMap />

      <Meteo />
    </div>
  );
};

export default Home;
