const TEXTS = {
  en: {
    utils: {
      dateFormat: "DD/MM/YYYY",
      pricesDateFormat: "DD/MM"
    },
    nav: {
      home: "Home",
      boats: "Boats without skipper",
      boatsDriver: "Boats with skipper",
      excursions: "Group excursions",
      route: "Routes and Gallery",
      contact: "Contact",
      booking: "Book now"
    },
    footer:{
      menu: "Menu",
      schedule: "Schedule",
      months: "From May 1 to October 15.",
      hours: "Every day from 8:30 a.m. to 6:00 p.m.",
      info: "Information",
      location: "Cala Brandinchi Beach - San Teodoro (OT) - Sardinia",
      copyright: "Copyright 2022 All rights reserved."
    },
    home: {
      carousel1: "No licence needed",
      carousel2: "Guided trips",
      servi1: "Rent a boat with or without a licence",
      servi2: "Rent a boat with a skipper",
      servi3: "Group excursions",
      viewPrices: "View Prices",
      weather: "View the weather",
      talkLanguage: "We speak english!"
    },
    contact: {
      whereTitle: "Where we are",
      where1: "Scirocco rent a boat is located on the fantastic",
      where2: "beach of Cala Brandinchi,",
      where3: "municipality of San Teodoro,",
      where4: "15 km south of Olbia, 7 km north of the village of San Teodoro.",
      address: "Cala Brandinchi Beach - San Teodoro (SS) - Sardinia",
      sendEmail: "Send Email"
    },
    route: {
      intro: "The listed route takes one and a half hour to be completed and touches the most extraordinary places of Tavolara Protected Marine Area, so 4 hours are enough to complete the trip including long stops to swim and snorkel in the sea.",
      options: "You can choose 3 options",
      bullet1: "Drive yourself a boat, even if you are not expert we will give you all the instructions and a map with the route indicated",
      bullet2: "Entrust yourself to one of our expert skippers for a private excursion",
      bullet3: "Entrust yourself to one of our expert skippers for a group excursion",
    },
    groups: {
      route: "Join a group, a professional skipper will show you the most magical places of the Protected Marine Area of Tavolara. Swimming in the sea, snorkelling, diving and fun assured!",
      prices: "Prices",
      adults: "Adults",
      children: "Children 3 to 12 years old",
      hours: "hours",
      or: "or",
      stopsTitle: "3 stops of 50 minutes in the most stunning places:",
      stops: "Molara swimming pools. Tavolara beach. The flat rocks.",
      routeChangesDisclaimer: "The listed route may change depending on the weather",
      contractUrl: "../contracts/H20 RENTAL AGRREMENT.pdf",
      contract: "Contract PDF"
    },
    withDriver: {
      length: "Length",
      hp: "hp",
      noLicence: "No Licence",
      trustTitle: "Trust us!",
      trustText: "Our expert skipper will show you the most extraordinary places of Tavolara Protected Marine Area",
      prices: "Prices",
      gasoline: "Skipper and gasoline included",
      date: "Date",
      duration: "Duration",
      hours: "hours",
      oneDay: "One day",
      routeWarning: "The route indicated takes one and a half hour of actual navigation, all the rest is swimming, snorkelling, diving and fun.",
      routeCosts: "The cost of the gas is included in the price in relation to the listed route. In case of any change it will be recalculated. The listed route can be changed on client's request"
    },
    woDriver: {
      length: "Length",
      noLicence: "No licence",
      wLicence: "With licence",
      new: "New",
      hp: "hp",
      gasolinePrices: "Prices gasoline not included",
      date: "Date",
      duration: "Duration",
      hours: "hours",
      oneDay: "One day",
      deposit: "Deposit",
      depositMsg: "500€ Boats without licence",
      depositMsg2: "2000€ Boats with licence",
      identity: "Identity document required",
      equipmentTitle: "All our boats are equipped with:",
      equip1: "Sun shade.",
      equip2: "Wide sundeck.",
      equip3: "Boarding ladder.",
      equip4: "Map of the Protected Marine Area with outlined itinerary.",
      equip5: "Life vests, oars, anchor.",
    },
    tripadvisor: [
      {
        user: "Colin J.",
        comment:
          "Magical Boat Hire. The prices are clear and with no hidden charges. Before you take the boat they give you clear guidance in English. You are provided with a map and recommended swimming stops. The boats themselves are very well maintained, clean and damage free. They all have sun shades (for use whilst stationary)They are very nippy, we didn’t max it out as on about half throttle you are bouncing out of the water with the waves.",
      },
    ]
  },
  es: {
    utils: {
      dateFormat: "DD/MM/YYYY",
      pricesDateFormat: "DD/MM"
    },
    nav: {
      home: "Inicio",
      boats: "Lanchas sin conductor",
      boatsDriver: "Lanchas con conductor",
      excursions: "Excursiones en grupo",
      route: "Ruta y galería",
      contact: "Contacto",
      booking: "Reservar"
    },
    footer:{
      menu: "Menú",
      schedule: "Horario",
      months: "Del 1 de Mayo al 15 de Octubre",
      hours: "Todos los días de 8:30 am a 6:00 pm",
      info: "Información",
      location: "Playa Cala Brandinchi - San Teodoro (OT) - Cerdeñas",
      copyright: "Copyright 2022 Todos los derechos reservados."
    },
    home: {
      carousel1: "No se necesita licencia",
      carousel2: "Visitas guiadas",
      servi1: "Alquila lanchas con o sin licencia",
      servi2: "Alquila lanchas con conductor",
      servi3: "Excursiones en grupo",
      viewPrices: "Ver precios",
      weather: "Ver pronostico del tiempo",
      talkLanguage: "¡Hablamos español!"
    },
    contact: {
      whereTitle: "Donde estamos",
      where1: "Scirocco rent a boat se encuentra en la fantástica",
      where2: "playa de Cala Brandinchi,",
      where3: "municipio de San Teodoro,",
      where4: "a 15 km al sur de Olbia, a 7 km al norte de la aldea de San Teodoro.",
      address: "Playa Cala Brandinchi - San Teodoro (SS) - Cerdeña",
      sendEmail: "Enviar Email"
    },
    route: {
      intro: "El recorrido aquí recomendado requiere una hora y media de navegacions efectiva y va a tocar los lugares mas hermosos del Area Marina Protegida de Tavolara, asi que 4 horas de alquiler ya son suficientes par acabarlo incluyendo largas paradas por banos y snorkeling",
      options: "Puede elegir entre teres opciones",
      bullet1: "Conduce tu una lancha sin licencia, aunque tu seas inexperto te vamos a dar todas las instrucciones necesarias, incluido un mapa con la ruta recomendada",
      bullet2: "Confiate en un conductor experto por una excursion privada",
      bullet3: "Confiate en un conductor experto por una excursion de grupo",
    },
    groups: {
      route: "UNETE A UN GRUPO, UN CONDUCTOR EXPERTO TE HARA DESCUBRIR LOS LIGARES MAGICOS DEL AREA MARINA PROTEGIDA DE TAVOLARA. BANOS, BUCEO, CHAPUZONES Y DIVERSION GARANTIZADOS",
      prices: "Precios",
      adults: "Adultos",
      children: "Niños de 3 a 12 años",
      hours: "horas",
      or: "o",
      stopsTitle: "3 Paradas de 50 minutos en los lugares más espectaculares:",
      stops: "Piscinas de Molara, Playa de Tavolara, Rocas Planas.",
      routeChangesDisclaimer: "El recorrido destacado puede sufrir modificaciones segun el meteos",
      contractUrl: "../contracts/H20 RENTAL AGRREMENT.pdf",
      contract: "Contrato PDF"
    },
    withDriver: {
      length: "Longitud",
      hp: "c.v.",
      noLicence: "Sin Licencia",
      trustTitle: "¡Confía en nosotros!",
      trustText: "Nuestros conductores expertos te harán descubrir los lugares mágicos del area marina protegida de Tavolara.",
      prices: "Precios",
      gasoline: "Conductor y gasolina incluido",
      date: "Fechas",
      duration: "Duración",
      hours: "horas",
      oneDay: "Un día",
      routeWarning: "El recorrido destacado requiere 1 hora y media de navegación efectiva, el resto son baños, buceo, chapuzones y diversión.",
      routeCosts: "El coste de la gasolina está incluido en el precio con relacion al recorrido aquá destacado, para eventuales variaciones habrá que recalcularlo. El recorrido puede variar a petición del cliente."
    },
    woDriver: {
      length: "Longitud",
      noLicence: "Sin licencia",
      wLicence: "Con licencia",
      new: "Nuevo",
      hp: "c.v.",
      gasolinePrices: "Precios sin incluir gasolina",
      date: "Fecha",
      duration: "Duración",
      hours: "horas",
      oneDay: "Un día",
      deposit: "Deposito",
      depositMsg: "500€ Lanchas sin licencia",
      depositMs2: "2000€ lanchas con licencia",
      identity: "Documento de identidad requerido",
      equipmentTitle: "Todos nuestros barcos están equipados con:",
      equip1: "Toldo.",
      equip2: "Gran solarium.",
      equip3: "Escalera para subir.",
      equip4: "Mapa del Área Marina Protegida con rutas marcadas.",
      equip5: "Equipamento legal: chalecos salvavidas, remos, ancla.",
    },
    tripadvisor: [
      {
        user: "bicocchinaonesta",
        comment:
          "Amabilidad y experiencia en una de las zonas más hermosas de Cerdeña, en las aguas cristalinas de Cala Brandinchi, puede encontrar el alquiler de botes Scirocco di Davide y Kim, nuevos botes, profesionalidad, precios justos y también aceptar tarjetas de crédito ... alquilar un barco y diviértete descubriendo las bahías más hermosas de la zona, también es bueno llevar el almuerzo y comer anclados en el paraíso ...",
      },
      {
        user: "DeniseDessena",
        comment:
          "Espectáculo de la naturaleza. Definitivamente una de las playas más hermosas del norte de Cerdeña. Insertado dentro del parque, un área marina protegida impecablemente. Pagué veinte euros por día por aparcar, porque toda la zona se mantiene realmente bien, pagas pero tienes un buen servicio. El establecimiento se encuentra en el centro de la playa (recomiendo reservar) con pago por adelantado, pero al menos podemos estar seguros de tener la ubicación. Muy bien cuidado, solo 18 paraguas con bote de goma y alquiler de pedalo, debajo de cada paraguas había un cubo para tirar la escoria. Bien hecho, así que todo se mantiene limpio. Una visita obligada para aquellos que visitan esta zona, verdaderamente un lugar fabuloso. Mira las fotos que hablan por sí mismas ...",
      },
      {
        user: "Rosa793416",
        comment:
          "Profesionalidad y simpatía. Alquilamos un bote inflable y recibimos información detallada del personal sobre todo lo necesario para llegar a los diversos puntos de interés del tavolara e información técnica sobre la balsa.",
      },
    ]
  },
  it: {
    utils: {
      dateFormat: "DD/MM/YYYY",
      pricesDateFormat: "DD/MM"
    },
    nav: {
      home: "Home",
      boats: "Gommoni senza conducente",
      boatsDriver: "Gommoni con conducente",
      excursions: "Escursioni di gruppo con conducente",
      route: "Percorsi e galleria",
      contact: "Contatti",
      booking: "Prenota"
    },
    footer:{
      menu: "Menu",
      schedule: "Orario",
      months: "Dal 1 maggio al 15 ottobre.",
      hours: "Tutti i giorni dalle 8:30 alle 18:00",
      info: "Informazioni",
      location: "Spiaggia Cala Brandinchi - San Teodoro (OT) - Sardegna",
      copyright: "Copyright 2022 Tutti i diritti riservati."
    },
    home: {
      carousel1: "GOMMONI SENZA PATENTE",
      carousel2: "ESCURSIONI CON CONDUCENTE",
      servi1: "NOLEGGIO GOMMONI CON O SENZA PATENTE NAUTICA",
      servi2: "NOLEGGIO GOMMONI CON CONDUCENTE",
      servi3: "ESCURSIONI DI GRUPPO",
      viewPrices: "Vedi prezzi",
      weather: "Il Meteo"
    },
    contact: {
      whereTitle: "Dove siamo",
      where1: "Scirocco rent a boat si trova sulla fantastica",
      where2: "spiaggia di Cala Brandinchi,",
      where3: "comune di San Teodoro,",
      where4: "15 km a sud di Olbia, 7 km a nord del paese di San Teodoro",
      address: "Spiaggia Cala Brandinchi - San Teodoro (SS) - Sardegna",
      sendEmail: "Invia email"
    },
    route: {
      intro: "Il percorso qui indicato richiede un' ora e mezza di navigazione efettiva e tocca i luoghi piu suggestivi dell Area Marina Protetta di Tavolara, quindi 4 ore di noleggio sono già sufficienti per completarlo includendo lunghe soste per bagni e snorkeling.",
      options: "Puoi scegliere 3 formule",
      bullet1: "Guida tu un gommone senza patente, se sei inesperto ti daremo tutte le istruzioni necessarie, inclusa una mappa con il percorso tracciato",
      bullet2: "Affidati a un nostro skipper esperto per un' escursione privata",
      bullet3: "Affidati a un nostro skipper esperto per un' escursione di gruppo",
    },
    groups: {
      route: "UNISCITI A UN GRUPPO, UN CONDUCENTE ESPERTO TI FARA' SCOPRIRE I LUOGHI MAGICI DELL AREA MARINA PROTETTA DI TAVOLARA. BAGNI, SNORKELLING, TUFFI E DIVERTIMENTO GARANTITI!",
      prices: "Prezzi",
      adults: "Adulti",
      children: "Bambini sotto 12 anni",
      hours: "ore",
      or: "o",
      stopsTitle: "3 SOSTE DI 50 MINUTI NEI LUOGHI PIU SPETTACOLARI:",
      stops: "PISCINE DI MOLARA, SPIAGGIA DI TAVOLARA, SASSI PIATTI.",
      routeChangesDisclaimer: "Il percorso indicato può subire variazioni a seconda del meteo.",
      contractUrl: "../contracts/H20 RENTAL AGRREMENT.pdf",
      contract: "Contract PDF"
    },
    withDriver: {
      length: "Lunghezza",
      hp: "cv",
      noLicence: "Senza patente",
      trustTitle: "AFFIDATI A NOI !",
      trustText: "I NOSTRI CONDUCENTI ESPERTI TI FARANNO SCOPRIRE I LUOGHI MAGICI DELL AREA MARINA PROTETTA DI TAVOLARA.",
      prices: "Prezzi",
      gasoline: "Conducente e benzina inclusi",
      date: "Data",
      duration: "Durata",
      hours: "ore",
      oneDay: "Giornata",
      routeWarning: "IL PERCORSO EVIDENZIATO RICHIEDE 1 ORA E MEZZA DI NAVIGAZIONE EFFETTIVA, IL RESTO SONO BAGNI, SNORKELING, TUFFI E DIVERTIMENTO",
      routeCosts: "il costo della benzina è compreso nel prezzo relativamente al percorso sopra indicato, per eventuali variazioni andrà dunque rivalutato. Il percorso sopra indicato può variare a richiesta del cliente."
    },
    woDriver: {
      length: "Lunghezza",
      noLicence: "Senza patente",
      wLicence: "Con patente",
      new: "Nuovi",
      hp: "cv",
      gasolinePrices: "Prezzi benzina esclusa",
      date: "Data",
      duration: "Durata",
      hours: "ore",
      oneDay: "Giornata",
      deposit: "Cauzione",
      depositMsg: "500€ GOMMONI SENZA PATENTE",
      depositMsg2: "2000€ GOMMONI CON PATENTE",
      identity: "RICHIESTO DOCUMENTO DI IDENTITA",
      equipmentTitle: "Tutti i nostri gomm sono dotati di:",
      equip1: "Tendalino parasole.",
      equip2: "Ampio prendisole.",
      equip3: "Scaletta di risalita.",
      equip4: "Mappa dell’Area Marina Protetta con percorsi segnalati.",
      equip5: "Dotazioni di legge: salvagenti, remi, ancora.",
    },
    tripadvisor: [
      {
        user: "bicocchinaonesta",
        comment:
          "Cordialità ed esperienza in una delle zone più belle della Sardegna, nelle acque cristalline di Cala Brandinchi, puoi trovare il noleggio di barche Scirocco di Davide e Kim, nuove imbarcazioni, professionalità, prezzi onesti e accetta anche carte di credito ... noleggia una barca e divertiti a scoprire la Le baie più belle della zona, è anche bello pranzare e mangiare in un paradiso ancorato ...",
      },
      {
        user: "DeniseDessena",
        comment:
          "Spettacolo della natura Sicuramente una delle spiagge più belle del nord Sardegna. Inserito all'interno del parco, un'area marina protetta in maniera impeccabile. L'intera area è davvero buona, si paga ma si ha un buon servizio. La struttura si trova nel centro della spiaggia (consiglio di prenotare) con pagamento anticipato, ma almeno possiamo essere sicuri di avere la posizione. Molto ben tenuto, sotto ogni ombrello c'era un secchio per buttare via le scorie. Ben fatto, quindi tutto rimane pulito. Un must per chi visita questa zona, davvero un posto favoloso.",
      },
      {
        user: "Rosa793416",
        comment:
          "Professionalità e simpatia. Abbiamo noleggiato un gommone e ricevuto informazioni dettagliate dallo staff su tutto il necessario per raggiungere i vari punti di interesse della tavolara e le informazioni tecniche sulla zattera.",
      },
    ]
  },
  fr: {
    utils: {
      dateFormat: "DD/MM/YYYY",
      pricesDateFormat: "DD/MM"
    },
    nav: {
      home: "Accueil",
      boats: "Bateaux sans conducteur",
      boatsDriver: "Bateaux avec chauffeur usage exclusif",
      excursions: "sorties de groupe avec conducteur",
      route: "Route and Galery",
      contact: "Contact",
      booking: "Book now"
    },
    footer:{
      menu: "Menu",
      schedule: "Schedule",
      months: "From May 1 to October 15.",
      hours: "Every day from 8:30 a.m. to 6:00 p.m.",
      info: "Information",
      location: "Cala Brandinchi Beach - San Teodoro (OT) - Sardinia",
      copyright: "Copyright 2022 All rights reserved."
    },
    home: {
      carousel1: "Bateaux sans conducteur",
      carousel2: "Bateaux avec chauffeur",
      servi1: "Louer un bateau avec ou sans permis",
      servi2: "Louer un bateau avec chauffeur",
      servi3: "Excursions en groupe",
      viewPrices: "Voir les prix",
      weather: "Voir la météo",
      talkLanguage: "On parle français!"
    },
    contact: {
      whereTitle: "Ou sommes nous",
      where1: "Scirocco rent a boat est situé dans la merveilleuse",
      where2: "plage de Cala Brandinchi,",
      where3: "commune de San Teodoro,",
      where4: "15 km au sud de Olbia, 7 km au nord de San Teodoro village.",
      address: "Plage Cala Brandinchi - San Teodoro (SS) - Sardaigne",
      sendEmail: "ENvoyer un email"
    },
    route: {
      intro: "The listed route needs one and a half hour to be completed and touches the most extraordinary places of Tavolara Protected Marine Area, so 4 hours are enough to complete the trip including long stops to swim and snorkel in the sea.",
      options: "You can choose 3 options",
      bullet1: "Drive a boat yourself, even if you are not expert we will give you all the instructions and a map with the route indicated",
      bullet2: "Entrust yourself to one of our expert skippers for a private excursion",
      bullet3: "Entrust yourself to one of our expert skippers for a group excursion",
    },
    groups: {
      route: "Rejoignez un groupe, un conducteur expert vous fairà découvrir les lieux magiques de l'Area Marine Protègée de Tavolara. Baignades, plongées et divertissement garantis !",
      prices: "PRIX",
      adults: "Adultes",
      children: "Enfants moins de 12 ans",
      hours: "heures",
      or: "ou",
      stopsTitle: "3 arrets de 50 minutes dans les lieux plus expectaculaires:",
      stops: "Les Piscines de Molara, la plage de Tavolara, les Rochers Plats.",
      routeChangesDisclaimer: "Le parcours indiqué peut subir des variations selon la metéo",
      contractUrl: "../contracts/H20 RENTAL AGRREMENT.pdf",
      contract: "Contract PDF"
    },
    withDriver: {
      length: "longueur",
      hp: "ch",
      noLicence: "Sans permis",
      trustTitle: "FAITES NOUS CONFIANCE!",
      trustText: "Nos experts conducteurs vous fairons découvrir les endroits magiques de l'Area Marine Protégée de Tavolara",
      prices: "Les prix",
      gasoline: "conducteur et essence inclus",
      date: "Dates",
      duration: "Durée",
      hours: "heures",
      oneDay: "Journée",
      routeWarning: "LE PARCOURS INDIQUE' DEMANDE UNE HEURE ET DEMI DE NAVIGATION EFFECTIVE, TOUT LE RESTE C'EST DES BAIGNADES, PLONGEéES,PLONGEONS ET AMUSEMENT",
      routeCosts: "le prix de l 'essence est compris relativement au parcours indiqué, pour des eventuelles variations il faudrà le recalculer. Le parcours peut varier sur demande du client."
    },
    woDriver: {
      length: "longueur",
      noLicence: "Sans permis",
      wLicence: "Avec permis",
      new: "Nouveau",
      hp: "ch",
      gasolinePrices: "Prix essence à part",
      date: "Dates",
      duration: "Durée",
      hours: "heures",
      oneDay: "Journée",
      deposit: "caution",
      depositMsg: "500€ bateaux sans permis",
      depositMsg2: "2000€ bateaux avec permis",
      identity: "Document d'identitèe éxigé",
      equipmentTitle: "Tous nos bateaux sont équipés de:",
      equip1: "taud de soleil.",
      equip2: "ample plate-forme bain de soleil.",
      equip3: "échelle pour remonter à bord.",
      equip4: "carte de la Zone Marine Protégée avec les parcours indiquées.",
      equip5: "équipement de sécurité: gilets de sauvetage, ancre, avirons.",
    },
    tripadvisor: [
      {
        user: "Phieso F.",
        comment:
          "Une sortie bateau à ne pas manquer! Nous avons loué un bateau sans permis pour l’après-midi avec nos 2 enfants. Le bateau était en parfait état, très confortable (matelassé partout). Les explications fournies par David étaient impeccables, précises, dans un français parfait et le circuit qu’il recommande est juste magique! Un seul regret, ne pas avoir pris la journée car c’était trop top!",
      },
    ]
  },
  ru: {},
  de: {
    utils: {
      dateFormat: "DD/MM/YYYY",
      pricesDateFormat: "DD/MM"
    },
    nav: {
      home: "Home",
      boats: "Boote ohne Fahrer",
      boatsDriver: "Boote mit Fahrer",
      excursions: "Gruppenausflüge",
      route: "Route und Galerie",
      contact: "Kontakt",
      booking: "Buchen Sie jetzt"
    },
    footer:{
      menu: "Menu",
      schedule: "Zeitplan",
      months: "Vom 1. Mai bis 15. Oktober.",
      hours: "Jeden Tag von 8.30 bis 18.00 Uhr.",
      info: "Informationen",
      location: "Strand Cala Brandinchi - San Teodoro (OT) - Cerdeña",
      copyright: "Copyright 2019 Alle Rechte vorbehalten."
    },
    home: {
      carousel1: "UNLICENSED MIETEN",
      carousel2: "FÜHRUNGEN",
      servi1: "Mieten Sie ein Boot mit oder ohne Führerschein",
      servi2: "Mieten Sie ein Boot mit Fahrer",
      servi3: "Gruppenausflüge",
      viewPrices: "Preise anzeigen",
      weather: "Das Wetter"
    },
    contact: {
      whereTitle: "Wo sind wir",
      where1: "Scirocco rent a boat befindet sich am fantastischen",
      where2: "Strand von Cala Brandinchi,",
      where3: "der Gemeinde San Teodoro,",
      where4: "15 km südlich von Olbia, 7 km nördlich des Dorfes San Teodoro.",
      address: "Strand Cala Brandinchi - San Teodoro (SS) - Cerdeña",
      sendEmail: "E-Mail senden"
    },
    route: {
      intro: "Die aufgeführte Route dauert anderthalb Stunden und berührt die außergewöhnlichsten Orte des Meeresschutzgebiets von Tavolara, sodass 4 Stunden ausreichen, um die Reise abzuschließen, einschließlich langer Stopps zum Schwimmen und Schnorcheln im Meer.",
      options: "Sie können 3 Optionen wählen:",
      bullet1: "Fahren Sie selbst ein Boot, auch wenn Sie kein Experte sind, wir geben Ihnen alle Anweisungen und eine Karte mit der angezeigten Route",
      bullet2: "Vertrauen Sie sich einem unserer erfahrenen Skipper für einen privaten Ausflug an",
      bullet3: "Vertrauen Sie sich einem unserer erfahrenen Skipper für einen Gruppenausflug an",
    },
    groups: {
      route: "Schließen Sie sich einer Gruppe an, ein professioneller Skipper lässt Sie die magischsten Orte des geschützten Meeresgebiets von Tavolara entdecken. Schwimmen im Meer, Schnorcheln, Tauchen und Spaß garantiert!",
      prices: "Preise",
      adults: "Erwachsene",
      children: "Kinder von 3 bis 12 Jahren",
      hours: "Std.",
      or: "oder",
      stopsTitle: "3 Haltestellen von 50 Minuten an den schönsten Orten:",
      stops: "Molara-Schwimmbäder. Tavolara-Strand. Die flachen Felsen.",
      routeChangesDisclaimer: "Die aufgeführte Route kann sich je nach Wetterlage ändern",
      contractUrl: "../contracts/H20 RENTAL AGRREMENT.pdf",
      contract: "Contract PDF"
    },
    withDriver: {
      length: "Länge",
      hp: "PS",
      noLicence: "Keine Lizenz",
      trustTitle: "Trust us!",
      trustText: "Our expert skipper will make you discover to the most extraordinary places of Tavolara Protected Marine Area",
      prices: "Preise",
      gasoline: "Skipper and gasoline included",
      date: "Datum",
      duration: "Dauer",
      hours: "Std.",
      oneDay: "Ganzer Tag",
      routeWarning: "The route indicated needs one and a half hour of actual navigation, all the rest is swimming, snorkelling, diving and fun.",
      routeCosts: "The cost of the gas is included in the price in relation to the listed route. In case of any change it will be recalculated. The listed route can be changed on client's request"
    },
    woDriver: {
      length: "Länge",
      noLicence: "Keine Lizenz",
      wLicence: "Mit Lizenz",
      new: "Neu",
      hp: "PS",
      gasolinePrices: "Preise ohne Benzin",
      date: "Datum",
      duration: "Dauer",
      hours: "Std.",
      oneDay: "Ganzer Tag",
      deposit: "Anzahlung",
      depositMsg: "500€ Boote ohne Führerschein",
      depositMsg2: "2000€ Boote mit Lizenz",
      identity: "Ausweis erforderlich",
      equipmentTitle: "Alle unsere Boote sind ausgestattet mit:",
      equip1: "Großes Sonnendeck am Heck und am Bug.",
      equip2: "Sonnenverdeck.",
      equip3: "Treppchen zum Hinaufsteigen.",
      equip4: "Karte des unter Schutz stehenden Meeresgebiets mit angezeigten Routen.",
      equip5: "Gesetzmäßige Ausstattung: Rettungsringe, Ruder, Anker.",
    },
    tripadvisor: [
      {
        user: "Colin J.",
        comment:
          "Was für ein Wahnsinns-Erlebniss. Unsere Anmeldung erfolgte über Whats App, total unproblematisch. Klappte alles reibungslos, wurden super nett in Empfang genommen, kurze Einweisung in die Route und in das Boot - sprechen perfekt Englisch. Die Boote sind in einem sehr guten Zustand. Und dann geht es los, durch das karibikblaue Wasser....ein Traum. Das 40 PS Boot geht ganz schön ab, reicht volkommen aus. Wir hatten einen Menge Spaß, die besprochene Route ist einzigartig. Leider hatten wir das Boot nur für 4 Stunden gemietet, würden wir beim nächsten Mal anders machen. Auch die Rückgabe war unproblematisch. Eine absolute Empfehlung, 5*****, DANKE für dieses unvergessliche Erlebniss.",
      },
    ]
  }
}

export default TEXTS;