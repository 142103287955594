import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import video1 from '../video/scirocco.mp4';
import { useState } from "react";

const Video = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container my-5">
      {/* <video style={{width: '100%'}} controls="true" src={video1} alt="scirocco rent a boat video" /> */}
      <div className="iframe-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Go5rOsPLjf4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default Video;