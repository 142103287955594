import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import Booking from "../../pages/Booking";

const okStyle = {
  backgroundColor: "#83FF0055",
};
const newStyle = {
  backgroundColor: "#FFEA0055",
};
const cancelStyle = {
  backgroundColor: "#ff840055",
};
const canceledStyle = {
  backgroundColor: "#ff000055",
};

const AdminDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    loadData();
  }, [date]);

  const loadData = () => {
    // request all bookings from database for date's week.
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/getBooking2Info.php",
      params: {
        appid: "root",
        all: "all",
      },
    }).then((resp) => {
      console.log(resp);
      setBookings(resp.data.data);
    });
  };

  const drawSLBoats = () => {
    return (
      <div>
        <div className="row text-center p-0 m-0">
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark">Data</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">A</div>
              <div className="col-4 border border-dark">B</div>
              <div className="col-4 border border-dark">T</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">A</div>
              <div className="col-4 border border-dark">B</div>
              <div className="col-4 border border-dark">T</div>
            </div>
          </div>
          {/* <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">A</div>
              <div className="col-4 border border-dark">B</div>
              <div className="col-4 border border-dark">T</div>
            </div>
          </div> */}
          <div className="col-2 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">TOT</div>
              <div className="col-4 border border-dark">ACC</div>
              <div className="col-4 border border-dark">SAL</div>
            </div>
          </div>
          <div className="col-5 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">TEL</div>
              <div className="col-4 border border-dark">NOME</div>
              <div className="col-4 border border-dark">MAIL</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark">STATUS</div>
            </div>
          </div>
        </div>
        <div>
          {bookings
            ?.filter((a) =>
              a && a.service && a.service == "SL" ? true : false
            )
            .map((el, i) => {
              let otherData = JSON.parse(el.data);
              if (i === 0) console.log(el.status);
              return (
                <div
                  className="row text-center p-0 m-0"
                  style={
                    parseInt(el.status) === 1
                      ? okStyle
                      : parseInt(el.status) === -1
                      ? cancelStyle
                      : parseInt(el.status) === -2
                      ? canceledStyle
                      : newStyle
                  }
                >
                  <div className="col-1 border border-dark">
                    <div className="row">
                      <div className="col-12 border border-dark">
                        {moment(otherData.bookingDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 border border-dark">
                    <div className="row">
                      <div className="col-4 border border-dark">
                        {otherData.morning ? (
                          parseInt(otherData.adults)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="col-4 border border-dark">
                        {otherData.morning ? (
                          parseInt(otherData.children)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="col-4 border border-dark">
                        {otherData.morning ? (
                          parseInt(otherData.adults) +
                          parseInt(otherData.children)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 border border-dark">
                    <div className="row">
                      <div className="col-4 border border-dark">
                        {!otherData.morning ? (
                          parseInt(otherData.adults)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="col-4 border border-dark">
                        {!otherData.morning ? (
                          parseInt(otherData.children)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="col-4 border border-dark">
                        {!otherData.morning ? (
                          parseInt(otherData.adults) +
                          parseInt(otherData.children)
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-1 border border-dark">
                    <div className="row">
                      <div className="col-4 border border-dark">
                        {parseInt(otherData.adults)}
                      </div>
                      <div className="col-4 border border-dark">
                        {parseInt(otherData.children)}
                      </div>
                      <div className="col-4 border border-dark">
                        {parseInt(otherData.adults) +
                          parseInt(otherData.children)}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-2 border border-dark">
                    <div className="row">
                      <div className="col-4 border border-dark">
                        {otherData.price}
                      </div>
                      <div className="col-4 border border-dark">
                        {"2" ? "100" : "0"}
                      </div>
                      <div className="col-4 border border-dark">
                        {otherData.price
                          ? parseInt(otherData.price) - 100
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col-4 border border-dark">
                    <div className="row">
                      <div className="col-1 border border-dark">
                        {otherData.phone}
                      </div>
                      <div className="col-4 border border-dark">
                        {otherData.name}
                      </div>
                      <div className="col-4 border border-dark">{el.email}</div>
                    </div>
                  </div>
                  <div className="col-1 border border-dark">
                    <div className="row">
                      <div className="col-12 border border-dark">{
                    parseInt(el.status) === 1
                      ? "OK"
                      : parseInt(el.status) === -1
                      ? "To cancel"
                      : parseInt(el.status) === -2
                      ? "Canceled"
                      : "New"
                  }</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row text-center p-0 m-0">
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark">&nbsp;</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-2 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-5 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">Pedidos</div>
              <div className="col-4 border border-dark">Sin deposito</div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark"></div>
            </div>
          </div>
        </div>
        <div className="row text-center  p-0 m-0">
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-2 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-5 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">Pedidos</div>
              <div className="col-4 border border-dark">Con deposito</div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark"></div>
            </div>
          </div>
        </div>
        <div className="row text-center p-0 m-0">
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark">&nbsp;</div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-2 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">&nbsp;</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-5 border border-dark">
            <div className="row">
              <div className="col-4 border border-dark">Confirmado</div>
              <div className="col-4 border border-dark"></div>
              <div className="col-4 border border-dark"></div>
            </div>
          </div>
          <div className="col-1 border border-dark">
            <div className="row">
              <div className="col-12 border border-dark"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        minWidth: "100%",
        maxWidth: 500,
      }}
    >
      <h3 className="bg-info p-3 text-center">Dashboard</h3>

      <div>{drawSLBoats()}</div>
    </div>
  );
};

export default AdminDashboard;
