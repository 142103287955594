import { useEffect, useState } from "react";

import image_1 from "../imgs/620.jpg";
import image_2 from "../imgs/zar_61.jpg";
import image_3 from "../imgs/escursioni-1.jpg";

import PrenotaWhatsappVisa from "../components/PrenotaWhatsappVisa";
import moment from "moment";
import TEXTS from "../Lang/Languages";
import { useParams } from "react-router";
import Tripadvisor from "../components/Tripadvisor";
import Meteo from "../components/Meteo";
import MapAndPictures from "../components/MapAndPictures";
import Video from "../components/Video";
import axios from "axios";

const halfDuration = "4";

const BoatsWithDriver = () => {
  const { lang } = useParams();
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    loadPrices();
  }, []);

  const loadPrices = () => {
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      let pri = resp.data.data.filter(a => a.service === 'CC');
      setPrices(pri);
    });
  };

  // useEffect(() => {
  //   setPrices([
  //     {
  //       dateStart: "05/01/2022",
  //       dateEnd: "10/15/2022",
  //       halfDuration: "4",
  //       half: { l5: 350, l12: 500 },
  //       full: { l5: 600, l12: 1000 },
  //     },
  //     // {
  //     //   dateStart: "05/01/2022",
  //     //   dateEnd: "06/24/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 250, l8: 350, l12: 450 },
  //     //   full: { l5: 350, l8: 500, l12: 650 },
  //     // },
  //     // {
  //     //   dateStart: "06/25/2022",
  //     //   dateEnd: "07/08/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 240, l8: 330, l12: 430 },
  //     //   full: { l5: 370, l8: 460, l12: 600 },
  //     // },
  //     // {
  //     //   dateStart: "07/09/2022",
  //     //   dateEnd: "07/22/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 250, l8: 360, l12: 460 },
  //     //   full: { l5: 400, l8: 550, l12: 700 },
  //     // },
  //     // {
  //     //   dateStart: "07/23/2022",
  //     //   dateEnd: "08/12/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 260, l8: 380, l12: 480 },
  //     //   full: { l5: 450, l8: 650, l12: 900 },
  //     // },
  //     // {
  //     //   dateStart: "08/13/2022",
  //     //   dateEnd: "08/26/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 270, l8: 400, l12: 500 },
  //     //   full: { l5: 500, l8: 700, l12: 950 },
  //     // },
  //     // {
  //     //   dateStart: "08/27/2022",
  //     //   dateEnd: "09/09/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 260, l8: 400, l12: 480 },
  //     //   full: { l5: 450, l8: 650, l12: 900 },
  //     // },
  //     // {
  //     //   dateStart: "09/10/2022",
  //     //   dateEnd: "09/16/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 250, l8: 360, l12: 460 },
  //     //   full: { l5: 400, l8: 550, l12: 700 },
  //     // },
  //     // {
  //     //   dateStart: "09/17/2022",
  //     //   dateEnd: "10/15/2022",
  //     //   halfDuration: "4",
  //     //   half: { l5: 240, l8: 330, l12: 430 },
  //     //   full: { l5: 370, l8: 460, l12: 600 },
  //     // }
  //   ]);
  // }, []);

  return (
    <div>
      <div style={{ height: "50px" }}></div>
      <div className="container text-center">
        <img src={image_1} alt="barco uno" />
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          5 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 6,2m - 40 {TEXTS[lang].withDriver.hp} - {TEXTS[lang].withDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          5 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 6,2m <br /> 40 {TEXTS[lang].withDriver.hp} -
          {TEXTS[lang].withDriver.noLicence}
        </h3>

        <img src={image_2} alt="barco uno" />
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          8 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 6m - 150 {TEXTS[lang].withDriver.hp} - {TEXTS[lang].withDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          8 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 6m <b15 /> 40 {TEXTS[lang].withDriver.hp} - {TEXTS[lang].withDriver.noLicence}
        </h3>

        <img src={image_3} alt="barco uno" />
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          12 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 8m - 250 {TEXTS[lang].withDriver.hp} - {TEXTS[lang].withDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          12 &nbsp;<i className="fas fa-male"></i> - {TEXTS[lang].withDriver.length} 8m <br /> 250 {TEXTS[lang].withDriver.hp} -
          {TEXTS[lang].withDriver.noLicence}
        </h3>

        <div className="row my-5">
          <div className="col-12">
            <p className="text-danger h3">{TEXTS[lang].withDriver.trustTitle}</p>
            <p>
            {TEXTS[lang].withDriver.trustText}
            </p>
          </div>
        </div>

        <Video />

        <div className="row my-5">
          <div className="col-12">
            <h3 className="h3">{TEXTS[lang].withDriver.prices}</h3>
            <p className="text-danger h3">{TEXTS[lang].withDriver.gasoline}</p>
          </div>
        </div>

        <PrenotaWhatsappVisa />

        <div className="m-auto" style={{ maxWidth: 750, paddingTop: 40 }}>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-4  py-2"></div>
            <div className="col-2 border border-gray py-2">
              5&nbsp;<i className="fas fa-male"></i>
            </div>
            {/* <div className="col-2 border border-gray py-2">
              8&nbsp;<i className="fas fa-male"></i>
            </div> */}
            <div className="col-2 border border-gray py-2">
              12&nbsp;<i className="fas fa-male"></i>
            </div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-2 border border-gray py-2 px-0">{TEXTS[lang].withDriver.date}</div>
            <div className="col-2 border border-gray py-2 px-0">
              <div className="d-md-none">
                <i className="fas fa-clock"></i>
              </div>
              <div className="d-none d-md-block">{TEXTS[lang].withDriver.duration}</div>
            </div>
            <div className="col-2 border border-gray py-2 px-0">6,20m <br/> 40 {TEXTS[lang].withDriver.hp}</div>
            {/* <div className="col-2 border border-gray py-2 px-0">6m <br/> 150 {TEXTS[lang].withDriver.hp}</div> */}
            <div className="col-2 border border-gray py-2 px-0">8m <br/> 250 {TEXTS[lang].withDriver.hp}</div>
          </div>

          {prices.length === 0 ? (
            <>
              <i className="fas fa-spinner fa-spin" />
            </>
          ) : (
            prices.map((priceLine, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-1"></div>
                  <div className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>
                      {moment(priceLine.dateStart).format(
                        TEXTS[lang].utils.pricesDateFormat
                      )}
                    </div>
                    <div style={{flexGrow: 1}}>
                      {moment(priceLine.dateEnd).format(
                        TEXTS[lang].utils.pricesDateFormat
                      )}
                    </div>
                  </div>
                  <div className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>{4} {TEXTS[lang].withDriver.hours}</div>
                    <div style={{flexGrow: 1}}>{TEXTS[lang].withDriver.oneDay}</div>
                  </div>
                  <div className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>{priceLine.price}€</div>
                    <div style={{flexGrow: 1}}>{priceLine.price2}€</div>
                  </div>
                  {/* <div className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>{priceLine.half.l8}€</div>
                    <div style={{flexGrow: 1}}>{priceLine.full.l8}€</div>
                  </div> */}
                  <div className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>{priceLine.price3}€</div>
                    <div style={{flexGrow: 1}}>{priceLine.price4}€</div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="mt-3">
          <div>
            <b>
              <span className="numberOreSL"></span> {halfDuration} {TEXTS[lang].withDriver.hours}:
            </b>
          </div>
          <div>
            <span style={{ fontSize: "15px" }}>
              &nbsp;&nbsp;9:00h - 13:00h &nbsp;/&nbsp; 14:00h - 18:00h
            </span>
          </div>
          <div>
            <b>{TEXTS[lang].withDriver.oneDay}:</b>
          </div>
          <div>
            <span style={{ fontSize: "15px" }}>&nbsp;&nbsp;9:00h - 18:00h</span>
          </div>
        </div>

        <div className="mt-3">
          <div>
            <h3>
            {TEXTS[lang].withDriver.routeWarning}
            </h3>
          </div>
        </div>

        <MapAndPictures/>

        <div className="row mt-md-5 pt-md-5">
          <div className="col-12">
            <h5 className="pt-3 pb-md-5 mb-3">
              {TEXTS[lang].withDriver.routeCosts}
            </h5>
          </div>
        </div>

        {/* Condiciones de reservas aquí */}

        {/* contract download */}

        <Meteo />

        <Tripadvisor />
      </div>
    </div>
  );
};

export default BoatsWithDriver;
