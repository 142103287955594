import { useEffect, useState } from "react";

import image_1 from "../imgs/zar43.jpg";
import image_2 from "../imgs/ZAR47.jpg";
import image_2_2 from "../imgs/bsc crema.JPG";
import image_3 from "../imgs/620.jpg";
import image_4 from "../imgs/zar_61.jpg";
import PrenotaWhatsappVisa from "../components/PrenotaWhatsappVisa";
import moment from "moment";
import TEXTS from "../Lang/Languages";
import { useParams } from "react-router";
import Tripadvisor from "../components/Tripadvisor";
import Meteo from "../components/Meteo";
import Video from "../components/Video";
import axios from "axios";

const halfDuration = "4";

const BoatsWithoutDriver = () => {
  const { lang } = useParams();
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    loadPrices();
  }, []);

  const loadPrices = () => {
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      let pri = resp.data.data.filter(a => a.service === 'SL');
      setPrices(pri);
    });
  };

  // useEffect(() => {
  //   setPrices([
  //     {
  //       dateStart: "05/01/2022",
  //       dateEnd: "06/17/2022",
  //       halfDuration: "4",
  //       half: { l4: 120, l5: 130, l6: 140, l8: 160 },
  //       full: { l4: 150, l5: 160, l6: 170, l8: 250 },
  //     },
  //     {
  //       dateStart: "06/18/2022",
  //       dateEnd: "06/30/2022",
  //       halfDuration: "4",
  //       half: { l4: 120, l5: 130, l6: 140, l8: 170 },
  //       full: { l4: 160, l5: 170, l6: 190, l8: 270 },
  //     },
  //     {
  //       dateStart: "07/01/2022",
  //       dateEnd: "07/15/2022",
  //       halfDuration: "4",
  //       half: { l4: 120, l5: 130, l6: 140, l8: 180 },
  //       full: { l4: 170, l5: 180, l6: 200, l8: 300 },
  //     },
  //     {
  //       dateStart: "07/16/2022",
  //       dateEnd: "07/31/2022",
  //       halfDuration: "4",
  //       half: { l4: 130, l5: 140, l6: 150, l8: 200 },
  //       full: { l4: 180, l5: 200, l6: 220, l8: 350 },
  //     },
  //     {
  //       dateStart: "08/01/2022",
  //       dateEnd: "08/12/2022",
  //       halfDuration: "4",
  //       half: { l4: 140, l5: 160, l6: 180, l8: 220 },
  //       full: { l4: 260, l5: 280, l6: 300, l8: 360 },
  //     },
  //     {
  //       dateStart: "08/13/2022",
  //       dateEnd: "08/26/2022",
  //       halfDuration: "4",
  //       half: { l4: 160, l5: 180, l6: 200, l8: 250 },
  //       full: { l4: 280, l5: 300, l6: 320, l8: 400 },
  //     },
  //     {
  //       dateStart: "08/27/2022",
  //       dateEnd: "09/02/2022",
  //       halfDuration: "4",
  //       half: { l4: 140, l5: 150, l6: 160, l8: 220 },
  //       full: { l4: 250, l5: 270, l6: 290, l8: 360 },
  //     },
  //     {
  //       dateStart: "09/03/2022",
  //       dateEnd: "09/16/2022",
  //       halfDuration: "4",
  //       half: { l4: 130, l5: 140, l6: 150, l8: 200 },
  //       full: { l4: 200, l5: 230, l6: 250, l8: 350 },
  //     },
  //     {
  //       dateStart: "09/17/2022",
  //       dateEnd: "10/15/2022",
  //       halfDuration: "4",
  //       half: { l4: 120, l5: 130, l6: 140, l8: 180 },
  //       full: { l4: 150, l5: 160, l6: 170, l8: 300 },
  //     },
  //   ]);
  // }, []);

  return (
    <div>
      <div style={{ height: "50px" }}></div>
      <div className="container text-center">
        <img src={image_1} alt="barco uno" />
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          4 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 4,5m - 40 {TEXTS[lang].woDriver.hp} -{" "}
          {TEXTS[lang].woDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          4 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 4,5m <br /> 40 {TEXTS[lang].woDriver.hp}{" "}
          -{TEXTS[lang].woDriver.noLicence}
        </h3>

        <div className="row">
          <div className="col-6">
            <img id="Zar47" src={image_2} alt="barco uno" />
          </div>
          <div className="col-6">
            <img id="Bsc50" src={image_2_2} alt="barco uno" />
          </div>
        </div>
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          5 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 5m - 40 {TEXTS[lang].woDriver.hp} -{" "}
          {TEXTS[lang].woDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          5 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 5m <br /> 40 {TEXTS[lang].woDriver.hp} -{" "}
          {TEXTS[lang].woDriver.noLicence}
        </h3>

        <div style={{ position: "relative" }}>
          <img src={image_3} alt="barco uno" />
        </div>
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          6 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 6,2m - 40 {TEXTS[lang].woDriver.hp} -{" "}
          {TEXTS[lang].woDriver.noLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          6 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 6,2m <br /> 40 {TEXTS[lang].woDriver.hp}{" "}
          -{TEXTS[lang].woDriver.noLicence}
        </h3>

        {/* <div style={{ position: "relative" }}>
          <img src={image_4} alt="barco uno" />
        </div>
        <h3
          className="d-none d-md-block"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          8 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 6m - 150 {TEXTS[lang].woDriver.hp} -{" "}
          {TEXTS[lang].woDriver.wLicence}
        </h3>
        <h3
          className="d-md-none"
          style={{ marginTop: "10px", marginBottom: "25px" }}
        >
          8 &nbsp;<i className="fas fa-male"></i> -{" "}
          {TEXTS[lang].woDriver.length} 6m <br /> 150 {TEXTS[lang].woDriver.hp}{" "}
          -{TEXTS[lang].woDriver.wLicence}
        </h3> */}

        <Video />

        <div className="row mt-4">
          <div className="col-12">
            <p className="text-danger h3">
              {TEXTS[lang].woDriver.gasolinePrices}
            </p>
          </div>
        </div>

        <PrenotaWhatsappVisa />

        <div
          className="m-auto"
          style={{ maxWidth: 750, paddingTop: 40}}
        >
          <div className="row">
            <div className="col-5 py-2"></div>
            <div
              className="col-6 border border-gray py-2"
              style={{ backgroundColor: "#00ff0020" }}
            >
              {TEXTS[lang].woDriver.noLicence}
            </div>
            {/* <div
              className="col-2 border border-gray py-2"
              style={{ backgroundColor: "#00559920" }}
            >
              {TEXTS[lang].woDriver.wLicence}
            </div> */}
          </div>

          <div className="row">
            <div className="col-5  py-2"></div>
            <div
              className="col-2 border border-gray py-2"
              style={{ backgroundColor: "#00ff0020" }}
            >
              4&nbsp;<i className="fas fa-male"></i>
            </div>
            <div
              className="col-2 border border-gray py-2"
              style={{ backgroundColor: "#00ff0020" }}
            >
              5&nbsp;<i className="fas fa-male"></i>
            </div>
            <div
              className="col-2 border border-gray py-2"
              style={{ backgroundColor: "#00ff0020" }}
            >
              6&nbsp;<i className="fas fa-male"></i>
            </div>
            {/* <div
              className="col-2 border border-gray py-2"
              style={{ backgroundColor: "#00559920" }}
            >
              8&nbsp;<i className="fas fa-male"></i>
            </div> */}
          </div>

          <div className="row">
            <div className="col-1"/>
            <div className="col-2 border border-gray py-2 px-0">
              {TEXTS[lang].woDriver.date}
            </div>
            <div className="col-2 border border-gray py-2 px-0">
              <div className="d-md-none">
                <i className="fas fa-clock"></i>
              </div>
              <div className="d-none d-md-block">
                {TEXTS[lang].withDriver.duration}
              </div>
            </div>
            <div
              className="col-2 border border-gray py-2 px-0"
              style={{ backgroundColor: "#00ff0020" }}
            >
              4,5m
            </div>
            <div
              className="col-2 border border-gray py-2 px-0"
              style={{ backgroundColor: "#00ff0020" }}
            >
              5m
            </div>
            <div
              className="col-2 border border-gray py-2 px-0"
              style={{ backgroundColor: "#00ff0020" }}
            >
              6,2m
            </div>
            {/* <div
              className="col-2 border border-gray py-2 px-0"
              style={{ backgroundColor: "#00559920" }}
            >
              6m
            </div> */}
          </div>

          {prices.length === 0 ? (
            <i className="fas fa-spinner fa-spin" />
          ) : (
            prices.map((priceLine, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-1"/>
                  <div
                    className="col-2 border border-gray py-2 d-flex"
                    style={{ flexDirection: "column" }}
                  >
                    {/* <div style={{ flexGrow: 1 }}>
                      {moment(priceLine.dateStart).format(
                        TEXTS[lang].utils.pricesDateFormat
                      )}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {moment(priceLine.dateEnd).format(
                        TEXTS[lang].utils.pricesDateFormat
                      )}
                    </div> */}
                    <div style={{ flexGrow: 1 }}>
                      {priceLine.dateStart}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {priceLine.dateEnd}
                    </div>
                  </div>
                  <div
                    className="col-2 border border-gray py-2 px-0 d-flex"
                    style={{ flexDirection: "column" }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      {4} {TEXTS[lang].woDriver.hours}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {TEXTS[lang].woDriver.oneDay}
                    </div>
                  </div>
                  <div
                    className="col-2 border border-gray py-2 px-0 d-flex"
                    style={{
                      flexDirection: "column",
                      backgroundColor: "#00ff0020",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{priceLine.price}€</div>
                    <div style={{ flexGrow: 1 }}>{priceLine.price2}€</div>
                  </div>
                  <div
                    className="col-2 border border-gray py-2 px-0 d-flex"
                    style={{
                      flexDirection: "column",
                      backgroundColor: "#00ff0020",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{priceLine.price3}€</div>
                    <div style={{ flexGrow: 1 }}>{priceLine.price4}€</div>
                  </div>
                  <div
                    className="col-2 border border-gray py-2 px-0 d-flex"
                    style={{
                      flexDirection: "column",
                      backgroundColor: "#00ff0020",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{priceLine.price5}€</div>
                    <div style={{ flexGrow: 1 }}>{priceLine.price6}€</div>
                  </div>
                  {/* <div
                    className="col-2 border border-gray py-2 px-0 d-flex" style={{flexDirection: 'column', backgroundColor: "#00559920" }}
                  >
                    <div style={{flexGrow: 1}}>{priceLine.half.l8}€</div>
                    <div style={{flexGrow: 1}}>{priceLine.full.l8}€</div>
                  </div> */}
                </div>
              );
            })
          )}
        </div>

        <div className="mt-3">
          <div>
            <b>
              <span className="numberOreSL"></span> {halfDuration}{" "}
              {TEXTS[lang].woDriver.hours}:
            </b>
          </div>
          <div>
            <span style={{ fontSize: "15px" }}>
              &nbsp;&nbsp;9:00h - 13:00h &nbsp;/&nbsp; 14:00h - 18:00h
            </span>
          </div>
          <div>
            <b>{TEXTS[lang].woDriver.oneDay}:</b>
          </div>
          <div>
            <span style={{ fontSize: "15px" }}>&nbsp;&nbsp;9:00h - 18:00h</span>
          </div>
        </div>

        <div className="mt-3">
          <div>
            <h3>
              <div>
                <b>{TEXTS[lang].woDriver.deposit}:</b>
              </div>
              <div>{TEXTS[lang].woDriver.depositMsg}</div>
              <div>{TEXTS[lang].woDriver.depositMsg2}</div>
            </h3>
          </div>
        </div>

        <div className="mt-3">
          <div>
            <h3>
              <b>{TEXTS[lang].woDriver.identity}</b>
            </h3>
          </div>
        </div>

        <div className="row mt-md-5 pt-md-5">
          <div className="col-12">
            <h5 className="pt-3 pb-md-5 mb-3">
              {TEXTS[lang].woDriver.equipmentTitle}
            </h5>
            <ul className="text-left">
              <li className="py-2">{TEXTS[lang].woDriver.equip1}</li>
              <li className="py-2">{TEXTS[lang].woDriver.equip2}</li>
              <li className="py-2">{TEXTS[lang].woDriver.equip3}</li>
              <li className="py-2">{TEXTS[lang].woDriver.equip4}</li>
              <li className="pt-2">{TEXTS[lang].woDriver.equip5}</li>
            </ul>
          </div>
        </div>

        {/* Condiciones de reservas aquí */}

        {/* contract download */}

        <Meteo />

        <Tripadvisor />
      </div>
    </div>
  );
};

export default BoatsWithoutDriver;
