import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isPhone from "validator/lib/isMobilePhone";
import isNumeric from "validator/lib/isNumeric";
import paymentMethods from "../../imgs/pagamentos_small.png";
import { DatePicker } from "@mantine/dates";
import update from "immutability-helper";
import $ from "jquery";
import { Modal } from "reactstrap";
import { v1 as uuidv1 } from "uuid";
import axios from "axios";
import dayjs from "dayjs";
import image_1 from "../../imgs/620-small.jpg";

import TimerMachine from "react-timer-machine";
import momentDurationFormatSetup from "moment-duration-format";

import moment from "moment";
import APPDATA from "../../AppData";

import CheckoutBtn from "../checkout/CheckoutBtn";

import es from "moment/locale/es";
import en from "moment/locale/en-gb";
import it from "moment/locale/it";
import ru from "moment/locale/ru";
import fr from "moment/locale/fr";
import de from "moment/locale/de";

import LANG from "../../Lang/BookingLanguage";
import LANG2 from "../../Lang/Languages";

const LangWrapper = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  return (
    <DriverBoat
      Lang={LANG[lang]}
      Lang2={LANG2[lang]}
      goBack={() => navigate(-1)}
    />
  );
};

class DriverBoat extends Component {
  constructor(...args) {
    super(...args);

    this.maxPassengers = 11;

    this.minDate = dayjs(new Date()).add(1, "days").toDate();

    momentDurationFormatSetup(moment);

    this.state = {
      bookingDate: this.minDate,
      bookingDateSelected: false,
      ref: uuidv1(),
      serviceBookingsData: null,
      preBookingDone: false,
      pay: false,
      name: "",
      surname: "",
      birthday: null,
      birthlocation: "",
      resident: false,
      residence: "",
      stayingin: "",
      email: "",
      phone: "",
      price: 0,
      prices: [],
      passengers: [],
      morePassengers: true,
      duration: "FD",
      formValid: false,
      formValidIncomplete: false,
      services: [],
      something: 0,
      timerStarted: false,
      bookingError: "",
    };

    this.dataChange = this.dataChange.bind(this);
    this.validateData = this.validateData.bind(this);
    this.addPassenger = this.addPassenger.bind(this);
    this.removePassenger = this.removePassenger.bind(this);
    this.prepareBooking = this.prepareBooking.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.validateStateData = this.validateStateData.bind(this);
    this.confirmBooking = this.confirmBooking.bind(this);
    this.handleErrorOnPayment = this.handleErrorOnPayment.bind(this);
    this.handleWindowClose = this.handleWindowClose.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        timerStarted: true,
      });
      this.loadData();
    }, 1000);
  }

  loadData() {
    console.log('loading Data');
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      let pri = resp.data.data.filter(a => a.service === 'CC');
      this.setState({
        prices: pri
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowClose);
  }

  cancelBooking() {
    window.scrollTo(0, 0);
    if (
      this.state.preBookingDone === true &&
      this.props.MainReducer.bookingConfirmed === false
    ) {
      $.ajax({
        url: APPDATA.apiPath + "deleteBooking.php",
        context: "json",
        async: false,
        data: {
          appid: "root",
          ref: this.state.ref,
        },
      });
    }
    this.props.goBack();
    // this.props.cancelBooking();
  }

  componentDidUpdate(prevProps, prevState){
    debugger;
    if (
      this.state.bookingDate !== prevState.bookingDate ||
      this.state.duration !== prevState.duration ||
      this.state.passengers !== prevState.passengers ||
      this.state.prices !== prevState.prices
    ){
      let price = -1;
      for (var i = 0; i < this.state.prices.length; i++){
        let a = this.state.prices[i]
        let year = moment().format('YYYY');
        let dateSData = a.dateStart.split('/');
        let dateS = moment(`${year}-${dateSData[1]}-${dateSData[0]}`);
        let dateEData = a.dateEnd.split('/');
        let dateE = moment(`${year}-${dateEData[1]}-${dateEData[0]}`);
        if (moment(this.state.bookingDate).isBetween(dateS,dateE)){
          debugger;
          let sumPeople = this.state.passengers.length;
          if (sumPeople > 5){
            if (this.state.duration === 'HD'){
              price = parseInt(a.price3);
            }else{
              price = parseInt(a.price4);
            }
          }else{
            if (this.state.duration === 'HD'){
              price = parseInt(a.price);
            }else{
              price = parseInt(a.price2);
            }
          }
        }
      }
      if (price > -1){
        this.setState({price});
      }else{
        this.setState({price: 0})
      }
    }
  }

  handleWindowClose(e) {
    this.cancelBooking();
  }

  componentWillMount() {
    window.addEventListener("beforeunload", this.handleWindowClose);
    if (LANG.code === "es") {
      moment().locale("es", es);
    } else if (LANG.code === "it") {
      moment().locale("it", it);
    } else if (LANG.code === "fr") {
      moment().locale("fr", fr);
    } else if (LANG.code === "de") {
      moment().locale("de", de);
    } else if (LANG.code === "en") {
      moment().locale("en", en);
    } else {
      moment().locale("ru", ru);
    }

    var price = 0;
    var serviceBookingsData = {};
    var validServices = [];
    // for (var i = 0; i < this.props.MainReducer.services.length ; i++){
    //   var item =  this.props.MainReducer.services[i];
    //   if(this.props.MainReducer.bookingService === "CC"){
    //     if (item.code === "CC"){
    //       validServices.unshift(item);
    //     }
    //   }
    // }

    //Pedir precio del servicio

    // $.ajax({
    //   url: APPDATA.apiPath + "getServicePrice.php",
    //   context: 'json',
    //   async: false,
    //   data: {
    //     appid: 'root',
    //     passAmount: this.state.passengers.length +1,
    //     service: validServices[0].id,
    //     cc: true,

    //   }
    // }).done(function(res) {
    //   res = JSON.parse(res);
    //   if (res.valid === true){
    //     price = parseInt(res.data);
    //   }
    // });

    $.ajax({
      url: APPDATA.apiPath + "getCcBookingsPerDay.php",
      context: "json",
      async: false,
      data: {
        appid: "root",
        date: moment(this.state.bookingDate).format("YYYY/MM/DD"),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (res.valid === true) {
        serviceBookingsData = {
          mornings: res.mornings,
          afternoons: res.afternoons,
          fulldaysInfo: res.fulldaysInfo,
          freeData: res.freeData,
        };
      }
    });

    if (parseInt(serviceBookingsData.mornings) > 0) {
      for (var i = 0; i < validServices.length; i++) {
        var item = validServices[i];
        if (item.morning === "1") {
          validServices.splice(i, 1);
        }
      }
    }
    if (parseInt(serviceBookingsData.afternoons) > 0) {
      for (var i = 0; i < validServices.length; i++) {
        var item = validServices[i];
        if (item.morning === "0") {
          validServices.splice(i, 1);
        }
      }
    }

    this.setState({
      services: validServices,
      price,
      serviceBookingsData,
    });
    // this.props.setBookingOptions(validServices[0].id);
  }

  dateChangeHandler(date) {
    this.setState({
      birthday: moment(date).toDate(),
    });
  }

  dataChange(e) {
    if (e.target.value === "") {
      e.target.style = "border: solid red 2px;";
    } else {
      e.target.style = "border: transparent red 2px;";
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  validateStateData() {
    var validName = this.state.name !== "" ? true : false;
    var validSurname = this.state.surname !== "" ? true : false;
    var validIdNum = this.state.idNum !== "" ? true : false;
    var validBirthday = this.state.birthday !== null ? true : false;
    var validBirthlocation = this.state.birthlocation !== "" ? true : false;
    var validResidence = this.state.residence !== "" ? true : false;
    var validStayingin = this.state.stayingin !== "" ? true : false;
    var validPhone = isPhone(this.state.phone);
    var validEmail = isEmail(this.state.email);
    let validPassengers = this.state.passengers.length > 1;

    if (
      validName &&
      validEmail &&
      validPhone &&
      validBirthday &&
      validBirthlocation &&
      validResidence &&
      validStayingin &&
      validIdNum &&
      validSurname &&
      validPassengers
    ) {
      this.setState({
        formValid: true,
        formValidIncomplete: false,
      });
    } else {
      this.setState({
        formValid: false,
        formValidIncomplete: true,
      });
    }
  }

  validateData(e) {
    var validName = this.state.name !== "" ? true : false;
    var validSurname = this.state.surname !== "" ? true : false;
    var validIdNum = this.state.idNum !== "" ? true : false;
    var validBirthday = this.state.birthday !== null ? true : false;
    var validBirthlocation = this.state.birthlocation !== "" ? true : false;
    var validResidence = this.state.residence !== "" ? true : false;
    var validStayingin = this.state.stayingin !== "" ? true : false;
    var validPhone = isPhone(this.state.phone);
    var validEmail = isEmail(this.state.email);

    if (e.target.name === "name" && validName === false) {
      this.nameInput.style = "border: solid 2px red;";
      this.nameInput.placeholder =
        LANG.booking.form.name + " (" + LANG.booking.form.required + ")";
      this.nameInput.focus();
    }

    if (e.target.name === "surname" && validSurname === false) {
      this.surnameInput.style = "border: solid 2px red;";
      this.surnameInput.placeholder =
        LANG.booking.form.surname + " (" + LANG.booking.form.required + ")";
      this.surnameInput.focus();
    }

    if (e.target.name === "idNum" && validIdNum === false) {
      this.idNumInput.style = "border: solid 2px red;";
      this.idNumInput.placeholder =
        LANG.booking.form.idNum + " (" + LANG.booking.form.required + ")";
      this.idNumInput.focus();
    }

    if (e.target.name === "birthday" && validBirthday === false) {
      this.birthdayInput.style = "border: solid 2px red;";
      this.birthdayInput.placeholder =
        LANG.booking.form.dateFormat + " (" + LANG.booking.form.required + ")";
      this.birthdayInput.focus();
    }

    if (e.target.name === "birthlocation" && validBirthlocation === false) {
      this.birthlocationInput.style = "border: solid 2px red;";
      this.birthlocationInput.placeholder =
        LANG.booking.form.birthLocation +
        " (" +
        LANG.booking.form.required +
        ")";
      this.birthlocationInput.focus();
    }

    if (e.target.name === "residence" && validResidence === false) {
      this.residenceInput.style = "border: solid 2px red;";
      this.residenceInput.placeholder =
        LANG.booking.form.residence + " (" + LANG.booking.form.required + ")";
      this.residenceInput.focus();
    }

    if (e.target.name === "stayingin" && validStayingin === false) {
      this.stayinginInput.style = "border: solid 2px red;";
      this.stayinginInput.placeholder =
        LANG.booking.form.stayingin + " (" + LANG.booking.form.required + ")";
      this.stayinginInput.focus();
    }

    if (e.target.name === "phone" && validPhone === false) {
      this.phoneInput.style = "border: solid 2px red;";
      this.phoneInput.placeholder =
        LANG.booking.form.phone + " (" + LANG.booking.form.required + ")";
      this.phoneInput.focus();
    }

    if (e.target.name === "email" && validEmail === false) {
      this.emailInput.style = "border: solid 2px red;";
      this.emailInput.placeholder =
        LANG.booking.form.email + " (" + LANG.booking.form.required + ")";
      this.emailInput.focus();
    }

    this.setState({
      formValid: false,
    });
  }

  addPassenger() {
    debugger;
    if (this.state.morePassengers === true) {
      if (this.passengerInput.value != "") {
        var newPassengers = [...this.state.passengers]
        newPassengers.push(this.passengerInput.value);
        this.passengerInput.value = "";
        this.passengerInput.focus();
        if (newPassengers.length > 10) {
          this.passengerInput.placeholder = this.props.Lang.booking.form.full;
          this.setState({
            passengers: newPassengers,
            morePassengers: false
          });
        } else {
          this.setState({
            passengers: newPassengers
          });
        }
      }
    }
  }

  getPrice(passAmount) {
    var price = 0;
    //Pedir precio del servicio

    $.ajax({
      url: APPDATA.apiPath + "getServicePrice.php",
      context: "json",
      async: false,
      data: {
        appid: "root",
        passAmount: passAmount,
        service: this.props.MainReducer.bookingOptions,
        cc: true,
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (res.valid === true) {
        price = parseInt(res.data);
      }
    });

    return price;
  }

  removePassenger(index) {
    var newPassengers = [...this.state.passengers];
    newPassengers.splice(index, 1);
    this.passengerInput.placeholder = this.props.Lang.booking.form.passengerFullName;
    this.setState({
      passengers: newPassengers,
      morePassengers: true
    });
  }

  setDuraion(e) {
    // this.props.setBookingOptions(e.target[e.target.selectedIndex].id);

    this.setState({
      duration: e.target.value,
      formValid: false,
    });
  }

  confirmBooking() {
    window.removeEventListener("beforeunload", this.handleWindowClose);

    $.ajax({
      url: APPDATA.apiPath + "editBookingsRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        props: "state,payment",
        values: "1,1",
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't edit the booking record.");
      }
    });

    const data = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      phone: this.state.phone,
      idNum: this.state.idNum,
      birthday: this.state.birthday,
      birthlocation: this.state.birthlocation,
      residence: this.state.residence,
      stayingin: this.state.stayingin,
      price: this.state.price,
    };

    var passengers = "";

    for (var i = 0; i < this.state.passengers.length; i++) {
      var item = this.state.passengers[i];
      if (i === this.state.passengers.length - 1) {
        passengers = passengers + item;
      } else {
        passengers = passengers + item + ",";
      }
    }

    $.ajax({
      url: APPDATA.apiPath + "createCcBookingRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        name: this.state.name,
        surname: this.state.surname,
        birthday: moment(this.state.birthday).format("YYYY/MM/DD"),
        birthlocation: this.state.birthlocation,
        resident: 0,
        residence: this.state.residence,
        phone: this.state.phone,
        email: this.state.email,
        idNumber: this.state.idNum,
        stayingin: this.state.stayingin,
        passengersDetails: passengers,
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't Create the CC booking record.");
      }
    });

    var passAmount = this.state.passengers.length;

    $.ajax({
      url: APPDATA.mailerPath + this.props.Lang.booking.emailSender,
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        to: this.state.email,
        name: this.state.name,
        pageName: "sciroccorentaboat.com",
        color: "35336c",
        type: "booking",
        service: this.props.Lang.servicesList.CC,
        serviceId: "CC",
        amount: passAmount,
        date: moment(this.props.MainReducer.bookingDate).format(this.props.Lang.dateCode),
        passengers,
        ref: this.state.ref.substring(0, 8),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't send the email.");
      }
    });

    $.ajax({
      url: APPDATA.mailerPath + "notification.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        from: this.state.email,
        name: this.state.name,
        pageName: "sciroccorentaboat.com",
        color: "35336c",
        type: "booking",
        service: this.props.Lang.servicesList.CC,
        serviceId: "CC",
        amount: passAmount,
        date: moment(this.props.MainReducer.bookingDate).format("DD/MM/YYYY"),
        passengers,
        ref: this.state.ref.substring(0, 8),
      },
    });

    this.props.confirmBooking(data);
    window.scrollTo(0, 0);
  }

  prepareBooking() {
    var valid = true;
    var bookingOptions = this.props.MainReducer.bookingOptions;
    var amount = 1;

    $.ajax({
      url: APPDATA.apiPath + "getCcBookingsPerDay.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        date: moment(this.props.MainReducer.bookingDate).format("YYYY/MM/DD"),
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (bookingOptions === "5") {
        if (res.freeData.morning < amount) {
          valid = false;
        }
      }
      if (bookingOptions === "15") {
        if (res.freeData.afternoon < amount) {
          valid = false;
        }
      }
    });

    if (valid === false) {
      this.unavailableBookingCancel();
      return false;
    }

    $.ajax({
      url: APPDATA.apiPath + "createBookingRecord.php",
      context: "json",
      method: "GET",
      async: false,
      data: {
        appid: "root",
        ref: this.state.ref,
        date: moment(this.props.MainReducer.bookingDate).format("YYYY/MM/DD"),
        doneDate: moment().format("YYYY/MM/DD"),
        service: this.props.MainReducer.bookingOptions,
        amount: 1,
        payment: 0,
        billDetails: JSON.stringify({
          servicePrice: this.state.price,
          totalPrice: this.state.price,
        }),
        state: 2,
      },
    }).done(function (res) {
      res = JSON.parse(res);
      if (!res.valid) {
        alert("Error: Coudn't make the booking record.");
        valid = false;
      }
    });
    if (valid === false) {
      this.unavailableBookingCancel();
      return false;
    } else {
      this.setState({
        preBookingDone: true,
      });
      return true;
    }
  }

  unavailableBookingCancel() {
    this.setState({
      bookingError: this.props.Lang.booking.form.bookingErrorMsg,
    });
  }

  handleErrorOnPayment() {
    if (
      this.state.preBookingDone === true &&
      this.props.MainReducer.bookingConfirmed === false
    ) {
      $.ajax({
        url: APPDATA.apiPath + "deleteBooking.php",
        context: "json",
        async: false,
        data: {
          appid: "root",
          ref: this.state.ref,
        },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.bookingError !== ""}>
          <div className="p-3">
            <p className="border rounded border-danger text-danger">
              {this.state.bookingError}
            </p>
            <div>
              <div
                className="btn btn-primary"
                onClick={() => {
                  this.cancelBooking();
                }}
              >
                <span>{this.props.Lang.btns.goBack}</span>
              </div>
            </div>
          </div>
        </Modal>
        <div className="topSpacer"></div>
        <div className="container text-center pb-5">
          <div className="row my-3">
            <div className="col-12 p-3 text-center">
              <h3>{this.props.Lang.servicesList.CC}</h3>
            </div>
            <div className="col-12 p-3 text-right">
              <div
                className="btn btn-primary"
                onClick={() => {
                  this.cancelBooking();
                }}
              >
                {/* <i className="fas fa-times"></i> */}
                {this.props.Lang.btns.cancel}
              </div>
            </div>
            <div className="col-12 text-center mb-3">
              <span className="countdownText">
                {this.props.Lang.booking.time}{" "}
              </span>
              <br />
              <span className="text-danger countdownTimer px-3">
                <TimerMachine
                  timeStart={600 * 1000} // start at 0 seconds
                  timeEnd={0} // end at 300 seconds
                  started={this.state.timerStarted}
                  paused={false}
                  countdown={true} // use as stopwatch
                  interval={1000} // tick every 1 second
                  formatTimer={(time, ms) =>
                    moment.duration(ms, "milliseconds").format("h:mm:ss")
                  }
                  onComplete={(time) => this.cancelBooking()}
                />
              </span>
            </div>
            <div className="col-12 col-md-6 mx-auto h-100 p-3">
              <div>
                <img id="escursioniImg" src={image_1}></img>
                <div className="text-left pt-2 pt-md-4">
                  <p className="text-danger h3">
                    {this.props.Lang.booked.disclaimer.important}
                  </p>
                  <p>{this.props.Lang.booked.disclaimer.early}</p>
                  <p>
                    <b>{this.props.Lang.booked.disclaimer.boat.cancelTitle}</b>
                  </p>
                  <div>
                    <ul>
                      {/* <li>{this.props.Lang.booked.disclaimer.boat.cancelFree}</li> */}
                      <li>
                        {this.props.Lang.booked.disclaimer.boat.cancelPenalty}
                      </li>
                      <li>
                        {this.props.Lang.booked.disclaimer.boat.cancelNoRefound}
                      </li>
                      <li>
                        {
                          this.props.Lang.booked.disclaimer.boat
                            .cancelBadWeather
                        }
                      </li>
                    </ul>
                    <p>{this.props.Lang.booked.disclaimer.earlyCC1}</p>
                    <p>{this.props.Lang.booked.disclaimer.earlyCC2}</p>
                    <p>{this.props.Lang.booked.disclaimer.gas}</p>
                  </div>
                </div>
              </div>
            </div>
            {!this.state.bookingDateSelected ? (
              <div className="col-12 col-md-6 mx-auto p-3">
                <DatePicker
                  minDate={this.minDate}
                  maxDate={dayjs(new Date()).month(9).date(15).toDate()}
                  value={this.state.bookingDate}
                  label={this.props.Lang.datePicker.bookDay}
                  placeholder={this.props.Lang.dateFormat}
                  onChange={(date) => {
                    if (date){
                      this.setState({bookingDate: date});
                      this.setState({bookingDateSelected: true});
                    }else{
                      this.setState({bookingDateSelected: false})
                    }
                  }}
                ></DatePicker>
              </div>
            ) : (
              <div className="col-12 col-md-6 mx-auto p-3">
                <div className="text-left">
                  <p>
                    {this.props.Lang.booked.date}:{" "}
                    <b>
                      {moment(this.state.bookingDate).format(
                        this.props.Lang.dateCode
                      )}
                    </b>
                  </p>
                  <label htmlFor="duration">
                    {this.props.Lang.booking.form.duration}
                  </label>
                  <select
                    id="duration"
                    className="form-control"
                    onChange={(e) => this.setDuraion(e)}
                  >
                    {/* {this.state.services.map((el,index)=> (
                      <option key={index} value={el.duration} id={el.id}>
                        {isNumeric(el.duration) ? parseInt(el.morning) === 1 ? el.duration + "h" + "‌‌‌‌‌‌‌‌‌‌ ‌‌ ‌‌ ‌‌ 9:00h - 14:00h" : el.duration + "h" + "‌‌ ‌‌ ‌‌ ‌‌ 14:00h - 18:00h" : el.duration === "HD" ? parseInt(el.morning) === 1 ?  this.props.Lang.booking.form.halfDay +"‌‌ ‌‌ ‌‌ ‌‌ 9:00h - 13:00h" : this.props.Lang.booking.form.halfDay +"‌‌ ‌‌ ‌‌ ‌‌ 14:00h - 18:00h" : this.props.Lang.booking.form.fullDay +"‌‌ ‌‌ ‌‌ ‌‌ 9:00h - 18:00h"}
                      </option>
                    ))} */}
                    <option value={"FD"}>
                      {this.props.Lang2.woDriver.oneDay}
                    </option>
                    <option value={"HD"}>
                      4 {this.props.Lang2.woDriver.hours}
                    </option>
                  </select>
                  <label className="mt-3">
                    {this.props.Lang.booking.personalDetails}
                  </label>
                  <input
                    ref={(input) => (this.nameInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.name}
                  ></input>
                  <input
                    ref={(input) => (this.surnameInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="surname"
                    value={this.state.surname}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.surname}
                  ></input>
                  <input
                    ref={(input) => (this.idNumInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="idNum"
                    value={this.state.idNum}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.idNum}
                  ></input>
                  <br />
                  <label className="mt-3">
                    {this.props.Lang.booking.form.birthday}
                  </label>
                  <br />
                  <DatePicker
                    value={
                      this.state.birthday || moment().add(-18, "year").toDate()
                    }
                    maxDate={moment().add(-18, "year").toDate()}
                    onChange={(date) => {
                      if (!date){
                        this.dateChangeHandler(moment().add(-18, "year").toDate())
                      }else{
                        this.dateChangeHandler(date)
                      }
                    }}
                  ></DatePicker>
                  <input
                    ref={(input) => (this.birthlocationInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="birthlocation"
                    value={this.state.birthlocation}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.birthLocation}
                  ></input>
                  <br />
                  <input
                    ref={(input) => (this.residenceInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="residence"
                    value={this.state.residence}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.residence}
                  ></input>
                  <input
                    ref={(input) => (this.stayinginInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="stayingin"
                    value={this.state.stayingin}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.stayingin}
                  ></input>
                  <br />
                  <input
                    ref={(input) => (this.emailInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.email}
                  ></input>
                  <input
                    ref={(input) => (this.phoneInput = input)}
                    className="form-control my-2"
                    type="text"
                    name="phone"
                    value={this.state.phone}
                    onChange={(e) => this.dataChange(e)}
                    onBlur={(e) => this.validateData(e)}
                    placeholder={this.props.Lang.booking.form.phone}
                  ></input>

                  <div className="">
                    <label className="mt-3">
                      {this.props.Lang.booking.form.passengers}: (Max:{" "}
                      {this.maxPassengers},{" "}
                      {this.props.Lang.booking.form.available}:{" "}
                      {this.maxPassengers - this.state.passengers.length})
                    </label>
                    <p className="mt-3">
                      {this.props.Lang.booking.form.passengerFullName}
                    </p>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        disabled={!this.state.morePassengers}
                        ref={(input) => (this.passengerInput = input)}
                        className="form-control"
                        ariaescribedby="button-addon2"
                      ></input>
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          id="button-addon2"
                          onClick={this.addPassenger}
                          disabled={!this.state.morePassengers}
                        >
                          {this.props.Lang.btns.add}
                        </button>
                      </div>
                    </div>
                    <div>
                      {this.state.passengers.map((el, index) => {
                        return (
                          <p
                            key={index}
                            className="rounded border border-dark p-2 my-1"
                          >
                            <span
                              className="text-danger px-2"
                              onClick={() => this.removePassenger(index)}
                            >
                              <i className="fas fa-times"></i>
                            </span>{" "}
                            &nbsp;{index + 1}: &nbsp;{el}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="my-3">
                    <label>
                      {this.props.Lang.booking.form.price}:{" "}
                      {this.state.price != null
                        ? this.state.price + " €"
                        : this.props.Lang.booking.form.calculating + "..."}
                    </label>
                  </div>
                  <div className="row text-center my-3">
                    <div className="col-12">
                      {this.state.formValidIncomplete === true ? (
                        <p className="border border-danger text-danger rounded">
                          {this.props.Lang.booking.form.makeSure}
                        </p>
                      ) : null}
                      {this.state.formValid === true ? (
                        <p className="border border-success text-success rounded">
                          {this.props.Lang.booking.form.formValid}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <img
                        style={{ width: "150px" }}
                        className="mb-3"
                        src={paymentMethods}
                        alt={this.props.Lang.booking.form.paymentMethods}
                      ></img>
                    </div>
                    <div className="col-12 col-md-6">
                      {this.state.formValid === false ? (
                        <div
                          className={`btn btn-primary`}
                          onClick={this.validateStateData}
                        >
                          {this.props.Lang.booking.form.confirmDetails}
                        </div>
                      ) : (
                        // <div
                        //   className={`btn btn-primary ${
                        //     this.state.formValid === true ? "" : "disabled"
                        //   }`}
                        //   onClick={() => {
                        //     if (this.state.formValid === true) {
                        //       this.setState({
                        //         pay: true,
                        //       });
                        //     }
                        //   }}
                        // >
                        //   {this.props.Lang.booking.form.payAndBook}
                        // </div>
                        <CheckoutBtn data={this.state} service={'CC'}/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LangWrapper;
