import { NavLink, useParams, Link } from "react-router-dom";
import TEXTS from "../Lang/Languages";

const Footer = () => {
  let { lang } = useParams();
  return (
    <div className="mainFooter">
      <div className="container">
        <div className="my-5 row">
          <div className="col-12 footerMenu col-md-4">
            <p className="title">{TEXTS[lang].footer.menu}</p>
            <ul>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/`}>
                  {TEXTS[lang].nav.home}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/boats`}>
                  {TEXTS[lang].nav.boats}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/boats_driver`}>
                  {TEXTS[lang].nav.boatsDriver}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/group_excursions`}>
                  {TEXTS[lang].nav.excursions}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/route_galery`}>
                  {TEXTS[lang].nav.route}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`${lang}/contact`}>
                  {TEXTS[lang].nav.contact}
                </NavLink>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`${lang}/booking`}>
                  <span className="nav-link-special">
                    {TEXTS[lang].nav.booking}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4">
            <p className="title">{TEXTS[lang].footer.schedule}</p>
            <p>{TEXTS[lang].footer.months}</p>
            <p>{TEXTS[lang].footer.hours}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="title">{TEXTS[lang].footer.info}</p>
            <p>
              {" "}
              <i className="fas fa-map-marked-alt fa-2x"></i> &nbsp;&nbsp;
              {TEXTS[lang].footer.location}
            </p>
            <p>
              {" "}
              <i className="fas fa-phone fa-2x"></i>&nbsp;
              <span className="text-success">
                <i className="fab fa-whatsapp-square fa-2x"></i>
              </span>{" "}
              &nbsp;&nbsp;
              <a href="tel:+393457582776">+39 345 758 2776</a>
            </p>
            <p>
              {" "}
              <i className="fas fa-envelope fa-2x"></i> &nbsp;&nbsp;{" "}
              <a href="mailto:davidepisati@gmail.com">davidepisati@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <div className="subFooter text-light">
        <div className="container">
          <div className="mt-5 row">
            <div className="col-12 col-md-6">
              <p className="">{TEXTS[lang].footer.copyright}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
