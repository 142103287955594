import { useParams } from "react-router";
import TEXTS from "../Lang/Languages";

const Meteo = () => {
  const { lang} = useParams();
  return (
    <div className="mb-5">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          href="https://www.ilmeteo.it/meteo/San%20Teodoro&c=6429"
          className="btn btn-primary btn-lg"
          target="_blank"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i className="fas fa-sun fa-2x"></i>
          &nbsp; {TEXTS[lang].home.weather}
        </a>
      </div>
    </div>
  );
};

export default Meteo;
