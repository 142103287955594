import { Navigate, NavLink, useNavigate} from "react-router-dom";
import {useState} from 'react';
import "../../css/adminNav.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const logout = () => {
    localStorage.setItem('sciroccoToken', null);
    navigate('/');
  }

  const closeNav = () => {
    setIsOpen(false);
  }

  return (
    <div id="adminNavBlock">
      <nav role="navigation">
        <div id="clickNav" className={`${isOpen ? 'open' : ''}`}>
          <input type="button" onClick={() => setIsOpen(!isOpen)}/>

          <span></span>
          <span></span>
          <span></span>

          <ul className="nav-data" role="list" style={{display: 'flex', flexDirection: 'column'}}>
            <li className="itm">
              <NavLink onClick={closeNav} to="/admin/dashboard">Home</NavLink>
            </li>
            <li className="itm">
              <NavLink onClick={closeNav} to="/admin/prices">Prices</NavLink>
            </li>
            <li style={{flexGrow: 1}}></li>
            <li className="itm">
              <NavLink to="/" onClick={logout}>Log out</NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className={`backdrop ${isOpen ? 'open' : ''}`} onClick={closeNav}></div>
    </div>
  );
};

export default Navbar;
