import { useParams } from "react-router";
import TEXTS from "../Lang/Languages";
import visa from '../imgs/pagamentos_small.png';
import { Link } from "react-router-dom";

const PrenotaWhatsappVisa = () => {
  let { lang } = useParams();
  return (
    <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
      <div className="col-5 col-md-3" style={{flexGrow: 1}}>
        <Link to={`/${lang}/booking`} className="btn btn-primary btn-lg">{TEXTS[lang].nav.booking}</Link>
      </div>
      <div className="col-3 col-md-2" style={{flexGrow: 1}}>
        <a className="btn btn-success btn-lg" href="https://msng.link/wa/+39 345 758 2776">
          <i className="fab fa-2x fa-whatsapp"></i>
        </a>
      </div>
      <div className="col-4 col-md-2" style={{flexGrow: 1}}>
        <img src={visa} style={{maxWidth: 150}} />
      </div>
    </div>
  );
};

export default PrenotaWhatsappVisa;
