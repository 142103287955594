import TEXTS from "../Lang/Languages";
import { useParams } from "react-router";
import Slider from "react-slick";

import gallery_1 from "../imgs/galery/galery_1.jpg";
import gallery_2 from "../imgs/galery/galery_2.jpg";
import gallery_3 from "../imgs/galery/galery_3.jpg";
import gallery_4 from "../imgs/galery/galery_4.jpg";
import gallery_5 from "../imgs/galery/galery_5.jpg";
import gallery_6 from "../imgs/galery/galery_6.jpg";
import gallery_7 from "../imgs/galery/galery_7.jpg";
import gallery_8 from "../imgs/galery/galery_8.jpg";
import gallery_9 from "../imgs/galery/galery_9.jpg";
import gallery_10 from "../imgs/galery/galery_10.jpg";
import gallery_11 from "../imgs/galery/galery_11.jpg";
import gallery_12 from "../imgs/galery/galery_12.jpg";
import gallery_13 from "../imgs/galery/galery_13.jpg";
import MapAndPictures from "../components/MapAndPictures";

import Video from "../components/Video";

const RouteAndGallery = () => {
  let { lang } = useParams();

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let Images = [
    gallery_1,
    gallery_2,
    gallery_3,
    gallery_4,
    gallery_5,
    gallery_6,
    gallery_7,
    gallery_8,
    gallery_9,
    gallery_10,
    gallery_11,
    gallery_12,
    gallery_13,
  ];

  return (
    <>
      <div class="container text-center mt-5 pt-5">
        <div class="row mt-md-4">
          <div class="col-12 text-center p-md-3">
            <h3>{TEXTS[lang].nav.route}</h3>
            <p>{TEXTS[lang].route.intro}</p>
          </div>
          <div className="col-12">
            <p>
              <b>{TEXTS[lang].route.options}</b>
            </p>
          </div>
          <div className="col-12 col-md-6 m-auto">
            <ul className="text-left">
              <li>{TEXTS[lang].route.bullet1}</li>
              <li>{TEXTS[lang].route.bullet2}</li>
              <li>{TEXTS[lang].route.bullet3}</li>
            </ul>
          </div>
        </div>

        <MapAndPictures />

        <Video />
        {/* <div className="row mt-4">
        <div className="col-12 col-md-10 col-lg-8 mx-auto my-4">
          <img className="" src={routeMap} alt="location map"></img>
        </div>
      </div> */}
      </div>
      <div className="row mt-4">
        <div className="col-12 mx-auto my-4">
          <Slider {...settings}>
            {Images.map((image) => {
              return (
                <div className="caruselSlide">
                  <img src={image} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RouteAndGallery;
