import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, Input, Button } from "@mantine/core";
import { DatePicker, DateRangePicker } from "@mantine/dates";

const AdminPrices = () => {
  const [prices, setPrices] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [price, setPrice] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [price3, setPrice3] = useState(0);
  const [price4, setPrice4] = useState(0);
  const [price5, setPrice5] = useState(0);
  const [price6, setPrice6] = useState(0);
  const [priceId, setPriceId] = useState(null);
  const [isNewPrice, setNewPrice] = useState(false);

  const [viewPrices, setViewPrices] = useState(null);

  useEffect(() => {
    loadPrices();
  }, []);

  const loadPrices = () => {
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      setPrices(resp.data.data);
    });
  };

  const removePrice = (itemId) => {
    axios({
      method: "GET",
      url: `https://www.sciroccorentaboat.com/PHPAPI/deletePrice.php?appid=root&id=${itemId}`,
    }).then((resp) => {
      loadPrices();
    });
  };

  const editPrice = (
    id,
    date1,
    date2,
    price,
    price2,
    price3,
    price4,
    price5,
    price6
  ) => {
    axios({
      method: "GET",
      url: `https://www.sciroccorentaboat.com/PHPAPI/editPrice.php?appid=root&id=${id}&dateStart=${date1}&dateEnd=${date2}&price=${price}&price2=${price2}&price3=${price3}&price4=${price4}&price5=${price5}&price6=${price6}`,
    }).then((resp) => {
      console.log(resp);
      loadPrices();
      closeModal();
    });
  };

  const SERVICES = [
    {
      id: "SL",
      name: "No Licence",
    },
    {
      id: "CC",
      name: "With Driver",
    },
    {
      id: "EX",
      name: "Excursions",
    },
  ];

  const createPriceRecord = (
    date1,
    date2,
    service,
    price,
    price2,
    price3,
    price4,
    price5,
    price6
  ) => {
    axios({
      method: "GET",
      url: `https://www.sciroccorentaboat.com/PHPAPI/createPrice.php?appid=root&dateStart=${date1}&dateEnd=${date2}&service=${service.id}&price=${price}&price2=${price2}&price3=${price3}&price4=${price4}&price5=${price5}&price6=${price6}`,
    }).then((resp) => {
      console.log(resp);
      loadPrices();
      closeModal();
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setDate1("");
    setDate2("");
    setPrice(0);
    setPriceId(null);
    setNewPrice(false)
  };

  const openModal = (id, date1, date2, pr, pr2, pr3, pr4, pr5, pr6) => {
    if (id && pr) {
      setDate1(date1);
      setDate2(date2);
      setPrice(pr);
      setPrice2(pr2);
      setPrice3(pr3);
      setPrice4(pr4);
      setPrice5(pr5);
      setPrice6(pr6);
      setPriceId(id);
    } else {
      setDate1("");
      setDate2("");
      setPrice(0);
      setPrice2(0);
      setPrice3(0);
      setPrice4(0);
      setPrice5(0);
      setPrice6(0);
      setPriceId(null);
    }
    setModalOpen(true);
  };

  const drawModal = () => {
    return (
      <Modal opened={modalOpen} onClose={closeModal} title={`${isNewPrice ? 'New price' : 'Edit price'}`}>
        {viewPrices.id === "SL" || viewPrices.id === "CC" ?
        <>
        <div>
          <label>
            Date start
            <Input
              value={date1}
              onChange={(e) => setDate1(e.target.value)}
            ></Input>
          </label>
        </div>
        <div>
          <label>
            Date end
            <Input
              value={date2}
              onChange={(e) => setDate2(e.target.value)}
            ></Input>
          </label>
        </div>
        </> : null}

        {viewPrices.id === "SL" ? (
          <div>
            <div className="py-2">
              <label>
                4 <i className="fas fa-user"></i>
              </label>
              <div>
                <label>
                  Price 1/2 Day
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                  ></Input>
                </label>
                &nbsp;
                <label>
                  Price 1 Day
                  <Input
                    value={price2}
                    onChange={(e) => setPrice2(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
            <div className="py-2">
              <label>
                5 <i className="fas fa-user"></i>
              </label>
              <div>
                <label>
                  Price 1/2 Day
                  <Input
                    value={price3}
                    onChange={(e) => setPrice3(e.target.value)}
                    type="number"
                  ></Input>
                </label>
                &nbsp;
                <label>
                  Price 1 Day
                  <Input
                    value={price4}
                    onChange={(e) => setPrice4(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
            <div className="py-2">
              <label>
                6 <i className="fas fa-user"></i>
              </label>
              <div>
                <label>
                  Price 1/2 Day
                  <Input
                    value={price5}
                    onChange={(e) => setPrice5(e.target.value)}
                    type="number"
                  ></Input>
                </label>
                &nbsp;
                <label>
                  Price 1 Day
                  <Input
                    value={price6}
                    onChange={(e) => setPrice6(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
          </div>
        ) : null}
        {viewPrices.id === "CC" ? (
          <div>
            <div className="py-2">
              <label>
                5 <i className="fas fa-user"></i>
              </label>
              <div>
                <label>
                  Price 1/2 Day
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                  ></Input>
                </label>
                &nbsp;
                <label>
                  Price 1 Day
                  <Input
                    value={price2}
                    onChange={(e) => setPrice2(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
            <div className="py-2">
              <label>
                12 <i className="fas fa-user"></i>
              </label>
              <div>
                <label>
                  Price 1/2 Day
                  <Input
                    value={price3}
                    onChange={(e) => setPrice3(e.target.value)}
                    type="number"
                  ></Input>
                </label>
                &nbsp;
                <label>
                  Price 1 Day
                  <Input
                    value={price4}
                    onChange={(e) => setPrice4(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
          </div>
        ) : null}
        {viewPrices.id === "EX" ? (
          <div>
            <div className="py-2">
              <div>
                <label>
                  {priceId == 1 ? 'Adult' : 'Child'}
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                  ></Input>
                </label>
              </div>
            </div>
          </div>
        ) : null}
        <div className="text-right">
          <Button
            onClick={() => {
              if (isNewPrice) {
                createPriceRecord(
                  date1,
                  date2,
                  viewPrices,
                  price,
                  price2,
                  price3,
                  price4,
                  price5,
                  price6
                );
              }else{
                editPrice(
                  priceId,
                  date1,
                  date2,
                  price,
                  price2,
                  price3,
                  price4,
                  price5,
                  price6
                )
              }
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    );
  };

  if (viewPrices) {
    return (
      <div
        style={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <div className="bg-info text-center">
          <h3 className="bg-info p-3 text-center">{viewPrices.name}</h3>
        </div>

        {drawModal()}

        <div className="my-3 mx-2">
          <div
            className="border border-dark btn"
            onClick={() => setViewPrices(null)}
          >
            Back
          </div>
        </div>

        {viewPrices.id === "SL" || viewPrices.id === "CC"
          ? prices
              .filter((a) => a.service === viewPrices.id)
              .map((priceItem) => {
                return (
                  <div
                    className="d-flex py-3 px-1 border border-dark btn mx-2"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="px-1 btn btn-light p-0"
                      onClick={() =>
                        openModal(
                          priceItem.id,
                          priceItem.dateStart,
                          priceItem.dateEnd,
                          priceItem.price,
                          priceItem.price2,
                          priceItem.price3,
                          priceItem.price4,
                          priceItem.price5,
                          priceItem.price6
                        )
                      }
                    >
                      {priceItem.dateStart} <br /> {priceItem.dateEnd}
                    </div>
                    <div className="px-1">
                      4<i className="fas fa-user"></i>
                      <br />
                      {priceItem.price} - {priceItem.price2}
                    </div>
                    <div className="px-1">
                      5<i className="fas fa-user"></i>
                      <br />
                      {priceItem.price3} - {priceItem.price4}
                    </div>
                    <div className="px-1">
                      6<i className="fas fa-user"></i>
                      <br />
                      {priceItem.price5} - {priceItem.price6}
                    </div>
                    <div
                      className="removeBtn btn btn-danger py-0 px-2 mr-1"
                      onClick={() => removePrice(priceItem.id)}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                    {/* <div className="px-3">{priceItem.service}</div> */}
                  </div>
                );
              })
          : null}
        {viewPrices.id === "SL" || viewPrices.id === "CC" ? (
          <div
            className="d-flex p-3 mx-2 my-3 mb-5 border border-dark btn"
            style={{ alignItems: "center", justifyContent: "center" }}
            onClick={() => {
              setNewPrice(true);
              openModal();
            }}
          >
            <i className="fas fa-plus"></i>
          </div>
        ) : null}
        {viewPrices.id === "EX"
          ? prices
              .filter((a) => a.service === viewPrices.id)
              .map((priceItem) => {
                return (
                  <div
                    className="d-flex py-3 px-1 border border-dark btn mx-2"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="px-1 btn btn-light p-0"
                      onClick={() =>
                        openModal(
                          priceItem.id,
                          priceItem.dateStart,
                          priceItem.dateEnd,
                          priceItem.price,
                          priceItem.price2,
                          priceItem.price3,
                          priceItem.price4,
                          priceItem.price5,
                          priceItem.price6
                        )
                      }
                    >
                      {priceItem.id == 1 ? 'Adult' : 'Child'} 
                    </div>
                    <div className="px-1">
                      {priceItem.price}
                    </div>
                  </div>
                );
              })
          : null}
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 500,
      }}
    >
      <h3 className="bg-info p-3 text-center">Prices</h3>

      {SERVICES.map((el) => {
        return (
          <div
            className="p-3 mb-5 border border-dark btn btn-light d-block"
            onClick={() => setViewPrices(el)}
          >
            <b>{el.name}</b>
          </div>
        );
      })}
    </div>
  );
};

export default AdminPrices;
