import { useParams } from "react-router";
import MapAndPictures from "../components/MapAndPictures";
import PrenotaWhatsappVisa from "../components/PrenotaWhatsappVisa";
import image_1 from "../imgs/zar 61 1.jpg";
import image_2 from "../imgs/escursioni-1.jpg";
import TEXTS from "../Lang/Languages";
import Meteo from "../components/Meteo";
import Tripadvisor from "../components/Tripadvisor";
import Contract from "../components/Contract";
import Video from "../components/Video";
import { useEffect, useState } from "react";
import axios from "axios";


const GroupExcursions = () => {
  const { lang } = useParams();
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    loadPrices();
  }, []);

  const loadPrices = () => {
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/selectPrices.php?appid=root",
    }).then((resp) => {
      let pri = resp.data.data.filter(a => a.service === 'EX');
      setPrices(pri);
    });
  };

  return (
    <div className="GroupExcursions mt-5 pt-5">
      <div className="container">
        <div className="row">
          {/* <div className="col-12 col-md-6">
            <img className="mb-3" src={image_1} />
          </div> */}
          <div className="col-12">
            <img className="mb-3" src={image_2} />
          </div>
        </div>

        <div className="text-center">
          <h4>{TEXTS[lang].groups.route}</h4>
        </div>

        <Video />

        <div className="text-center mt-5">
          <h3 className="text-primary">{TEXTS[lang].groups.prices}</h3>

          <div className="pb-3">
              <h5><b>{prices.length > 0 ? prices.filter(a => a.id == 1)[0].price : '...'}€</b> {TEXTS[lang].groups.adults} - <b>{prices.length > 0 ? prices.filter(a => a.id == 2)[0].price : '...'}€</b> {TEXTS[lang].groups.children}</h5>
          </div>
          <div className="my-3">
              <h5>4 {TEXTS[lang].groups.hours}:</h5>
              <h5>9:00 - 13:00 {TEXTS[lang].groups.or} 14:00 - 18:00</h5>
          </div>
        </div>

        <div className="my-5">
          <PrenotaWhatsappVisa/>
        </div>

        <div className="my-3 text-center">
          <h3>{TEXTS[lang].groups.stopsTitle}</h3>
          <h5>{TEXTS[lang].groups.stops}</h5>
        </div>
        <div className="my-5 text-center">
          <MapAndPictures />
        </div>


        <div className="my-3 text-center">
          <h4 className="">{TEXTS[lang].groups.routeChangesDisclaimer}</h4>
        </div>

        <Meteo/>

        <Tripadvisor/>

        {/* Condiciones reservas aqui */}
        
        {/* Descargar contratos aquí */}
        <Contract text={`${TEXTS[lang].groups.contract}`} link={`${TEXTS[lang].groups.contractUrl}`} />
        
      </div>
    </div>
  );
};

export default GroupExcursions;
