import Slider from "react-slick";
import TripLogo from "../imgs/tripadvisor.png";
import TEXTS from "../Lang/Languages";
import { useParams } from "react-router-dom";

const Tripadvisor = () => {
  let { lang } = useParams();
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let Comments = TEXTS[lang].tripadvisor;

  return (
    <div className="tripadvisorDiv my-5 py-5 text-center">
      <a
        href="https://www.tripadvisor.it/Attraction_Review-g652016-d6000910-Reviews-Scirocco-San_Teodoro_Province_of_Olbia_Tempio_Sardinia.html"
        target="_blank"
      >
        <img
          src={TripLogo}
          className="tripadvisorLogo"
          alt="tripadvisor logo"
        />
      </a>
      <div className="my-5" style={{ color: "#58b595" }}>
        <i className="far fa-comment fa-3x"></i>
      </div>
      <Slider {...settings}>
      {Comments.map((comment, i) => {
          return (
            <div key={i} className="caruselSlide">
              <div
                className="tripadvisorComents"
                style={{ maxWidth: "80%", margin: "auto" }}
              >
                <a
                  href="https://www.tripadvisor.it/Attraction_Review-g652016-d6000910-Reviews-Scirocco-San_Teodoro_Province_of_Olbia_Tempio_Sardinia.html"
                  target="_blank"
                  style={{ color: "black" }}
                >
                  <div className="tripadvisorComent">
                    <p className="testimonialComment">
                      <i className="fas fa-quote-left"></i>
                      {comment.comment}
                      <i className="fas fa-quote-right"></i>
                    </p>
                    <p className="testimonialAuthor text-center">
                      {comment.user}
                    </p>
                    <p className="testimonialSite text-center">TripAdvisor</p>
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Tripadvisor;
