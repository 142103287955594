import { useState } from "react";
import { useParams, Link, Routes, Route, useNavigate } from "react-router-dom";
import image_1 from "../imgs/zar43-small.jpg";
import image_2 from "../imgs/620-small.jpg";
import image_3 from "../imgs/escursioni-1.jpg";

import NoLisenceBoat from "../components/Forms/NoLisenceBoatDetails";
import DriverBoat from "../components/Forms/DriverBoatDetails";

import TEXTS from "../Lang/Languages";
import BOOKING_TEXTS from "../Lang/BookingLanguage";

const Menu = () => {
  const { lang } = useParams();

  return (
    <div className="row">
      <div className="col-12 col-lg-4 mb-3">
        <Link to="boats">
          <div className="card p-2 btn">
            <img
              src={image_1}
              alt={TEXTS[lang].nav.boats}
              style={{ maxHeight: 250, width: "auto" }}
            ></img>
            <div className="card-title mt-2">{TEXTS[lang].nav.boats}</div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-lg-4 mb-3">
        <Link to="driverBoats">
          <div className="card p-2 btn">
            <img
              src={image_2}
              alt={TEXTS[lang].nav.boatsDriver}
              style={{ maxHeight: 250, width: "auto" }}
            ></img>
            <div className="card-title mt-2">{TEXTS[lang].nav.boatsDriver}</div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-lg-4 mb-3">
        <Link to="excursions">
          <div className="card p-2 btn">
            <img
              src={image_3}
              alt={TEXTS[lang].nav.excursions}
              style={{ maxHeight: 250, width: "auto" }}
            ></img>
            <div className="card-title mt-2">{TEXTS[lang].nav.excursions}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const Pending = () => {
  return <div>
    <a href="mailto:davidepisati@gmail.com"><button className="btn btn-primary">davidepisati@gmail.com</button></a>
  </div>
}

const Excursions = () => {
  const { lang } = useParams();
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="container" style={{ position: "relative" }}>
        <div className="row" style={{ position: "absolute", right: 20, top: 0 }}>
          <div className="col-12 p-3 pt-0 text-right">
            <div
              className="btn btn-primary"
              onClick={() => {
                navigate(-1)
              }}
            >
              {/* <i className="fas fa-times"></i> */}
              <span>{BOOKING_TEXTS[lang].btns.cancel}</span>
            </div>
          </div>
        </div>
        {/* <h3>{TEXTS[lang].nav.booking}</h3> */}
        <h1>{TEXTS[lang].nav.excursions}</h1>
        <div className="row">
          <div className="col-12">
            <img className="mb-3" src={image_3} />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <a
              className="btn btn-success btn-lg"
              href="https://msng.link/wa/+39 345 758 2776"
            >
              <div
                className="d-flex"
                style={{ justifyContent: "cetner", alignItems: "center" }}
              >
                <i className="fab fa-2x fa-whatsapp"></i>&nbsp;
                <span>Whatsapp</span>
              </div>
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <a
              className="btn btn-lg btn-primary"
              href="mailto:davidepisati@gmail.com"
            >
              <i className="fas fa-at"></i>
              {TEXTS[lang].contact.sendEmail}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Booking = () => {
  return (
    <div className="mt-5 text-center py-5 container">
      <Routes>
        <Route index element={<Pending />} />
        {/* <Route index element={<Menu />} />
        <Route path="/boats" element={<NoLisenceBoat />} />
        <Route path="/driverBoats" element={<DriverBoat />} />
        <Route path="/excursions" element={<Excursions />} /> */}
      </Routes>
    </div>
  );
};

export default Booking;
