import "./App.css";
import "@stripe/stripe-js";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BookingNavbar from "./components/BookingNavbar";
import RouteAndGallery from "./pages/RouteAndGallery";
import GroupExcursions from "./pages/GroupExcursions";
import BoatsWithDriver from "./pages/BoatsWithDriver";
import BoatsWithoutDriver from "./pages/BoatsWithoutDriver";
import AdminPage from "./pages/AdminPage";
import Booking from "./pages/Booking";
import CheckoutBtn from "./components/checkout/CheckoutBtn";
import LANG from "./Lang/Languages";
import LANG2 from "./Lang/BookingLanguage";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import axios from "axios";
import APPDATA from "./AppData";
import moment from "moment";

const CheckoutSuccess = (props) => {
  let { ref, lang } = useParams();
  let [sent, setSent] = useState(false);

  useEffect(() => {

    let bookingData = {};

    if (!sent){
      setSent(true)
      axios({
        method: "GET",
        url: "https://www.sciroccorentaboat.com/PHPAPI/editBookings2Record.php",
        params: {
          appid:"root",
          ref: ref,
          payment: 2
        }
      }).then(resp => {
        axios({
          method: "GET",
          url: "https://www.sciroccorentaboat.com/PHPAPI/getBooking2Info.php",
          params: {
            appid:"root",
            ref: ref,
          }
        }).then(bookingResp => {
          if (bookingResp.data.valid){
            let data = bookingResp.data.data[0];
            bookingData = data;
            console.log({data});
            let jsonData = JSON.parse(data.data);
            console.log({jsonData});
            console.log(jsonData);
            // axios({
            //   method: "GET",
            //   url: APPDATA.mailerPath + LANG2[lang].booking.emailSender,
            //   params: {
            //     appid: 'root',
            //     to: data.email,
            //     name: jsonData.name,
            //     service: LANG2[lang].servicesList[data.service],
            //     serviceid: data.service,
            //     date: moment(jsonData.bookingDate).format('DD/MM/YYYY'),
            //     ref: ref.substring(0, 8),
            //     time: jsonData.duration + " " + jsonData.hoursSelected,
            //     ensurance: jsonData.ensurance,
            //     type: "booking"
            //   }
            // }).then(emailResp => {
            //   console.log({emailResp})
            //   let res = emailResp.data;
            //   if (!res.valid){
            //     alert("Error: Coudn't send the email.");
            //   }
            // })
            axios({
              method: "GET",
              url: APPDATA.mailerPath + "sciroccoNoti.php",
              params: {
                appid: 'root',
                to: data.email,
                name: jsonData.name,
                service: LANG2[lang].servicesList[jsonData.service],
                serviceId: data.service,
                date: moment(jsonData.bookingDate).format('DD/MM/YYYY'),
                ref: ref.substring(0, 8),
                time: jsonData.duration,
                hours: jsonData.duration + " " + jsonData.hoursSelected,
                ensurance: jsonData.ensurance,
                type: "booking",
                adults: jsonData.adults,
                children: jsonData.children,
              }
            }).then(emailResp => {
              console.log({emailResp})
              let res = emailResp.data;
              if (!res.valid){
                alert("Error: Coudn't send the email.");
              }
            })
          }
        })
      })
    }

  }, [sent]);

  return (
    <div className="mt-5 row">
      <div className="col-12" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <h1>{LANG2[lang].booked.title}</h1>
        <p>{LANG2[lang].booked.emailMsg}</p>
        <div className="h1 text-success">
          <i className="fas fa-check"></i>
        </div>
        <Link className="btn btn-primary" to={"/"}>{LANG2[lang].btns.goBack}</Link>
      </div>
    </div>
  );
};

const CheckoutCancel = (props) => {
  let { ref, lang } = useParams();

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/editBookings2Record.php",
      params: {
        appid: "root",
        ref: ref,
        payment: -1,
      },
    });
  }, []);

  return (
    <div className="mt-5 row">
      <div className="col-12" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <h1>{LANG2[lang].stripe.error.title}</h1>
        <h2>{LANG2[lang].stripe.error.header}</h2>
        <p>{LANG2[lang].stripe.error.msg}</p>
        <p>{LANG2[lang].stripe.error.msg2}</p>
        <div className="h1 text-danger">
          <i className="fas fa-times"></i>
        </div>
        <Link className="btn btn-primary" to={`/${lang}/booking`}>{LANG2[lang].btns.goBack}</Link>
      </div>
    </div>
  );
};

function App() {
  let { lang } = useParams();
  let { pathname } = useLocation();
  if (!lang) {
    window.location = "/en/";
  }
  if (
    pathname.substring(4).indexOf("booking") !== -1 ||
    pathname.substring(4).indexOf("checkout") !== -1
  ) {
    return (
      <div>
        <BookingNavbar />
        <Outlet />
        {/* <Footer /> */}
      </div>
    );
  }
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

function LangSelector() {
  let params = useParams();

  const LANGS = ["es", "en", "de", "it", "fr", "ru"];

  if (LANGS.indexOf(params.lang) === -1) {
    window.location = "/en/";
    return null;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}

function Content() {
  let params = useParams();
  return (
    <div>
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="route_gallery" element={<RouteAndGallery />} />
        <Route path="group_excursions" element={<GroupExcursions />} />
        <Route path="boats_driver" element={<BoatsWithDriver />} />
        <Route path="boats" element={<BoatsWithoutDriver />} />
        <Route path="booking" element={<Booking />} />
        <Route path="booking/*" element={<Booking />} />
        <Route path="checkout" element={<CheckoutBtn />} />
        <Route path="checkout-success/:ref" element={<CheckoutSuccess />} />
        <Route path="checkout-cancel/:ref" element={<CheckoutCancel />} />
      </Routes>
    </div>
  );
}

function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<AdminPage />}></Route>
        <Route path="/admin/*" element={<AdminPage />}></Route>
        <Route path="/" element={<App />}>
          <Route path=":lang/*" element={<LangSelector />}>
            <Route path="*" element={<Content />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Index;
