const Contract = ({text, link}) => {
  return (
    <div className="my-4 text-center">
      <a className="btn btn-primary" href={`${link}`}>
        {text}
      </a>
    </div>
  );
};

export default Contract;
