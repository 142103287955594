import react from "react";
import Logo from "../imgs/logo_ita.png";
import Logo_it from "../imgs/flags/it.svg";
import Logo_gb from "../imgs/flags/gb.svg";
import Logo_es from "../imgs/flags/es.svg";
import Logo_de from "../imgs/flags/de.svg";
import Logo_fr from "../imgs/flags/fr.svg";
import Logo_ru from "../imgs/flags/ru.svg";
import { useParams, NavLink, Link } from "react-router-dom";

const LANG_IMGS = {
  it: Logo_it,
  en: Logo_gb,
  es: Logo_es,
  de: Logo_de,
  fr: Logo_fr,
  // ru: Logo_ru,
};

const LANG_NAMES = {
  it: "Italiano",
  en: "English",
  es: "Español",
  de: "Deutsch",
  fr: "Français",
  ru: "Pусский",
};

const Navbar = () => {
  let { lang } = useParams();

  const renderLangOptions = () => {
    let langs = Object.keys(LANG_IMGS);
    return langs.map((item) => {
      return (
        <div key={item}>
          <NavLink className="nav-link px-3 py-1" to={`${item}/`}>
            <img style={{maxWidth: 30}} className="border border-gray" src={LANG_IMGS[item]} alt={LANG_NAMES[item]}/> <span>{LANG_NAMES[item]}</span>
          </NavLink>
        </div>
      );
    });
  };

  return (
    <div className="container-fluid p-0 text-center">
      <nav className="navbar navbar-toggleable-md navbar-light bg-faded">
        <button
          className="navbar-toggler d-lg-none navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarMobile"
          aria-controls="navbarMobile"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="navLogo navbar-brand mx-auto" to={`${lang}/`}>
          <img className="" src={Logo} />
        </Link>
        <div className="w-100"></div>

        {/* <!-- Mobile --> */}
        <div
          className="collapse d-lg-none text-center mx-auto w-100"
          id="navbarMobile"
        >
          <ul className="navbar-nav mx-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={LANG_IMGS[lang]} alt="english" /> <span>{LANG_NAMES[lang]}</span>
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {renderLangOptions()}
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <nav className="desktopNavbar d-none d-lg-block navbar navbar-expand-lg navbar-light fixed-header">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={LANG_IMGS[lang]} alt="english" /> <span>{LANG_NAMES[lang]}</span>
              </div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {renderLangOptions()}
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {/* <div style={{height: 300}}></div> */}
    </div>
  );
};

export default Navbar;
