import { useState } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

const CheckoutBtn = (props) => {
  let { lang } = useParams();
  let navigate = useNavigate();
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const item = {
    price: "price_1LR24JLrujxOPaK8MFpeWZyr",
    quantity: 1
  };
  const [bookingReference, setBookingReference] = useState('BookingN1')

  const checkoutOptions = {
    lineItems: [item],
    customerEmail: "tvcologan@gmail.com",
    clientReferenceId: bookingReference,
    mode: "payment",
    successUrl: `${window.location.origin}/${lang}/checkout-success/${props.data.ref}`,
    cancelUrl: `${window.location.origin}/${lang}/checkout-cancel/${props.data.ref}`
  };

  const redirectToCheckout = async () => {

    let data = props.data;
    delete data.prices;

    axios({
      method: "GET",
      url: "https://www.sciroccorentaboat.com/PHPAPI/createBooking2Record.php",
      params: {
        appid:"root",
        ref: props.data.ref,
        data: JSON.stringify(data),
        date: moment(props.data.bookingDate).format('YYYY-MM-DD'),
        email: props.data.email,
        service: props.service || 'NON',
        duration: props.data.duration,
        morning: props.data.morning || 0,
        payment: 1
      }
    }).then(async (resp) => {
      if (resp.data.valid){
        setLoading(true);
        console.log("redirectToCheckout");
    
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout(checkoutOptions);
        console.log("Stripe checkout error", error);
    
        if (error) setStripeError(error.message);
        setLoading(false);
      }else{
        if (window.confirm("Error during booking") == true) {
          navigate("/");
        } else {
          navigate("/");
        }
      }
    })

  };

  if (stripeError) alert(stripeError);

  return (
    <div className="checkout mt-5 py-5 d-flex" style={{alignItems: "center", justifyContent: 'center', flexDirection: "column"}}>
      <button
        className="checkout-button btn btn-primary d-flex"
        style={{flexDirection: 'row'}}
        onClick={redirectToCheckout}
        disabled={isLoading}
      >
        <div className="grey-circle">
          <div className="purple-circle mr-3">
            <i className="fas fa-credit-card"></i>
          </div>
        </div>
        <div className="text-container">
          <p className="text m-0">{isLoading ? "Loading..." : "Proceed to checkout"}</p>
        </div>
      </button>
    </div>
  );
};

export default CheckoutBtn;