import { useParams, Routes, Route, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import AdminPrices from "../components/AdminPanel/AdminPrices";
import AdminDashboard from "../components/AdminPanel/AdminDashboard";

import Navbar from "./AdminPanel/Navbar";

const AdminPanel = () => {
  let { lang } = useParams();
  const [error, setError] = useState("");

  return (
    <div>
      <Navbar />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center"
        }}
      >
        <Routes>
          <Route path="dashboard" element={<AdminDashboard />}></Route>
          <Route path="prices" element={<AdminPrices />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default AdminPanel;
