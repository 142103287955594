import { useParams, useNavigate, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Logo from "../imgs/logo_ita.png";
import sha256 from "crypto-js/sha256";
import AdminPanel from "../components/AdminPanel";
import AdminPrices from "../components/AdminPanel/AdminPrices";
import AdminDashboard from "../components/AdminPanel/AdminDashboard";

const KEY = "543a9cb911f353830c45c0b01716248909aeb7a1519e4d5496a6cc8aa0d3a900";

const AdminPage = () => {
  let { lang } = useParams();
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [isValidToken, setIsValidToken] = useState(false);
  const [localToken, setLocalToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let lT = localStorage.getItem("sciroccoToken");
    setLocalToken(lT);
    document.getElementById("password")?.focus();
  }, []);

  useEffect(() => {
    if (localToken) {
      let half = Math.ceil(localToken.length / 2);
      let token = localToken.substring(0, half);
      if (token === KEY) {
        setIsValidToken(true);
        navigate("dashboard");
      } else {
        setIsValidToken(false);
      }
    }
  }, [localToken]);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      let passHash = sha256(pass).toString();
      if (passHash === KEY) {
        let lT = `${passHash}${passHash}`;
        localStorage.setItem("sciroccoToken", lT);
        setLocalToken(lT);
      } else {
        setError("Contraseña incorrecta.");
      }
    }
  };

  if (isValidToken) {
    return (
      <div style={{backgroundColor: "#e7e7e7", minHeight: '100vh'}}>
        <AdminPanel/>
      </div>
    );
  }

  return (
    <div className="container text-center pt-5">
      <div className="row">
        <div className="col-6 m-auto text-center">
          <img className="" src={Logo} />
          <div className="text-center pt-5">
            <input
              onKeyDown={onEnter}
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
                setError("");
              }}
              id="password"
              type="password"
              placeholder="password"
              className="form-control m-auto"
              style={{ width: "90%" }}
            ></input>
            {error !== "" ? (
              <div className="pt-5">
                <p className="text-danger h5">{error}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
