import routeMap from "../imgs/map_rout.jpg";
import routeMap_1 from "../imgs/Molara_min.png";
import routeMap_2 from "../imgs/Tavolara_min.png";
import routeMap_3 from "../imgs/map_rout_3.jpg";
import routeMap_1_lg from "../imgs/Molara_medium.png";
import routeMap_2_lg from "../imgs/Tavolara_medium.png";
import routeMap_3_lg from "../imgs/map_route_3_medium.png";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { useState } from "react";

const images = [
  routeMap_1_lg,
  routeMap_2_lg,
  routeMap_3_lg
];

const MapAndPictures = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleOpenBox = (num) => {
    setIndex(num);
    setIsOpen(true);
  }

  return (
    <div>
      <div className="row mt-4">
        <div className="col-12 col-md-10 col-lg-8 mx-auto my-4">
          <img className="" src={routeMap} alt="location map"></img>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 p-4">
          <img src={routeMap_1} onClick={() => handleOpenBox(0)} />
        </div>
        <div className="col-12 col-md-4 p-4">
          <img src={routeMap_2} onClick={() => handleOpenBox(1)} />
        </div>
        <div className="col-12 col-md-4 p-4">
          <img src={routeMap_3} onClick={() => handleOpenBox(2)} />
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setIndex((index + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default MapAndPictures;
