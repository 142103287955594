
// Español
const ES = {
  code: "es",
  dateFormat: "DD/MM/AAAA",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "Español",
    index: "Inicio",
    boats: "Precios lanchas sin licencia",
    guidedTrips: "Precios visitas guiadas - Lancha con conductor",
    routs: "Rutas y galería",
    beachServices: "Playa equipada",
    contact: "Contacto"
  },
  footer: {
    menu: "Menú",
    schedule: "Horario",
    dates: "Del 1 de Mayo al 15 de Octubre.",
    hours: "Todos los días de 8:30 a 19:00.",
    info: "Información",
    loc: "Playa Cala Brandinchi - San Teodoro (OT) - Cerdeña",
    copyright: "Copyright 2019 Todos los derechos reservados."
  },
  datePicker: {
    months: {
      5: "Mayo",
      6: "Junio",
      7: "Julio",
      8: "Agosto",
      9: "Septiembre",
      10: "Octubre"
    },
    selectYear: "Seleccione un año",
    selectMonth: "Seleccione un mes",
    bookDay: "Reservar día"
  },
  booking: {
    time: "Por favor, complete la reserva en el margen de tiempo establecido:",
    duration: "Duración",
    whatTime: "A qué hora desea la reserva:",
    quantity: "Seleccione un numero de lanchas",
    multyDrivers: {
      driver: "Conductor",
      saved: "guardado"
    },
    driverDetails: "Datos del conductor",
    personalDetails: "Datos personales",
    form: {
      required: "Obligatorio",
      name: "Nombre",
      surname: "Apellido",
      idNum: "Documento de identidad",
      birthday: "Fecha de nacimiento",
      birthLocation: "Lugar de nacimiento",
      residence: "Residencia",
      stayingin: "Lugar de hospedaje en Cerdeña",
      phone: "Teléfono",
      email: "Email",
      pickPlace: "Elija su posición",
      firstRow: "Primera fila",
      position: "Posición",
      fullDriversDetails: "Datos de conductores completados",
      saved: "Guardado",
      adults: "Adultos",
      kids: "Niños de hasta 12 años",
      ensurance: "Seguro",
      ensuranceQuestion: "Seguro por daños de 5 €",
      ensurancePerBoat: "Seguro por daños de 5 € por lancha",
      calculating: "Calculando",
      makeSure: "Asegúrese de haber introducido todos los datos!",
      makeSureBeach: "Asegúrese de haber introducido todos los datos y haber seleccionado su posición!",
      formValid: "Formulario completo! Proceda al pago.",
      paymentMethods: "formas de pago",
      confirmDetails: "Confirmar datos",
      payAndBook: "Pagar y reservar",
      bookingErrorMsg: "Desgraciadamente, la reserva que quería hacer ya no esta disponible. Vuelva a intentarlo.",
      pilotSaveError: "Asegúrese de introducir todos los datos con el formato correcto.",
      passengerFullName: "Nombre completo de pasajero",
      halfDay: "Medio dia",
      fullDay: "Dia completo",
      taken: "Ocupado",
      free: "Libre",
      selected: "Seleccionado",
      row: "Fila",
      place: "puesto",
      selectedPalce: "Posición seleccionada",
      selectedPlaces: "Posiciones seleccionadas",
      price: "Precio",
      full: "Completo",
      passengers: "Pasajeros",
      available: "disponibles"

    },
    emailSender: "noti2_es.php"
  },
  stripe: {
    error:{
      title: "Se produjo algún error",
      header: "Cobro no realizado",
      msg: "Stripe no ha podido realizar el cobro, asegúrese de haber introducido los datos correctamente. Por motivos de seguridad, Stripe bloquea a las tarjetas que le parezcan potencialmente fraudulentas. Si no es su caso, inéntelo de nuevo.",
      msg2: "No hemos podido realizar el cobro en su tarjeta, asegúrese de haber introducido los datos de forma correcta o vuelva a intentarlo más tarde."
    },
    title: "Pago moderado por Stripe",
    done: "Pago realizado",
    redirecting: "Redirigiendo",
    formTitle: "Introduzca la información de su tarjeta",
    number: "Número",
    expireDate: "Fecha de caducidad",
    CVC: "Código de seguridad CVC",
    amaunt: "Cantidad a abonar:",

  },
  btns:{
    cancel: "Cancelar",
    goBack: "Volver",
    saveDriver: "Guardar conductor",
    no: "No",
    yes: "Si",
    pay: "Pagar",
    add: "Añadir"
  },
  servicesList: {
    title: "Elija un servicio",
    SL: "Lanchas sin licencia",
    CC: "Visitas guiadas - Lancha con conductor",
    SP: "Playa equipada"
  },
  booked:{
    title: "Reserva confirmada.",
    emailMsg: "Te hemos enviado un email",
    emailMsg2: "Revisa la bandeja de entrada de:",
    bookingInfo: "Información de la reserva",
    date: "Fecha",
    service: "Servicio",
    name: "Nombre",
    disclaimer: {
      important: "Importante",
      gas: "Precios sin incluir gasolina.",
      earlySL1: "llegar a la sede de SCIROCCO RENT A BOAT al menos 20 minutos antes de la reserva con un DNI ( documento de identitad o pasaporte o carnet de conducir) y € 400 de DEPOSITO en efectivo o tarjeta de credito.",
      earlySL2: "En agosto puede haber mucha cola a la entrada del aparcamiento de Cala Brandinchi, asi que aconsejamos llegar con mucha antelacion. En caso de aparcamiento agotado ensenar la mail de confirmacion a los encargados a la entrada del aparcamiento: les dejaran entrar.",
      earlyCC1: "llegar a la sede de SCIROCCO RENT A BOAT al menos 20 minutos antes de la reserva con un DNI ( documento de identitad o pasaporte o carnet de conducir).",
      earlyCC2: "En agosto puede haber mucha cola a la entrada del aparcamiento de Cala Brandinchi, asi que aconsejamos llegar con mucha antelacion. En caso de aparcamiento agotado ensenar la mail de confirmacion a los encargados a la entrada del aparcamiento: les dejaran entrar.",
      boat: {
        model: "LA RESERVA NO GARANTIZA UN MODELO ESPECÍFICO SINO UNO DE LA CATEGORÍA.",
        cancelTitle: "CANCELACIONES O MODIFICACIONES:",
        cancelFree: "GRATIS HASTA 3 DIAS ANTES DE LA FECHA RESERVADA",
        cancelPenalty: "10 € DE PENALIZACION HASTA 24 HORAS ANTES DE LA FECHA RESERVADA",
        cancelNoRefound: "NINGUN REMBOLSO MENOS DE 24 HORAS ANTES DE LA FECHA RESERVADA",
        cancelBadWeather: "3% DEL IMPORTE ( comision de la tajeta) EN CASO DE MAL TIEMPO: olas superiores a 1 metro o viento superior a los 20 nudos",
      },
      beach: {
        cancelTitle: "CANCELACIONES O MODIFICACIONES",
        cancelPenalty: "10 € de penalizacion hasta 24 horas antes de la fecha reservada",
        cancelNoRefound: "Ningun rembolso menos de 24 horas antes de la fecha reservada",
        cancelFree: "3% DEL IMPORTE ( comision de la tajeta) EN CASO DE MAL TIEMPO: lluvia o viento superior a los 25 nudos.",
        early: "En agosto puede que haya una larga cola a la entrada del aparcamiento de Cala Brandinchi, además, después de las 10,00 el estacionamiento podria ya estár completo y la entrada no está garantizada, incluso con la reserva de tumbonas. Por lo tanto es recomendable llegar temprano porque, en el caso de aparcamiento completo NO SE REMBOLSARA el coste de las tumbonas."
      }
    },
    ensuranceYes: "en caso de daños causados a la unidad alquilada el cliente es responsable de la indemnizacion de la sola FRANQUICIA de 2000 €.",
    ensuranceNo: "en caso de daños causados a la unidad alquilada el cliente es responsable del importe total del daño."
  }
}

//Ingles
const EN = {
  code: "en",
  dateFormat: "DD/MM/YYYY",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "English",
    index: "Home",
    boats: "Prices boats without a licence",
    guidedTrips: "Prices guided trips - Motorboat with driver",
    routs: "Routes and gallery",
    beachServices: "Equipped beach",
    contact: "Contact"
  },
  footer: {
    menu: "Menu",
    schedule: "Schedule",
    dates: "From May 1 to October 15.",
    hours: "Every day from 8:30 a.m. to 7:00 p.m.",
    info: "Information",
    loc: "Beach Cala Brandinchi - San Teodoro (OT) - Sardinia",
    copyright: "Copyright 2019 All rights reserved."
  },
  datePicker: {
    months: {
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October"
    },
    selectYear: "Select a year",
    selectMonth: "Select a month",
    bookDay: "Book day"
  },
  booking: {
    time: "Please, complete the booking in the given time:",
    duration: "Duration",
    whatTime: "At what time would you like the booking:",
    quantity: "Select the number of boats",
    multyDrivers: {
      driver: "Driver",
      saved: "saved"
    },
    driverDetails: "Driver details",
    personalDetails: "Personal details",
    form: {
      required: "Required",
      name: "Name",
      surname: "Surname",
      idNum: "ID number",
      birthday: "Date of birth",
      birthLocation: "Place of birth",
      residence: "Residence",
      stayingin: "Place of lodging in Sardinia",
      phone: "Phone",
      email: "Email",
      pickPlace: "choose your position",
      firstRow: "First row",
      position: "Position",
      fullDriversDetails: "Driver details completed",
      saved: "Saved",
      adults: "Adults",
      kids: "Kids up to 12 years old",
      ensurance: "Collision Damage Waiver",
      ensuranceQuestion: "Collision Damage Waiver for 5 €",
      ensurancePerBoat: "Collision Damage Waiver for 5€ per boat",
      calculating: "Calculaiting",
      makeSure: "Make sure you inserted all details correctly!",
      makeSureBeach: "Make sure you inserted all details correctly and you choosed your position!",
      formValid: "Form completed! Proceed to payment.",
      paymentMethods: "Payment methods",
      confirmDetails: "Confirm details",
      payAndBook: "Pay and book",
      bookingErrorMsg: "Unfortunately, the booking you where trying to make is no longer available. Try again.",
      pilotSaveError: "Make sure you inserted all detail to the form correctly.",
      passengerFullName: "Full passenger name",
      halfDay: "Half day",
      fullDay: "Full day",
      taken: "Taken",
      free: "Dree",
      selected: "Chosen",
      row: "Row",
      place: "place",
      selectedPalce: "Chosen place",
      selectedPlaces: "Chosen places",
      price: "Price",
      full: "Complete",
      passengers: "Passengers",
      available: "available"

    },
    emailSender: "noti2_en.php"
  },
  stripe: {
    error:{
      title: "An error ocurred.",
      header: "Unrealized charge",
      msg: "Stripe has not been able to make the payment, make sure you have entered the data correctly. For security reasons, Stripe blocks cards that seem potentially fraudulent. If it is not your case, try again.",
      msg2: "We have not been able to charge your card, make sure you entered the information correctly or try again later."
    },
    title: "Payment made with Stripe",
    done: "Payment completed.",
    redirecting: "Redirecting",
    formTitle: "Insert card details",
    number: "Number",
    expireDate: "Expiry date",
    CVC: "Security code (CVC)",
    amaunt: "Amount to pay:",

  },
  btns:{
    cancel: "Cancel",
    goBack: "Back",
    saveDriver: "Save driver",
    no: "No",
    yes: "Yes",
    pay: "Pay",
    add: "Add"
  },
  servicesList: {
    title: "Choose a service",
    SL: "Boats no licence needed",
    CC: "Guided trips - Motorboat with driver",
    SP: "Equipped beach"
  },
  booked:{
    title: "Booking confirmed.",
    emailMsg: "We have sent you an email",
    emailMsg2: "Check the inbox of:",
    bookingInfo: "Booking information",
    date: "Date",
    service: "Service",
    name: "Name",
    disclaimer: {
      important: "Important",
      gas: "Prices gasoline not included.",
      earlySL1: "Arrive at SCIROCCO RENT A BOAT establishment at least 20 MINUTES BEFORE your booking, with an ID (identity card or driving licence or passport) and 400 € DEPOSIT in cash or credit card.",
      earlySL2: "In August there may be a long queue at the entrance of Cala Brandinchi parking, so it is advisable to arrive well in advance. In case of full parking show the confirmation e-mail to the parking attendants: they will let you in.",
      earlyCC1: "Arrive at SCIROCCO RENT A BOAT establishment at least 20 MINUTES BEFORE your booking, with an ID (identity card or driving licence or passport) .",
      earlyCC2: "In August there may be a long queue at the entrance of Cala Brandinchi parking, so it is advisable to arrive well in advance. In case of full parking show the confirmation e-mail to the parking attendants: they will let you in.",
      boat: {
        model: "THE BOOKING DOES NOT GUARANTEE A SPECIFIC MODEL BUT ANY MODEL OF THE CATEGORY.",
        cancelTitle: "CANCELLATIONS OR CHANGES:",
        cancelFree: "TOTAL REFUND UP TO 3 DAYS BEFORE THE BOOKED DATE",
        cancelPenalty: "10 € PENALTY  UP TO 24 HOURS BEFORE THE BOOKED DATE",
        cancelNoRefound: "NO REFUND LESS THAN 24 HOURS BEFORE THE BOOKED DATE ",
        cancelBadWeather: "3% OF THE AMOUNT (credit card fees ) IN CASE OF BAD WEATHER: wave higher than 1 meter or wind stronger than 20 knots",
      },
      beach: {
        cancelTitle: "CANCELLATIONS OR CHANGES:",
        cancelPenalty: "10 € PENALTY  UP TO 24 HOURS BEFORE THE BOOKED DATE",
        cancelNoRefound: "NO REFUND LESS THAN 24 HOURS BEFORE THE BOOKED DATE",
        cancelFree: "3% OF THE AMOUNT (credit card fees ) IN CASE OF BAD WEATHER: wave higher than 1 meter or wind stronger than 25 knots",
        early: "In August there might be a long queue at the entrance of Cala Brandinchi parking, moreover after 10 am the parking is often already full and the entry is not guaranteed, even with the sunbeds reservation. It is therefore advisable to arrive quite early because, in the case of full parking, the cost of the sunbeds will NOT BE REFUNDED."
      }
    },
    ensuranceYes: "in case of damages caused to the rented unit, the client renter is responsible for the compensation of  the excess amount of 2000 €.",
    ensuranceNo: "in case of damages caused to the rented unit, the client renter is responsible for the compensation of the total amount of the damage."
  }
}


//Italiano
const IT = {
  code: "it",
  dateFormat: "GG/MM/AAAA",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "Italiano",
    index: "Home",
    boats: "Prezzi gommoni senza patente",
    guidedTrips: "Prezzi escursioni guidate - gommone con conducente",
    routs: "Itinerario e galleria",
    beachServices: "Spiaggia attrezzata",
    contact: "Contatti"
  },
  footer: {
    menu: "Menu",
    schedule: "Orario",
    dates: "Dal 1 maggio al 15 ottobre.",
    hours: "Tutti i giorni dalle 8:30 alle 19:00.",
    info: "Informazioni",
    loc: "Spiaggia Cala Brandinchi - San Teodoro (OT) - Sardegna",
    copyright: "Copyright 2019 Tutti i diritti riservati."
  },
  datePicker: {
    months: {
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre"
    },
    selectYear: "Seleziona un anno",
    selectMonth: "Seleziona un mese",
    bookDay: "Prenota il giorno"
  },
  booking: {
    time: "Per favore completa la prenotazione entro il tempo stabilito:",
    duration: "Durata",
    whatTime: "A che ora desideri riservare:",
    quantity: "Seleziona un numero di gommoni",
    multyDrivers: {
      driver: "Conducente",
      saved: "salvato"
    },
    driverDetails: "Dati del conducente",
    personalDetails: "Dati personali",
    form: {
      required: "obbligatorio",
      name: "Nome",
      surname: "Cognome",
      idNum: "Documento d'identità",
      birthday: "Data di nascita",
      birthLocation: "Luogo di nascita",
      residence: "Residenza",
      stayingin: "Indirizzo di soggiorno in Sardegna",
      phone: "Telefono",
      email: "Email",
      pickPlace: "Scegli la posizione",
      firstRow: "Prima fila",
      position: "Posizione",
      fullDriversDetails: "Dati del conducente completati",
      saved: "Salvato",
      adults: "Adulti",
      kids: "Bambini fino a 12 anni",
      ensurance: "Assicurazione",
      ensuranceQuestion: "Vuoi acquistare l'assicurazione danni per l'importo di 5 € ?",
      ensurancePerBoat: "Vuoi acquistare l'assicurazione danni per l'importo di 5 € per gommone ?",
      calculating: "Calcolando",
      makeSure: "Assicurati di avere introdotto tutti i dati!",
      makeSureBeach: "Assicurati di avere introdotto tutti i dati e aver selezionato la posizione!",
      formValid: "Formulario completo, procedi al pagamento.",
      paymentMethods: "modo di pagamento",
      confirmDetails: "Conferma i dati",
      payAndBook: "Paga et prenota",
      bookingErrorMsg: "Sfortunatamente la prenotazione non è andata a buon fine. Prova di nuovo",
      pilotSaveError: "Assicurati di inserire tutti i dati nel formato corretto.",
      passengerFullName: "Nome completo del passeggero",
      halfDay: "Mezza giornata",
      fullDay: "Giornata intera",
      taken: "Occupato",
      free: "Libero",
      selected: "Selezionato",
      row: "Fila",
      place: "posto",
      selectedPalce: "Posizione selezionata",
      selectedPlaces: "Posizioni selezionate",
      price: "Prezzo",
      full: "Completo",
      passengers: "Passeggeri",
      available: "disponibili"

    },
    emailSender: "noti2_it.php"
  },
  stripe: {
    error:{
      title: "Si è prodotto un errore",
      header: "Pagamento non realizzato",
      msg: "Stripe non ha potuto realizzare il pagamento, assicurati di aver introdotto i dati correttamente. Per motivi di sicurezza Stripe blocca le carte que possono essere potenzialmente fraudolente. Se non è il tuo caso, prova di nuovo.",
      msg2: "Non abbiamo potuto realizzare l'addebito sulla vostra carta, assicurati di aver introdotto i dati correttamente o prova più tardi."
    },
    title: "Pagamento gestito da Stripe",
    done: "Pagamento realizzato",
    redirecting: "Reindirizzando",
    formTitle: "Introduci le informazioni della carta",
    number: "Numero",
    expireDate: "Data di scadenza",
    CVC: "Codice di sicurezza CVC",
    amaunt: "Importo da pagare:",

  },
  btns:{
    cancel: "Cancellare",
    goBack: "Indietro",
    saveDriver: "Salva il conducente",
    no: "No",
    yes: "Si",
    pay: "Pagare",
    add: "Aggiungere"
  },
  servicesList: {
    title: "Scegli un servizio",
    SL: "Gommoni senza patente",
    CC: "Escursioni guidate - gommone con conducente",
    SP: "Spiaggia attrezzata"
  },
  booked:{
    title: "Prenotazione confermata.",
    emailMsg: "Ti abbiamo inviato una email",
    emailMsg2: "Controlla la posta in arrivo:",
    bookingInfo: "Informazioni della prenotazione",
    date: "Data",
    service: "Servizio",
    name: "Nome",
    disclaimer: {
      important: "Importante",
      gas: "Prezzi benzina esclusa.",
      earlySL1: "Arrivare alla sede di SCIROCCO RENT A BOAT almeno 20 MINUTI PRIMA della prenotazione muniti di un DOCUMENTO D' IDENTITA' ( carta d'identità o patente o  passaporto) e 400 € di CAUZIONE in contanti o carta di credito.",
      earlySL2: "Ad agosto può esserci lunga attesa per entrare al parcheggio di Cala Brandinchi, quindi si consiglia di arrivare con largo anticipo. In caso di parcheggio completo mostrate la mail di conferma agli addetti all'ingresso del parcheggio: vi faranno entrare.",
      earlyCC1: "Arrivare alla sede di SCIROCCO RENT A BOAT almeno 20 MINUTI PRIMA della prenotazione muniti di un DOCUMENTO D' IDENTITA' ( carta d'identità o patente o  passaporto).",
      earlyCC2: "Ad agosto può esserci lunga attesa per entrare al parcheggio di Cala Brandinchi, quindi si consiglia di arrivare con largo anticipo. In caso di parcheggio completo mostrate la mail di conferma agli addetti all'ingresso del parcheggio: vi faranno entrare.",
      boat: {
        model: "LA PRENOTAZIONE NON GARANTISCE UN MODELLO SPECIFICO MA UN QUALSIASI MODELLO DELLA CATEGORIA.",
        cancelTitle: "CANCELLAZIONI O MODIFICHE:",
        cancelFree: "GRATIS FINO A 3 GIORNI PRIMA DELLA DATA PRENOTATA",
        cancelPenalty: "10 € DI PENALE FINO A 24 ORE PRIMA  DELLA DATA PRENOTATA",
        cancelNoRefound: "NESSUN RIMBORSO MENO DI 24 ORE PRIMA DELLA DATA PRENOTATA",
        cancelBadWeather: "3% DELL' IMPORTO ( commisioni della carta di credito) IN CASO DI METEO AVVERSO : onda superiore a 1 metro o vento superiore a 20 nodi",
      },
      beach: {
        cancelTitle: "CANCELLAZIONI O MODIFICHE:",
        cancelPenalty: "10 € DI PENALE FINO A  24 ORE PRIMA  DELLA DATA PRENOTATA",
        cancelNoRefound: "NESSUN RIMBORSO MENO DI 24 ORE PRIMA DELLA DATA PRENOTATA",
        cancelFree: "3% DEL IMPORTE ( commisioni della carta di credito) IN CASO DI METEO AVVERSO : pioggia o vento superiore ai 25 nodi",
        early: "Ad agosto può crearsi una lunga coda all'ingresso del parcheggio di Cala Brandinchi, inoltre dopo le ore 10 spesso il parcheggio è già completo e l'entrata non è garantita, nemmeno con la prenotazione dei lettini. Si consiglia dunque di arrivare per tempo poiché, in caso di parcheggio pieno, il costo dei lettini NON VERRA' RIMBORSATO."
      }
    },
    ensuranceYes: "in caso di danni causati all'unità locata il cliente è responsabile della del risarcimento della franchigia di €  2000.",
    ensuranceNo: "in caso di danni causati all'unità locata il cliente è responsabile del risarcimento dell'importo totale del danno."
  }
}


const DE = {
  code: "de",
  dateFormat: "DD/MM/YYYY",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "Deutsch",
    index: "Home",
    boats: "Preise nicht lizenzierte Boote",
    guidedTrips: "Preise Führungen - Motorboot mit Fahrer",
    routs: "Routen und Galerie",
    beachServices: "Ausgestatteter Strand",
    contact: "Kontakt"
  },
  footer: {
    menu: "Menu",
    schedule: "Zeitplan",
    dates: "Vom 1. Mai bis 15. Oktober.",
    hours: "Jeden Tag von 8.30 bis 19.00 Uhr.",
    info: "Informationen",
    loc: "Strand Cala Brandinchi - San Teodoro (OT) - Sardegna",
    copyright: "Copyright 2019 Alle Rechte vorbehalten."
  },
  datePicker: {
    months: {
      5: "Mai",
      6: "Juni",
      7: "Juli",
      8: "August",
      9: "September",
      10: "Oktober"
    },
    selectYear: "Wählen Sie ein Jahr aus",
    selectMonth: "Wählen Sie einen Monat aus",
    bookDay: "Tag buchen"
  },
  booking: {
    time: "Bitte füllen Sie die Reservierung innerhalb des festgelegten Zeitraums aus:",
    duration: "Dauer",
    whatTime: "Wann möchten Sie die Reservierung?:",
    quantity: "Wählen Sie eine Anzahl von Booten aus",
    multyDrivers: {
      driver: "Fahrer",
      saved: "gerettet"
    },
    driverDetails: "Informationen zum Fahrer",
    personalDetails: "Persönliche Informationen",
    form: {
      required: "Obligatorisch",
      name: "Name",
      surname: "Nachname",
      idNum: "Ausweisdokument",
      birthday: "Geburtsdatum",
      birthLocation: "Geburtsort",
      residence: "Wohnsitz",
      stayingin: "Ort der Unterkunft in Sardegna",
      phone: "Telefon",
      email: "E-Mail",
      pickPlace: "Wählen Sie Ihre Position",
      firstRow: "Erste Reihe",
      position: "Position",
      fullDriversDetails: "Abgeschlossene Fahrerdaten",
      saved: "Gerettet",
      adults: "Erwachsene",
      kids: "Kinder bis 12 Jahre",
      ensurance: "Schadensversicherung",
      ensuranceQuestion: "Schadensversicherung für 5 €",
      ensurancePerBoat: "Schadensversicherung für 5 € pro Boot",
      calculating: "Einschätzen",
      makeSure: "Stellen Sie sicher, dass Sie alle Daten eingegeben haben!",
      makeSureBeach: "Stellen Sie sicher, dass Sie alle Daten eingegeben haben und haben Ihre Position ausgewählt!",
      formValid: "Vollständiges Formular! Weiter zur Zahlung.",
      paymentMethods: "Zahlungsarten",
      confirmDetails: "Daten bestätigen",
      payAndBook: "Zahlen und reservieren",
      bookingErrorMsg: "Leider ist die gewünschte Reservierung nicht mehr verfügbar. Versuchen Sie es erneut.",
      pilotSaveError: "Stellen Sie sicher, dass Sie alle Daten im richtigen Format eingeben.",
      passengerFullName: "Vollständiger Name des Passagiers",
      halfDay: "Halber Tag",
      fullDay: "Ganzer Tag",
      taken: "Beschäftigt",
      free: "Frei",
      selected: "Ausgewählt",
      row: "Reihe",
      place: "platz",
      selectedPalce: "Gewählte platz",
      selectedPlaces: "Gewählten plätze",
      price: "Preis",
      full: "Abgeschlossen",
      passengers: "Passagiere",
      available: "verfügbar"

    },
    emailSender: "noti2_de.php"
  },
  stripe: {
    error:{
      title: "Es ist ein Fehler aufgetreten",
      header: "Sammlung nicht gemacht",
      msg: "Stripe konnte die Zahlung nicht durchführen. Vergewissern Sie sich, dass Sie die Daten korrekt eingegeben haben. Aus Sicherheitsgründen blockiert Stripe Karten, die möglicherweise betrügerisch erscheinen. Wenn dies nicht der Fall ist, versuchen Sie es erneut.",
      msg2: "Wir konnten Ihre Karte nicht belasten, sicherstellen, dass Sie die Informationen richtig eingegeben haben, oder es später erneut versuchen."
    },
    title: "Moderate Zahlung per Stripe",
    done: "Zahlung erfolgt",
    redirecting: "Weiterleitung",
    formTitle: "Geben Sie Ihre Karteninformationen ein",
    number: "Nummer",
    expireDate: "Ablaufdatum",
    CVC: "CVC-Sicherheitscode",
    amaunt: "zu zahlender Betrag:",

  },
  btns:{
    cancel: "Abbrechen",
    goBack: "Zurück",
    saveDriver: "Fahrer Speichern",
    no: "Nein",
    yes: "Ja",
    pay: "Bezahlen",
    add: "Hinzufügen"
  },
  servicesList: {
    title: "Wählen Sie einen Service aus",
    SL: "Preise nicht lizenzierte Boote",
    CC: "Führungen - Motorboot mit Fahrer",
    SP: "Ausgestatteter Strand"
  },
  booked:{
    title: "Bestätigte Reservierung.",
    emailMsg: "Wir haben Ihnen eine E-Mail geschickt",
    emailMsg2: "Überprüfen Sie den Posteingang von:",
    bookingInfo: "Reservierungsinformationen",
    date: "Datum",
    service: "Service",
    name: "Name",
    disclaimer: {
      important: "Wichtig",
      gas: "Preise ohne Benzin.",
      earlySL1: "Bitte treffen Sie 20 Minuten vor der gebuchten Zeit bei SCIROCCO RENT A BOAT mit Personalien (Personalausweis oder Führerschein oder Reisepass) und einer Kaution in Höhe von 400 € in bar oder Kreditkarte ein.",
      earlySL2: "Im August kann es lange dauern, bis Sie einen Parkplatz in Cala Brandinchi finden. Es ist daher ratsam, viel Zeit einzuplanen. Wenn der Parkplatz voll sein sollte, zeigen Sie den Mitarbeitern Ihre Buchungsbestätigung (E-Mail) an der Parkplatzeinfahrt: Sie werden Sie einlassen.",
      earlyCC1: "Bitte treffen Sie 20 Minuten vor der gebuchten Zeit bei SCIROCCO RENT A BOAT mit Personalien (Personalausweis oder Führerschein oder Reisepass)",
      earlyCC2: "Im August kann es lange dauern, bis Sie einen Parkplatz in Cala Brandinchi finden. Es ist daher ratsam, viel Zeit einzuplanen. Wenn der Parkplatz voll sein sollte, zeigen Sie den Mitarbeitern Ihre Buchungsbestätigung (E-Mail) an der Parkplatzeinfahrt: Sie werden Sie einlassen.",
      boat: {
        model: "BUCHEN GARANTIERT NICHT EIN SPEZIFISCHES MODELL, ABER EIN MODELL DER KATEGORIE.",
        cancelTitle: "STORNIERUNGEN ODER ÄNDERUNGEN:",
        cancelFree: "FREI BIS 3 TAGE VOR DEM GEBUCHTEN DATUM",
        cancelPenalty: "10 € Gebühr bis 24 Stunden vor dem gebuchten Termin",
        cancelNoRefound: "KEINE RÜCKERSTATTUNG WENIGER ALS 24 STUNDEN VOR DEM GEBUCHTEN DATUM",
        cancelBadWeather: "3% DES BETRAGS (Kreditkartenprovisionen) BEI UNWETTER: Welle größer als 1 Meter oder Wind über 20 Knoten",
      },
      beach: {
        cancelTitle: "STORNIERUNGEN ODER ÄNDERUNGEN:",
        cancelPenalty: "10 € Gebühr bis 24 Stunden vor dem gebuchten Termin",
        cancelNoRefound: "KEINE RÜCKERSTATTUNG WENIGER ALS 24 STUNDEN VOR DEM GEBUCHTEN DATUM",
        cancelFree: "3% DES BETRAGS (Kreditkartenprovisionen) BEI UNWETTER: Welle größer als 1 Meter oder Wind über 25 Knoten",
        early: "Im August können Sie an der Einfahrt zum Parkplatz von Cala Brandinchi eine lange Warteschlange einrichten, darüber hinaus ist das Parken nach 10 Uhr oft bereits abgeschlossen, und der Eintritt ist nicht garantiert,  auch nicht bei der Buchung von Sonnenliegen Es ist daher ratsam, rechtzeitig anzukommen, da bei voller Parkgebühr die Kosten für die Sonnenliegen NICHT erstattet werden."
      }
    },
    ensuranceYes: "Im Falle von Schäden an der gemieteten Einheit haftet der Kunde für die Entschädigung des Überschusses von 2000 €.",
    ensuranceNo: "Im Falle von Schäden, die an der gemieteten Einheit verursacht wurden, ist der Kunde für den Ersatz des Gesamtbetrags des Schadens verantwortlich."
  }
}

const FR = {
  code: "fr",
  dateFormat: "JJ/MM/AAAA",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "Français",
    index: "Accueil",
    boats: "Prix bateaux sans permis",
    guidedTrips: "Prix excursions - location avec chauffeur",
    routs: "Parcours et galerie",
    beachServices: "Plage équipée",
    contact: "Contacter"
  },
  footer: {
    menu: "Menu",
    schedule: "Calendrier",
    dates: "Du 1er mai au 15 octobre.",
    hours: "Tous les jours de 8h30 à 19h00.",
    info: "Les informations",
    loc: "Plage Cala Brandinchi - San Teodoro (OT) - Sardaigne",
    copyright: "Copyright 2019 Tous les droits réservés."
  },
  datePicker: {
    months: {
      5: "Mai",
      6: "Juin",
      7: "Juillet",
      8: "Août",
      9: "Septembre",
      10: "Octobre"
    },
    selectYear: "chiosir un an",
    selectMonth: "choisir un moi",
    bookDay: "Réserver le Jour"
  },
  booking: {
    time: "S'il vous palit, completez la réservation dans le temps établi:",
    duration: "Durée",
    whatTime: "Quelle heure voulez vous réserver:",
    quantity: "Choisir un numero de bateaux",
    multyDrivers: {
      driver: "Conducteur",
      saved: "sauvegardé"
    },
    driverDetails: "Données du conducteur",
    personalDetails: "Données personelles",
    form: {
      required: "Obligatoir",
      name: "Nom",
      surname: "Après nom",
      idNum: "Document d'identité",
      birthday: "Date de naissance",
      birthLocation: "Lieu de naissance",
      residence: "Addresse",
      stayingin: "Adresse de séjour en Sardaigne",
      phone: "Téléphone",
      email: "Email",
      pickPlace: "Choisissez votre position",
      firstRow: "Prèmiere file",
      position: "Place",
      fullDriversDetails: "Données du conducteur complétées",
      saved: "Sauvgardé",
      adults: "Adultes",
      kids: "Enfants jusqu'à 12 ans",
      ensurance: "Assurance",
      ensuranceQuestion: "Voulez vous acheter l'assurance pour un montant de 5 € ?",
      ensurancePerBoat: "Voulez vous acheter l'assurance pour un montant de 5 € pour bateau ?",
      calculating: "Calculant",
      makeSure: "Assurez vous d'avoir introduit toutes les donnéesr!",
      makeSureBeach: "Assurez vous d'avoir introduit toutes les donnéesr et avoir choisi votre place!",
      formValid: "Formulaire complet, procedez au paiement.",
      paymentMethods: "modes de paiement",
      confirmDetails: "Confirmez les données",
      payAndBook: "Payez et réservez",
      bookingErrorMsg: "Maleurheusement la rèservation n'a pas réussi. Essayez de nouveau.",
      pilotSaveError: "Assurez vous d'introduire toutes les données sous la forme correcte.",
      passengerFullName: "Nom complet du passager",
      halfDay: "Demi journée",
      fullDay: "Journée entière",
      taken: "Occupé",
      free: "Libre",
      selected: "Choisi(e)",
      row: "File",
      place: "place",
      selectedPalce: "Place choisie",
      selectedPlaces: "Places choisies",
      price: "Prix",
      full: "Complet",
      passengers: "Passagers",
      available: "disponible"

    },
    emailSender: "noti2_fr.php"
  },
  stripe: {
    error:{
      title: "Une erreur s'est produite",
      header: "Paiement pas effectué",
      msg: "Stripe n'a pas pu effectuer le paiement, assurez vous d'avoir introduit les données correctement. Pour question de sécurité, Stripe bloque les cartes qui peuve être potentiellement frauduleuses. S'il n'est pas votre cas, essayez de nouveau.",
      msg2: "Stripe n'a pas pu effectuer le paiement sur votre carte, assurez vous d'avoir introduit les données correctement ou essayez plus tard."
    },
    title: "Paiement conduit par Stripe",
    done: "Paiement effectué",
    redirecting: "Redirigeant",
    formTitle: "Introduisez les informtions de sa carte",
    number: "Numéro",
    expireDate: "Date d'expiration",
    CVC: "Code de sécurité CVC",
    amaunt: "Montant à payer:",

  },
  btns:{
    cancel: "Effacer",
    goBack: "Arrière",
    saveDriver: "Sauvegarder le conducteur",
    no: "Non",
    yes: "Oui",
    pay: "Payer",
    add: "Ajouter"
  },
  servicesList: {
    title: "Choisir un service",
    SL: "Bateaux sans permis",
    CC: "Excursions - location avec chauffeur",
    SP: "Plage équipée"
  },
  booked:{
    title: "Réservation confirmée.",
    emailMsg: "On t'a envoyé une mail",
    emailMsg2: "Vérifiez votre boîte mail:",
    bookingInfo: "Informations de la réservation",
    date: "Date",
    service: "Service",
    name: "Nom",
    disclaimer: {
      important: "Important",
      gas: "Prix essence a pàrt.",
      earlySL1: "Arrivez à SCIROCCO RENT A BOAT au moins 20 minute avant votre réservation, avec un document d’identité (carte d’identité ou permis de conduire ou passeport) et 400 € de caution en espèces ou par carte de crédit.",
      earlySL2: "Au mois d’août, l’attente pour entrer dans le parking de Cala Brandinchi peut être longue, il est donc conseillé d’arriver longtemps à l’avance. En cas de parking complet, montrez l'email de confirmation aux employés à l'entrée du parking: ils vous laisseront entrer.",
      earlyCC1: "Arrivez au siège de SCIROCCO RENT A BOAT  au moins 20 MINUTES AVANT votre réservation, avec un document d’identité (carte d’identité ou permis de conduire ou passeport).",
      earlyCC2: "Au mois d’août, l’attente pour entrer dans le parking de Cala Brandinchi peut être longue, il est donc conseillé d’arriver longtemps à l’avance. En cas de parking complet, montrez l'email de confirmation aux employés à l'entrée du parking: ils vous laisseront entrer.",
      boat: {
        model: "LA RÉSERVATION NE GARANTIT PAS UN MODÈLE SPÉCIFIQUE MAIS UN MODÈLE DE LA CATÉGORIE.",
        cancelTitle: "ANNULATIONS OU CHANGEMENTS:",
        cancelFree: "GRATUIT JUSQU'À 3 JOURS AVANT LA DATE RÉSERVÉE",
        cancelPenalty: "10 € DE PÉNALITÉ  JUSQU' À 24 HEURES AVANT LA DATE RÉSERVÉE",
        cancelNoRefound: "AUCUN REMBOURSEMENT MOINS DE 24 HEURES AVANT LA DATE RÉSERVÉE",
        cancelBadWeather: "3% DU MONTANT ( commissions de la carte de credit ) EN CAS DE MAUVAIS TEMPS: vagues supérieures à 1 mètre ou vent supérieur à 20 nœuds",
      },
      beach: {
        cancelTitle: "ANNULATIONS OU CHANGEMENTS:",
        cancelPenalty: "10 € DE PÉNALITÉ JUSQU' À 24 HEURES AVANT LA DATE RÉSERVÉE",
        cancelNoRefound: "AUCUN REMBOURSEMENT MOINS DE 24 HEURES AVANT LA DATE RÉSERVÉE",
        cancelFree: "3% DU MONTANT ( commissions de la carte de credit ) EN CAS DE MAUVAIS TEMPS: vagues supérieures à 1 mètre ou vent supérieur à 25 nœuds",
        early: "En août il peut y avoir une longue file à l'entrée du parking Cala Brandinchi, en plus après 10 heures du matin le parking est souvent déjà complet et l'entrée n'est pas garantie, même avec la réservation des chaises longues. Il est donc conseillé d'arriver assez  tôt car, en cas de parking complet, le coût des chaises longues ne sera pas remboursé."
      }
    },
    ensuranceYes: "en cas de dommages apportés à l'unité louée le client loueur est résponsable du remboursement    d'une franchise de € 2000.",
    ensuranceNo: "en cas de dommages apportés à l'unité louée le client loueur est résponsable du remboursement du montant total du dommage."
  }
}


const RU = {
  code: "ru",
  dateFormat: "DD/MM/YYYY",
  dateCode: "DD/MM/YYYY",
  navbar:{
    langName: "Pусский",
    index: "Главная",
    boats: "Цены нелицензионного лодки",
    guidedTrips: "Цены экскурсии - лодка с водителем",
    routs: "Маршруты и галерея",
    beachServices: "Оборудованный пляж",
    contact: "контакт"
  },
  footer: {
    menu: "меню",
    schedule: "график",
    dates: "С 1 мая по 15 октября.",
    hours: "Каждый день с 8:30 до 7:00.",
    info: "информация",
    loc: "пляж Cala Brandinchi - San Teodoro (OT) - Sardegna",
    copyright: "Copyright 2019 Все права защищены."
  },
  datePicker: {
    months: {
      5: "май",
      6: "июнь",
      7: "Хулио",
      8: "Август",
      9: "сентябрь",
      10: "Октябр"
    },
    selectYear: "Выберите год",
    selectMonth: "Выберите месяц",
    bookDay: "Книжный день"
  },
  booking: {
    time: "Пожалуйста, завершите бронирование в установленные сроки:",
    duration: "продолжительность",
    whatTime: "В какое время вы хотите забронировать:",
    quantity: "Выберите количество лодок",
    multyDrivers: {
      driver: "проводник",
      saved: "охраняемый"
    },
    driverDetails: "Информация водителя",
    personalDetails: "Личная информация",
    form: {
      required: "обязательное",
      name: "имя",
      surname: "фамилия",
      idNum: "Удостоверение личности",
      birthday: "Дата рождения",
      birthLocation: "Место рождения",
      residence: "проживание",
      stayingin: "Место проживания на Sardegna",
      phone: "телефон",
      email: "адрес электронной почты",
      pickPlace: "Выберите свою позицию",
      firstRow: "Первый ряд",
      position: "положение",
      fullDriversDetails: "Завершенные данные драйвера",
      saved: "охраняемый",
      adults: "взрослые",
      kids: "Дети до 12 лет",
      ensurance: "страхование от ущерба",
      ensuranceQuestion: "страхование от ущерба 5 евро?",
      ensurancePerBoat: "страхование от ущерба 5 евро за лодку?",
      calculating: "Расчет суммы",
      makeSure: "Убедитесь, что вы ввели все данные!",
      makeSureBeach: "Убедитесь, что вы ввели все данные и выбрали свою позицию!",
      formValid: "Полная форма! Перейти к оплате.",
      paymentMethods: "формы оплаты",
      confirmDetails: "Подтвердите данные",
      payAndBook: "Оплатить и забронировать",
      bookingErrorMsg: "К сожалению, бронирование, которое я хотел сделать, больше не доступно. Попробуйте еще раз.",
      pilotSaveError: "Убедитесь, что вы вводите все данные в правильном формате.",
      passengerFullName: "Полное имя пассажира",
      halfDay: "Полдня",
      fullDay: "Полный день",
      taken: "занятый",
      free: "место пусто",
      selected: "выбранный",
      row: "ряд",
      place: "должность",
      selectedPalce: "Выбранная позиция",
      selectedPlaces: "Выбранная позиция",
      price: "цена",
      full: "полный",
      passengers: "пассажиров",
      available: "имеющийся"

    },
    emailSender: "noti2_en.php"
  },
  stripe: {
    error:{
      title: "Была какая-то ошибка",
      header: "Коллекция не сделана",
      msg: "Stripe не может произвести платеж, убедитесь, что вы правильно ввели данные. По соображениям безопасности Stripe блокирует карты, которые кажутся мошенническими. Если это не ваш случай, попробуйте еще раз.",
      msg2: "Нам не удалось списать средства с вашей карты, убедитесь, что вы правильно ввели информацию, или повторите попытку позже.."
    },
    title: "Умеренный платеж Stripe",
    done: "Оплата произведена",
    redirecting: "перенаправление",
    formTitle: "Введите данные своей карты",
    number: "номер",
    expireDate: "Срок годности",
    CVC: "Код безопасности CVC",
    amaunt: "Сумма к оплате:",

  },
  btns:{
    cancel: "отменить",
    goBack: "возврат",
    saveDriver: "Сохранить драйвер",
    no: "нет",
    yes: "если",
    pay: "платить",
    add: "добавлять"
  },
  servicesList: {
    title: "Выберите сервис",
    SL: "нелицензионного лодки",
    CC: "экскурсии - лодка с водителем",
    SP: "Оборудованный пляж"
  },
  booked:{
    title: "Подтвержденное бронирование.",
    emailMsg: "Мы отправили вам письмо",
    emailMsg2: "Проверьте почтовый ящик:",
    bookingInfo: "Информация о бронировании",
    date: "дата",
    service: "обслуживание",
    name: "имя",
    disclaimer: {
      important: "Bажно",
      gas: "Цены без учета газа.",
      earlySL1: "прибыть в штаб-квартиру SCIROCCO RENT A BOAT минимум за 20 минут до бронирования с DNI (документ, удостоверяющий личность, паспорт или водительские права) и ДЕПОЗИТОМ 400 евро наличными или кредитной картой.",
      earlySL2: "В августе может потребоваться много времени для въезда на автостоянку Cala Brandinchi, поэтому желательно прибыть заранее. В случае полной парковки, покажите подтверждение по электронной почте сотрудникам у входа на парковку: они впустят вас.",
      earlyCC1: "прибыть в штаб-квартиру SCIROCCO RENT A BOAT минимум за 20 минут до бронирования с DNI (удостоверение личности, паспорт или водительские права).",
      earlyCC2: "В августе может потребоваться много времени для въезда на автостоянку Cala Brandinchi, поэтому желательно прибыть заранее. В случае полной парковки, покажите подтверждение по электронной почте сотрудникам у входа на парковку: они впустят вас.",
      boat: {
        model: "БРОНИРОВАНИЕ НЕ ГАРАНТИРУЕТ КОНКРЕТНУЮ МОДЕЛЬ, А ЛЮБУЮ МОДЕЛЬ КАТЕГОРИИ.",
        cancelTitle: "ОТМЕНЫ ИЛИ ИЗМЕНЕНИЯ:",
        cancelFree: "БЕСПЛАТНО ДО 3 ДНЕЙ ДО ЗАБРОНИРОВАННОЙ ДАТЫ",
        cancelPenalty: "10 евро штрафа до 24 часов до указанной даты",
        cancelNoRefound: "НЕТ ВОЗВРАТОВ МЕНЬШЕ, ЧЕМ 24 ЧАСА ДО ЗАБРОНИРОВАННОЙ ДАТЫ",
        cancelBadWeather: "3% от суммы (комиссии по кредитной карте) в случае неблагоприятной погоды: волна больше 1 метра или ветер выше 20 узлов",
      },
      beach: {
        cancelTitle: "ОТМЕНЫ ИЛИ ИЗМЕНЕНИЯ:",
        cancelPenalty: "10 евро штрафа до 24 часов до указанной даты",
        cancelNoRefound: "НЕТ ВОЗВРАТОВ МЕНЬШЕ, ЧЕМ 24 ЧАСА ДО ЗАБРОНИРОВАННОЙ ДАТЫ",
        cancelFree: "3% от суммы (комиссии по кредитной карте) в случае неблагоприятной погоды: волна больше 1 метра или ветер выше 25 узлов",
        early: "в августе на въезде на автостоянку Cala Brandinchi может быть создана длинная очередь, более того, после 10 часов утра парковка часто завершается, и въезд не гарантируется, даже при бронировании шезлонгов. Поэтому желательно прибыть вовремя, потому что, в случае полной парковки, стоимость лежаков не будет возмещена."
      }
    },
    ensuranceYes: "в случае ущерба, нанесенного арендованной единице, клиент несет ответственность за компенсацию превышения 2000 евро.",
    ensuranceNo: "в случае ущерба, нанесенного арендованной единице, клиент несет ответственность за возмещение общей суммы ущерба."
  }
}


const LANG = {
  es: ES,
  en: EN,
  it: IT,
  de: DE,
  fr: FR,
  ru: RU
}

export default LANG;